import * as MuiGrid from "@mui/x-data-grid-pro";
import { TGDM, TGRM, TypedGridApiRef } from '@imas/data-grid';
import { v4 as uudi4 } from 'uuid';
import { Remote } from "mdi-material-ui";

//id of the persistent record used for adding new rows'
export const PERSISTENT_EDIT_ID = `PERSISTENT_EDIT_ROW-${uudi4()}`;

export const isChildOfPopper = (e: HTMLElement | EventTarget | null) => {
	let current: HTMLElement | null = e as HTMLElement | null; 
	
	while (current !== null && current !== undefined) {
		//check if the current element is a popper
		if (current?.classList?.contains('MuiPopover-root') || current?.classList?.contains('MuiDialog-root') || current?.classList?.contains('MuiModal-root')) {
			return true;
		}

		//this is for the date picker
		if (current?.classList?.contains('MuiInputBase-adornedEnd') || current?.classList?.contains('MuiCalendarPicker-root') || current?.classList?.contains('MuiDialogActions-root')) {
			return true;
		}

        // this is fot detail panels
        if (current?.classList?.contains('MuiDataGrid-detailPanel')) {
            return true;
        }

		//move to the next element up
		current = current?.parentElement;
	}

	return false;
};

// focus first the column of the persistent row
export const focusPersistentRow = (apiRef: TypedGridApiRef<TGDM, TGRM, never, "cell" | "row">) => {
	if(apiRef.current === null) return;

    try {
        const newCurrentIndex = apiRef.current.getRowIndexRelativeToVisibleRows(PERSISTENT_EDIT_ID);
        apiRef.current.scrollToIndexes({ rowIndex: newCurrentIndex });
        
        const columns = apiRef.current.getAllColumns();
        apiRef.current.setCellFocus(PERSISTENT_EDIT_ID, columns[0].field);
    } catch {}
};

// returns if true if the persistent row exists on the grid
export const persistentRowExists = (apiRef: TypedGridApiRef<TGDM, TGRM, never, "cell" | "row">) => {
	if(apiRef.current === null) return false;
    try {
        return apiRef.current.getRow(PERSISTENT_EDIT_ID) !== null;
    } catch {return false;}
};

export const createPersistentRow = (apiRef: TypedGridApiRef<TGDM, TGRM, never, "cell" | "row">, focus?: boolean) => {
	if(apiRef.current === null) return;

    // don't create a row that already exists
    //if (persistentRowExists(apiRef)) return;

    try {
        // //create the persistent edit row and set it to edit mode
        apiRef.current.updateRows([{ id: PERSISTENT_EDIT_ID }]);
        
        if (persistentRowExists(apiRef)) {
            // set row to edit mode if it is in view mode
            const mode = apiRef.current.getRowMode(PERSISTENT_EDIT_ID);
            if (mode === MuiGrid.GridRowModes.View) apiRef.current.startRowEditMode({ id: PERSISTENT_EDIT_ID });
        
            // focus the new row    
            if (focus) setTimeout(() => focusPersistentRow(apiRef));
        }
    } catch {}
};

// 3 useful functions [reset, remake, delete]

// delete the persistent row
export const deletePersistentRow = (apiRef: TypedGridApiRef<TGDM, TGRM, never, "cell" | "row">) => {
	if(apiRef.current === null) return;

    // if there is no existing row we can't delete it 
    if (!persistentRowExists(apiRef)) return;

    try {
        //delete persistent row
        apiRef.current.updateRows([{ id: PERSISTENT_EDIT_ID, _action: 'delete' }]);
    } catch {}
};

// remake persistent row, delete + create
export const remakePersistentRow = (apiRef: TypedGridApiRef<TGDM, TGRM, never, "cell" | "row">, focus?: boolean) => {
	if(apiRef.current === null) return;
    
    //delete persistent row
    //deletePersistentRow(apiRef);

    //re-create the persistent row
    createPersistentRow(apiRef, focus);

};

// resets the persistent row to default values (a remake if exist)
export const resetPersistentRow = (apiRef: TypedGridApiRef<TGDM, TGRM, never, "cell" | "row">, focus?: boolean) => {
    if(apiRef.current === null) return;

    // if there is no existing row we can't reset it 
    if (!persistentRowExists(apiRef)) return;

    remakePersistentRow(apiRef, focus);

    // stop edit mode
    // const mode = apiRef.current.getRowMode(PERSISTENT_EDIT_ID);
    // if (mode === MuiGrid.GridRowModes.Edit) {
    //     apiRef.current.stopRowEditMode({ id: PERSISTENT_EDIT_ID, ignoreModifications: true });
    //     apiRef.current.startRowEditMode({ id: PERSISTENT_EDIT_ID });
    // }
};