import { useApi, useLoadApi } from '@imas/api';
import { Alignment, Spacer } from '@imas/components/layout';
import { MuiFormSubmit, MuiFormSubmitProps, useMuiFormRef } from '@imas/mui-form';
import { openEditorWindow, useEditorState } from '@imas/utils/editor';
import { isPopup } from '@imas/utils/misc';
import { ExitToApp, Save as SaveIcon } from '@mui/icons-material';
import { Button, Paper } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { Navigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { CreateVehicleInspection, GetVehicleInspection, GetVVehicle, UpdateVehicleInspection, UploadVehicleFile } from 'src/api/misc/vehicles';
import { GetServiceOrder, GetServiceOrderTrackingResult, UpdateSO } from 'src/api/service-order/api';
import { TVehicleInspectionForm } from 'src/api/types/api/misc/vehicles/TVehicleInspectionForm';
import { VehicleInspection } from 'src/api/types/api/misc/vehicles/VehicleInspection';
import { VehicleInspectionForm } from './VehicleInspectionForm/VehicleInspectionForm';

type OpenVehicleInspectionEditorParams = { calItemId?: string };

type OpenVehicleInspectionEditorOptions = { 
	calItemId: int; 
	calVehInspectionId?: undefined;
 } | {
	calItemId?: undefined;
	calVehInspectionId: int; 
}; 

//function used by other pages to open this one, requires either a vehicle calItemId or an inspection id
export const openVehicleInspectionEditor = ({ calItemId, calVehInspectionId }: OpenVehicleInspectionEditorOptions): Window | null => {
	return openEditorWindow<OpenVehicleInspectionEditorParams>(
		IMAS.PAGES.HOME.VEHICLES.INSPECTION(), 
		{ id: calVehInspectionId ?? null, calItemId: calItemId?.toString() ?? undefined }, 
		{ height: 850, width: 480, name: calVehInspectionId ? `Vehicle Inspection ${calVehInspectionId}` : `New Inspection, Vehicle #${calItemId}`, left: (window.innerWidth - 435)/2 }
	);
};

export const VehicleInspectionEditor = () => {

	//editor state
	const [state, setState] = useEditorState<OpenVehicleInspectionEditorParams>({ url: IMAS.PAGES.HOME.VEHICLES.INSPECTION() });

    //useFormRef for service order service form
    const [formRef, onFormRef] = useMuiFormRef<TVehicleInspectionForm, VehicleInspection>();

	//get calItemId from url
	const calItemId = useMemo(() => {
		const stringParam = state.calItemId;
		if (stringParam === undefined) return null;
        return parseInt(stringParam);
	}, [state]);

	//get calVehInspectionId from url	
	const calVehInspectionId = useMemo(() => {
		return state.id;
	}, [state]);

	//get vVehicle and vehicleInspection (if calVehInspectionId is provided)
	const { data: vehicleInspection } = useLoadApi(GetVehicleInspection, [calVehInspectionId ?? -1], [calVehInspectionId], {disabled: calVehInspectionId === null});
	const { data: vVehicle } = useLoadApi(GetVVehicle, [calItemId ?? vehicleInspection?.calItemId ?? -1], [calItemId, vehicleInspection], {disabled: calItemId === null && vehicleInspection === null});

	//api
	const createVehicleInspection = useApi(CreateVehicleInspection);
	const updateVehicleInspection = useApi(UpdateVehicleInspection);

    //reset the form to editing the loaded vehicle inspection
    useEffect(() => {
        if (vehicleInspection === undefined) return;
        else {
            formRef?.reset({
                ...vehicleInspection,
				inspectionDate: moment(vehicleInspection.inspectionDate).toDate()
            });
        }

    }, [vehicleInspection]);	

	//submit handler
	const onSubmit = useCallback<MuiFormSubmitProps<TVehicleInspectionForm, VehicleInspection>["onSubmit"]>(async (data) => {
		if (!vVehicle) throw new Error("Vehicle is not yet loaded.");

		//new Files will be the type 'file', existing files are hierarchyIDs
		let newFiles: File[] = [];
		if (data.file1 instanceof File) newFiles.push(data.file1);
		if (data.file2 instanceof File) newFiles.push(data.file2);
		if (data.file3 instanceof File) newFiles.push(data.file3);
		if (data.file4 instanceof File) newFiles.push(data.file4);
		if (data.file5 instanceof File) newFiles.push(data.file5);

		//check if an inspection record is loaded, if not create a new record
		if (vehicleInspection){
			return await updateVehicleInspection(vehicleInspection.calVehInspectionId, {...data, 
				newFiles: newFiles,
			});
		}
		else {
			return await createVehicleInspection(vVehicle.calItemId, {...data,
				newFiles: newFiles,
			});
		}
	}, [vehicleInspection, vVehicle, updateVehicleInspection, createVehicleInspection]);


	return(
		<Paper>
        <Alignment flex column overflowHidden sx={{ margin: '10px', maxWidth: '500px' }}>

			<Alignment column overflowHidden sx={{height: '-webkit-fill-available'}}>
				<VehicleInspectionForm 
					vVehicle={vVehicle} 
					vehicleInspection={vehicleInspection}
					ref={onFormRef}
				/>
			</Alignment>

			<Alignment rowReverse sx={{ marginTop: '10px', "& > *:not(:last-child)": { marginLeft: '10px' } }}>
				{/* Save & Exit Button (Only show in a popup.) */}
				{isPopup() ? (
					<MuiFormSubmit
						//disabled={!canEdit}
						formRef={formRef}
						onSubmit={onSubmit}
						onSuccessfulSubmit={() => {
							//call window.close() to close the popup window
							window.close();
						}}
						startIcon={<ExitToApp/>}
					>{"Save & Exit"}</MuiFormSubmit>
				) : null}

				{/* Save Button */}
				<MuiFormSubmit
					//disabled={!canEdit}
					formRef={formRef}
					onSubmit={onSubmit}
					onSuccessfulSubmit={(newClient) => {
						//if a new server was just created then change to edit the new record
						if (!vehicleInspection) setState({ id: newClient.id }, { replace: true });
					}}
					startIcon={<SaveIcon/>}	
				>{"Save"}</MuiFormSubmit>

				{/* Exit Button */}
				{isPopup() ? (
					<Button
						variant = "contained"
						color = "secondary"
						onClick={() => {window.close(); }}
					>{"Exit"}</Button>
				): null }

			</Alignment>
		</Alignment>
		</Paper>
	);
};
