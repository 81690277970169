import { ApiConfig, mapApi } from "@imas/api";
import { Site, vWorkClass } from './types';

const SITES_BASE_URL = "/api/system/sites";

/*  */
export const GetSites = mapApi((): ApiConfig<Site[]> => ({
	method: 'get',
	url: `${SITES_BASE_URL}`,
}));

/*  */
export const GetSiteWorkClasses = mapApi((siteId?: int): ApiConfig<vWorkClass[]> => ({
	method: 'get',
	url: `${SITES_BASE_URL}/${siteId ?? -1}`,
}));