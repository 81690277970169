import { useCallback, useEffect, useRef } from 'react';
import { InputAdornment } from '@mui/material';
import { getGridNumericOperators } from "@mui/x-data-grid-pro";
import { TypedGridColTypeDef, TypedGridRenderEditCellParams } from '../typed-grid';
import { formatUSD } from '@imas/utils/formatting';
import { NumberInputEditCell } from './INTEGER_COL';
import { EditCellContainer } from './utils';
import { NumberInputBaseProps } from '@imas/components/inputs';
import { roundFloat } from '@imas/utils/math';

interface GetDecimalColProps<T extends boolean> extends Omit<NumberInputBaseProps, "id" | "value" | "onValueChange" | "fullWidth" | "ref"> {
	required?: T;
	roundToNearest?: decimal;
};

type TEditCellParamsDecimalValue = TypedGridRenderEditCellParams<{ id: string | number; value: number; }, { value: number; }, never, "value", "cell">;
type TEditCellParamsDecimalValueNullable = TypedGridRenderEditCellParams<{ id: string | number; value: number | null; }, { value: number | null; }, never, "value", "cell">;

export type DecimalEditCellProps<T extends boolean, TEditCellParams extends {} = never> = 
	TEditCellParams extends never ?
		T extends true ? 
			GetDecimalColProps<T> & TEditCellParamsDecimalValue
			: GetDecimalColProps<T> & TEditCellParamsDecimalValueNullable
	: GetDecimalColProps<T> & TEditCellParams;

export const DecimalEditCell = <T extends boolean = false, TEditCellParams extends {} = never>(props: DecimalEditCellProps<T, TEditCellParams>) => {
	//spread props
	const { id, value, field, api, hasFocus, required, roundToNearest, ...inputProps } = (props as DecimalEditCellProps<T, TEditCellParamsDecimalValueNullable>);

    //input ref
    const ref = useRef<HTMLInputElement | null>(null);

    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);

	const roundAndClamp = useCallback((x: decimal) => {
		//round value
		if (typeof roundToNearest === 'number') x = roundFloat(x, roundToNearest);

		//clamp value
		if (typeof (inputProps?.min) === 'number' && x < inputProps.min) x = inputProps.min;
		if (typeof (inputProps?.max) === 'number' && x > inputProps.max) x = inputProps.max;

		return x;
	}, [roundToNearest, inputProps?.min, inputProps?.max]);

    return (
		<EditCellContainer required={required && typeof value !== "number"}>
			<NumberInputEditCell
				value={value === undefined || value === null ? null : value}
				onValueChange={({ floatValue }) => {
					//if field is not required then floatValue should null instead of undefined
					let value = required ? floatValue : floatValue ?? null;

					api.setEditCellValue({ id, field, value } as any);
				}}
				onBlur={(e) => {
					//clamp and round value if it is a number
					if (typeof value === 'number') api.setEditCellValue({ id, field, value: roundAndClamp(value) } as any);

					//pass event
					if (inputProps?.onBlur) inputProps.onBlur(e);
				}}
				fullWidth
				ref={ref}
				{...inputProps}
			/>
		</EditCellContainer>
    );
};

export const FixedDecimalEditCell = (props: any, decimalScale: int) => {
	//spread props
	const { id, value, field, api, hasFocus, required, ...inputProps } = props;
    //input ref
    const ref = useRef<HTMLInputElement | null>(null);

    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);

    return (
		<EditCellContainer required={required && typeof value !== "number"}>
			<NumberInputEditCell
				value={value === undefined || value === null ? null : value}
				onValueChange={({ floatValue }) => {
					//if field is not required then floatValue should null instead of undefined
					//let value = required ? floatValue : floatValue ?? null;

					api.setEditCellValue({ id, field, value: floatValue } as any);
				}}
				fullWidth
				decimalScale={decimalScale}
				fixedDecimalScale
				ref={ref}
				{...inputProps}
			/>
		</EditCellContainer>
    );
};

//USD filter operators
const filterOperators = getGridNumericOperators()
	.map(operator => {
		return operator;
	}) as any;

export const getDecimalColDef = <
	ValueNullable extends boolean = false
>({ required, ...props }: GetDecimalColProps<ValueNullable>): TypedGridColTypeDef<ValueNullable extends true ? number : number | null> => ({
	type: 'number',
	renderCell: ({ value }) => typeof value !== 'number' ? '' : value.toFixed(props.decimalScale),
	renderEditCell: (renderProps) => <DecimalEditCell required={required} {...props} {...renderProps as any}/>,
});


//v6 col def
export const TWO_DIGIT_DECIMAL_COL = {
	type: 'number',
	valueFormatter: ({ value }: { value: number | null }) => value?.toFixed(2) ?? null,
	renderEditCell: (props: any) => <FixedDecimalEditCell {...props} decimalScale={2} />
};