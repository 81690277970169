import { ServiceOrderSignatureRequest } from './../types/api/service-orders/service-order/ServiceOrderSignatureRequest';
import { CreateSignatureRequest } from './../types/api/service-orders/service-order/CreateSignatureRequest';
import { TServiceOrderForm } from './../types/api/service-orders/service-order/TServiceOrderForm';
import { ServiceOrder, SOTrackingResult } from 'src/api/types/api/service-orders/types';
import { objectToFormData, SourceGetter } from '@imas/api';
import axios from 'axios';
import UpdateReptTypeRequest from '../types/api/service-orders/UpdateReptTypeRequest';
import PrepareApproveReportSentRequest from '../types/api/service-orders/service-order/PrepareApproveReportSentRequest';
import SOResetRequest from '../types/api/service-orders/service-order/SOResetRequest';
import { Moment } from 'moment';
import moment from 'moment';
import { UpdateServiceOrderContactRequest } from '../types/api/service-orders/service-orders';
import { GetPayrollEmpHoursResult } from '../types/api/misc/GetPayrollEmpHoursResult';

/**
 * Search for a Service Order given a pronum and serviceOrderId
 */
 export const _Search = (getSource: SourceGetter) => {
    return (pronum: int, serviceOrderId: string): Promise<ServiceOrder | null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ServiceOrder | null>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-order/search',
                params: {
                    pronum: pronum,
                    serviceOrderId: serviceOrderId, 
                },
                cancelToken: source.token
            }).then((response) => {
                //reject promise if no content was returned
                if (response.status === 204) resolve(null); 

                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Get a service order entry given the serviceOrder uniqueId.
 * @param {number} uniqueId Unique ID of the ServiceOrder to get.
 */
export const _Get = (getSource: SourceGetter) => {
    return (uniqueId: int): Promise<ServiceOrder | null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ServiceOrder | null>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-order/' + uniqueId.toString(),
                cancelToken: source.token
            }).then((response) => {
                //reject promise if no content was returned
                if (response.status === 204) resolve(null); 

                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Used to set a service order as complete given an array of day indexes [0-6].
 */
export const _Complete = (getSource: SourceGetter) => {
    return (uniqueId: number, days: int[], pastDueReason: string | null): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-order/' + uniqueId.toString() + "/complete",
                data: {
                    days,
                    pastDueReason
                },
                cancelToken: source.token
            }).then(() => {
                //resolve promise
                resolve(null);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Update a service order
 */
 export const _UpdateSO = (getSource: SourceGetter) => {
    return (uniqueId: number, data: TServiceOrderForm): Promise<ServiceOrder> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ServiceOrder>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-order/update' + uniqueId.toString(),
                data: data,
                cancelToken: source.token,
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Update the notes of a service order
 */
export const _Update = (getSource: SourceGetter) => {
    return (uniqueId: number, values: { notes?: string }): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-order/' + uniqueId.toString(),
                data: {
                    notes: values.notes ?? null
                },
                cancelToken: source.token
            }).then(() => {
                //resolve promise
                resolve(null);
            }).catch(e => reject(e));
        });
    };
};


/**
 * Get a service order tracking result given the serviceOrder uniqueId.
 */
export const _GetTrackingResult = (getSource: SourceGetter) => {
    return (uniqueId: number): Promise<SOTrackingResult | null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<SOTrackingResult | null>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-order/tracking/' + uniqueId.toString(),
                cancelToken: source.token
            }).then((response) => {
                //reject promise if no content was returned
                if (response.status === 204) resolve(null); 

                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

export const _QueryTrackingResults = (getSource: SourceGetter) => {
	return (fromDate?:Moment, toDate?:Moment, uniqueId?:int, pronum?:int, client?:string, keyword?:string, sitenum?:int, depcode?:int, pmid?:int, timing?:boolean, dailysotracking?:boolean, status?: int | string) : Promise<SOTrackingResult[]> => {
        //create request source 
        const source = getSource();

		//1 day subtraction is needed because it was not showing jobs that were scheduled on the fromdate. 
		//a seperate variable is needed so that it does not affect the page where it comes from
		let fromDate2 = fromDate?.clone();
		fromDate2?.subtract(1,'day');

        //make API call and return a promise which resolves into the result
        return new Promise<SOTrackingResult[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-orders/tracking',
				params:{
					fromDate: (fromDate2 ?? moment()).format(),
					toDate: (toDate ?? moment().add(1, "years")).format(),
					uniqueId: uniqueId ?? null,
					pronum: pronum ?? null,
					client: client ?? null,
					keyword: keyword ?? null,
					sitenum: sitenum ?? null,
					depcode: depcode ?? null,
					pmid: pmid ?? null,
					timing: timing ?? null,
					dailysotracking: dailysotracking ?? null,
                    status: status ?? null
				},
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 *  Update a Service Order's Report Type
 */
 export const _UpdateReptType = (getSource: SourceGetter) => {
    return (request: UpdateReptTypeRequest): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-orders/update-rept-type',
                data: request,
                cancelToken: source.token
            }).then(() => {
                //resolve promise
                resolve(null);
            }).catch(e => reject(e));
        });
    };
};

//Prepare, Approve, or SOReportSent a service order give a PrepareApproveReportSentRequest, target must be Prepare, Approve, or Report Sent
export const _PrepareApproveReportSent = (getSource: SourceGetter) => {
    return (request: PrepareApproveReportSentRequest): Promise<string | null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<string | null>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-orders/prepare-approve-reportsentusp',
                data: request,
                cancelToken: source.token
            }).then((response) => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

export const _SOReset = (getSource: SourceGetter) => {
    return (request: SOResetRequest): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-orders/SOReset',
                data: request,
                cancelToken: source.token
            }).then(() => {
                //resolve promise
                resolve(null);
            }).catch(e => reject(e));
        });
    };
};

/* discontinued
// Update the client signature given a uniqueid and a signature file
export const _UpdateSignature = (getSource: SourceGetter) => {
    return  (uniqueid: int, signature: File, name:string, toEmail:boolean, email?:string): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-orders/update-signature/' + uniqueid.toString(),
				data: objectToFormData({ uniqueid:uniqueid, name:name, email:email, file: signature, toEmail: toEmail }),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};
*/

// Update the client signature given a uniqueid and a signature file
export const _CreateSignatureRequest = (getSource: SourceGetter) => {
    return  (request: CreateSignatureRequest): Promise<ServiceOrderSignatureRequest> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<ServiceOrderSignatureRequest>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-orders/signatures',
				data: request,
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

// Update a Service Order Contacts
 export const _UpdateContacts = (getSource: SourceGetter) => {
    return (uniqueid: int, requests: UpdateServiceOrderContactRequest[] ): Promise<null> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<null>((resolve, reject) => {
            axios({
                method: 'put',
                url: '/api/service-orders/contacts/update/' + uniqueid.toString(),
                data: requests,
                cancelToken: source.token
            }).then(() => {
                //resolve promise
                resolve(null);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Get charge status string
 */
 export const _GetChargeStatus = (getSource: SourceGetter) => {
    return  (uniqueid: int): Promise<string> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<string>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-orders/charge-status/' + uniqueid.toString(),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Get payroll information
 */
 export const _GetPayrollEmpHours = (getSource: SourceGetter) => {
    return  (uniqueid: int): Promise<GetPayrollEmpHoursResult[]> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<GetPayrollEmpHoursResult[]>((resolve, reject) => {
            axios({
                method: 'get',
                url: '/api/service-orders/payroll/' + uniqueid.toString(),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};

/**
 * Sync 
 */
 export const _TimeSheetSync = (getSource: SourceGetter) => {
    return  (uniqueid: int, employeeid: int, weekOf: DateString, editorid: int): Promise<boolean> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<boolean>((resolve, reject) => {
            axios({
                method: 'post',
                url: '/api/service-orders/payroll/sync/' + uniqueid.toString() + '/' + employeeid.toString() + '/' + weekOf + '/' + editorid.toString(),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => reject(e));
        });
    };
};