import { useCallback, useEffect, useMemo } from 'react';
import { useApi, useLoadApi } from '@imas/api';
import { 
	GetServiceOrderWorkPlaceReview, CreateServiceOrderWorkPlaceReview, UpdateServiceOrderWorkPlaceReview,
	ServiceOrderWorkPlaceReview, ServiceOrderWorkPlaceReviewForm as TServiceOrderWorkPlaceReviewForm, GetServiceOrderFullJobName
} from '@imas/api/service-order';
import { Alignment } from '@imas/components/layout';
import { FormTextField, MuiFormSubmit, MuiFormSubmitProps, useMuiFormControl, useMuiFormRef } from '@imas/mui-form';
import { isPopup } from '@imas/utils/misc';
import { ResourceCollaboratorGroup, RESOURCES } from '@imas/utils/resource';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { ExitToApp, Save as SaveIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { Navigate } from 'react-router-dom';
import { useEditorState } from '@imas/utils/editor';
import { 
	ServiceOrderWorkPlaceReviewForm, 
	defaultValues as serviceOrderWorkPlaceReviewFormDdfaultValues 
} from './ServiceOrderWorkPlaceReviewForm/ServiceOrderWorkPlaceReviewForm';
import { useConfirmationDialog } from '@imas/utils/dialog';
import { openEditorWindow, sendEditorUpdate } from '@imas/utils/editor';
import { useSelector } from 'react-redux';
import { getEmployeeData } from '@imas/redux';
import moment from 'moment';
import { Button, TextField } from '@mui/material';

type OpenServiceOrderWorkPlaceReviewEditorParams = { serviceOrderId?: string; };

type OpenServiceOrderWorkPlaceReviewEditorOptions = { 
	workPlaceReviewId: int; 
	serviceOrderId?: undefined;
 } | {
	workPlaceReviewId?: undefined;
	serviceOrderId: int; 
}; 

export const openServiceOrderWorkPlaceReviewEditor = ({ workPlaceReviewId, serviceOrderId }: OpenServiceOrderWorkPlaceReviewEditorOptions): Window | null => {
	return openEditorWindow<OpenServiceOrderWorkPlaceReviewEditorParams>(
		IMAS.PAGES.SERVICE_ORDERS.WORK_PLACE_REVIEWS(), 
		{ id: workPlaceReviewId ?? null, serviceOrderId: serviceOrderId ? `${serviceOrderId}` : undefined }, 
		{ height: 850, width: 900, name: workPlaceReviewId ? `SO Work Place Review #${workPlaceReviewId} Edit Form` : "New SO Work Place Review" }
	);
};

export const ServiceOrderWorkPlaceReviewEditor = () => {
	//page title
    document.title = "IMAS - Saftey Review";

	//editor state
	const [state, setState] = useEditorState<OpenServiceOrderWorkPlaceReviewEditorParams>({ url: IMAS.PAGES.SERVICE_ORDERS.WORK_PLACE_REVIEWS() });

	//serviceOrderId
	const serviceOrderId = state.serviceOrderId ? isNaN(Number(state.serviceOrderId)) ? null : Number(state.serviceOrderId) : null;

	//use automatic snackbar
    const showSnackbar = useAutomaticSnackbar();

	//use confirmation dialog
	const [confirm] = useConfirmationDialog();

    //use APIs
    const createWorkPlaceReviewApi = useApi(CreateServiceOrderWorkPlaceReview);
    const updateWorkPlaceReviewApi = useApi(UpdateServiceOrderWorkPlaceReview);

	//get current employee info
	const currentEmployee = useSelector(getEmployeeData);

    //useFormRef for workPlaceReview form
    const [formRef, onFormRef] = useMuiFormRef<TServiceOrderWorkPlaceReviewForm, ServiceOrderWorkPlaceReview>();

    //load the specified workPlaceReview if state is a number
    const { data: workPlaceReview, error: workPlaceReviewError, clear: clearClient } = useLoadApi(GetServiceOrderWorkPlaceReview, [state.id ?? -1], [state.id], { disabled: state.id === null });

	//load service order fullJobName using workPlaceReview.serviceOrderId or serviceOrderId from editor params
	const jobNameServiceOrderId = useMemo(() => workPlaceReview?.serviceOrderId ?? serviceOrderId ?? null, [workPlaceReview]);
	const { data: serviceOrderFullJobName } = useLoadApi(GetServiceOrderFullJobName, [jobNameServiceOrderId ?? -1], [jobNameServiceOrderId], { disabled: jobNameServiceOrderId === null });

    //reset form to blank for a new item
    useEffect(() => {
        //clear any loaded workPlaceReview record
        clearClient();

        if (state.id === null) {
            formRef?.reset({
				...serviceOrderWorkPlaceReviewFormDdfaultValues,
                serviceOrderId,
				date: moment().toDate(),
            });
        }
    }, [formRef?.reset, state]);

    //reset the form to editing the loaded workPlaceReview
    useEffect(() => {
        if (workPlaceReview === undefined) return;
        else {
            formRef?.reset({
                ...workPlaceReview,
				date: workPlaceReview.date ? moment(workPlaceReview.date).toDate() : null,
            });
        }
    }, [workPlaceReview]);

    //create a new ServiceOrderWorkPlaceReview record
    const createClient = useCallback(async (data: TServiceOrderWorkPlaceReviewForm) => {
        //progress notification
        const close = showSnackbar("Creating safety review...", { variant: 'info', persist: true });
        
        try {
            //call API
            const record = await createWorkPlaceReviewApi(data);

            //close notification
            close();
			
			//send editor update
			sendEditorUpdate("/service-orders/work-place-reviews", "CREATE", record);

            //return the new record
            return record;
        } catch (e) {
            //close notification & re-throw error
            throw close(e);
        }
    }, [createWorkPlaceReviewApi, showSnackbar]);

    //update a ServiceOrderWorkPlaceReview record
    const updateClient = useCallback(async (workPlaceReviewId: int, data: TServiceOrderWorkPlaceReviewForm) => {
        //progress notification
        const close = showSnackbar("Updating safety review...", { variant: 'info', persist: true });
        
        try {
            //call API
            const record = await updateWorkPlaceReviewApi(workPlaceReviewId, data);
            
            //close notification
            close();

			//send editor update
			sendEditorUpdate("/service-orders/work-place-reviews", "UPDATE", record);

            //return the updated record
            return record;
        } catch(e) {
            //close notification & re-throw error
            throw close(e);
        }
    }, [updateWorkPlaceReviewApi, showSnackbar]);

	//submit handler
	const onSubmit = useCallback<MuiFormSubmitProps<TServiceOrderWorkPlaceReviewForm, ServiceOrderWorkPlaceReview>["onSubmit"]>(async (data) => {
		//check if a workPlaceReview record is loaded, if not create a new record
		if (!workPlaceReview) {
			return await createClient(data);
		} 
		//otherwise update the loaded record
		else {
			return await updateClient(workPlaceReview.id, data);
		};
	}, [workPlaceReview, createClient, updateClient]);

	//if the form can be edited (true if the form is in create new mode or the logged in user is the one who created the form)
	const canEdit = state.id === null || (workPlaceReview && currentEmployee?.employeeId === workPlaceReview?.reviewedByEmployeeId);

    //close form if there was an error loading the workPlaceReview
    if (workPlaceReviewError !== undefined) {
		if (isPopup()) window.close();
		return <Navigate to={"/"} replace/>;
	}

    return (
        <Alignment column flex sx={{ margin: '10px' }}>
			<Alignment column flex>
				<ServiceOrderWorkPlaceReviewForm
					key={state.id ?? "new"}
					viewOnly={!canEdit}
					fullJobName={serviceOrderFullJobName}
					serviceOrderId={serviceOrderId}
					review={state.id === null ? null : workPlaceReview}
					loading={state.id !== null && workPlaceReview === undefined}
					ref={onFormRef}
				/>				
			</Alignment>
            
			{state.id === null ? (
				<Alignment rowReverse sx={{ marginTop: '10px', "& > *:not(:last-child)": { marginLeft: '10px' } }}>
					{/* Continue Button */}
					<MuiFormSubmit
						formRef={formRef}
						onSubmit={onSubmit}
						onSuccessfulSubmit={(newClient) => {
							setState({ id: newClient.id }, { replace: true });
						}}
						startIcon={<ArrowForwardIcon/>}
					>{"Continue"}</MuiFormSubmit>

					<Button
					variant = "contained"
					color = "secondary"
					onClick={() => {window.close(); }}
					>{"Cancel"}</Button>

				</Alignment>
			) : (
				<Alignment rowReverse sx={{ marginTop: '10px', "& > *:not(:last-child)": { marginLeft: '10px' } }}>
					{/* Save & Exit Button (Only show in a popup.) */}
					{isPopup() ? (
						<MuiFormSubmit
							disabled={!canEdit}
							formRef={formRef}
							onSubmit={onSubmit}
							onSuccessfulSubmit={() => {
								//call window.close() to close the popup window
								window.close();
							}}
							startIcon={<ExitToApp/>}
						>{"Save & Exit"}</MuiFormSubmit>
					) : null}

					{/* Save Button */}
					<MuiFormSubmit
						disabled={!canEdit}
						formRef={formRef}
						onSubmit={onSubmit}
						onSuccessfulSubmit={(newClient) => {
							//if a new server was just created then change to edit the new record
							if (!workPlaceReview) setState({ id: newClient.id }, { replace: true });
						}}
						startIcon={<SaveIcon/>}
					>{"Save"}</MuiFormSubmit>

					<Button
						variant = "contained"
						color = "secondary"
						onClick={() => {window.close(); }}
					>{"Exit"}</Button>

					{/* Resource Collaborators for this Client Form */}
					{workPlaceReview ? <ResourceCollaboratorGroup resource={RESOURCES.serviceOrderWorkPlaceReviews(workPlaceReview.id)} noPadding/> : null}
				</Alignment>
			)}
		</Alignment>
	);
};