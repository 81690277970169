import { useState, useEffect, useContext, useMemo } from 'react';
import { Button, Dialog, DialogContent, DialogActions, List, ListItem, ListItemText, Typography } from '@mui/material';
import { ScheduleEventEntry, ScheduleType } from 'src/api/types/api/schedule/schedule';
import { useApi, useLoadApi } from '@imas/api';
import { EmployeeCheckIn, EmployeeUndoCheckIn, EmployeeUndoCheckInStatus } from 'src/api/employee/api';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { ConfirmationDialog } from '@imas/components/dialogs';
import { Alignment, Spacer, LabeledItem } from '@imas/components/layout';
import { Undo as UndoIcon } from '@mui/icons-material';
import { EmployeeWorkEvent } from 'src/api/types/api/employee/employee';
import { DailyScheduleViewerContext } from '../DailyScheduleViewerContext';
import { registerPeriodicSyncTask, NotificationType, PeriodicSyncTasks } from '@imas/service-worker';
import { clearNotification } from '@imas/utils/notification';
import { SlideUp } from '@imas/components/transitions';
import { XDialogTitle } from '@imas/components/mui';
import { useNavigate, useParams } from 'react-router';
import { checkInStyles } from './CheckInStyles';
import useInterval from '@use-it/interval';
import moment, { Moment } from 'moment';
import { getUserRoles } from '@imas/redux';
import { useSelector } from 'react-redux';

export interface CheckInProps {
    entries: ScheduleEventEntry[];
	setType: (arg0: ScheduleType) => void;
	setDay: (day: Moment) => void;
};

/** Button which is used to allow user's to go through the check in process. */
export const CheckIn = ({ entries, setType, setDay }: CheckInProps) => {
    const { classes, deviceInfo, cx } = checkInStyles();

    //use react router navigate
    const navigate = useNavigate();

	//get user roles
	const userRoles = useSelector(getUserRoles);

    //use automatic snackbar
    const showSnackbar = useAutomaticSnackbar();

    //use apis
    const employeeCheckIn = useApi(EmployeeCheckIn);
    const employeeUndoCheckIn = useApi(EmployeeUndoCheckIn);

    //get react router params to get the current schedule state
    const { state: scheduleState } = useParams();

    //use params & search params
/*     const [searchParams] = useSearchParams();
	const selectedEvent = useMemo(() => {

	}, [searchParams]); */


    //consume context
    const { lastEvent, selectedDay, refreshEvents } = useContext(DailyScheduleViewerContext);
    
    //selected reason for clock in
    const [selectedReason, setSelectedReason] = useState<{ id: int, typeId: int, locationTypeId: int, selectedId: int }>();

    //check if there is an event which the employee can undo
    const { data: undoEvent, call: refreshUndoEvent } = useLoadApi(EmployeeUndoCheckInStatus, [], []);

    //detect lastEvent change, if it is null, check if there is a existing "Checked In" notification, if there is then clear it
    useEffect(() => {
        if (lastEvent === null && scheduleState === "checking-in") {

            //try and clear the notification if it exists
            clearNotification(NotificationType.CheckInReminder);   
        }
    }, [lastEvent]);

    //clear selectedReason if lastEvent changes
    useEffect(() => setSelectedReason(undefined), [lastEvent]);

    //on interval refresh events & undoEvent
    useInterval(() => {
        refreshEvents();
        refreshUndoEvent();
    }, 10 * 60 * 1000);

    //if the check in dialog is opened & lastEvent is null & entries.length = 0 set the selected reason to general
    useEffect(() => {
        if (!lastEvent) {
            if (entries.length === 0 && scheduleState === "checking-in") {
                setSelectedReason({ id: 0, typeId: 0, locationTypeId: 0, selectedId: 0 });
			} else {
                setSelectedReason(undefined);
            }
        }
		if (scheduleState === 'checking-into-job'){
            //this is an issue when refreshing the page in the checking into job from full schedule
			setSelectedReason({id: entries[0].uniqueId ?? 0, typeId: 1, locationTypeId: entries[0].workLocationTypeId, selectedId: entries[0].id });
		}
    }, [entries, setSelectedReason, scheduleState]);

    //get filtered entries
    const getFilteredEntries = (): ScheduleEventEntry[] => {
        var temp = entries ?? [];

        //filter out entry which user is currently clocked in for
        temp = temp.filter(x => x.uniqueId !== lastEvent?.workId && x.soEmpAssignId !== lastEvent?.workId);

		if (scheduleState === "checking-into-job" && temp.length > 1) temp = [temp[0]];

        //if lastEvent is not null then return a list which only contains field entries or coaching sessions
        if (lastEvent && scheduleState !== "checking-into-job") return temp.filter(x => (x.workLocationTypeId === 1) /* || (x.dailyAssignmentId !== null) */);

        return temp;
    };

    //show new event notifications
    const showEventNotifications = (events: EmployeeWorkEvent[]) => {
        //show a notification for events
        for (var event of events) {
            //get message text based on event type
            var message = "Invalid event at ";
            if (event.typeId === 2) message = "Checked in at ";
            else if (event.typeId === 3) message = "Checked into site at ";
            else if (event.typeId === 4) message = "Checked out of site at ";
            else if (event.typeId === 5) message = "Checked out at ";

            //get timestamp string
            const timestamp = moment(event.inDateTime).format("h:mma");

			//only show 1 snackbar for check into job
			if (event.typeId === 5 && scheduleState === "checking-into-job") continue;

            //show toast
            showSnackbar(message + timestamp + ".", { variant: "success" });
        };
    };

    //call checkIn api
    const checkIn = (info?: { workId: int, workTypeId: int, workLocationTypeID: int }, onSite?: boolean) => {        
        //get user location
        navigator.geolocation.getCurrentPosition(position => {
            //call api
            employeeCheckIn({
                empEventId: lastEvent?.id,
                workId: info?.workId,
                workTypeId: info?.workTypeId,
                workLocationTypeId: info?.workLocationTypeID,
                onSite: onSite,
				intoJob: scheduleState === "checking-into-job",
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                accuracy: position.coords.accuracy
            })
            .then(newEvents => {
                //if this was a check in event then register the check-checked-in periodic sync task and show the checked in notification
                if (lastEvent === null) {
                    //register periodic sync 
                    registerPeriodicSyncTask(PeriodicSyncTasks.CheckCheckedIn, 240);
                }

                //refresh last & undo event
                refreshEvents();
                refreshUndoEvent();

                //show a notification for events
                showEventNotifications(newEvents);
				if (scheduleState === 'checking-into-job') setType(ScheduleType.ME);
				if (scheduleState === 'checking-into-job') setDay(moment());
            })
			.catch(e => {
				//refresh last & undo event
				refreshEvents();
				refreshUndoEvent();

				return showSnackbar("Something went wrong, please try again.", { variant: "error" }, false);

			});
        }, () => {
            //give error to the user
            showSnackbar("Unable to get your location.", { variant: "error" }, false);
        }, { enableHighAccuracy: true, maximumAge: 0 });
    };

    //call checkIn api twice to do the Leaving Site & Check Out Actions at the same time
    const endDay = () => {
        //get user location
        navigator.geolocation.getCurrentPosition(position => {
            //call api to complete the "Leaving Site" step
            employeeCheckIn({
                empEventId: lastEvent?.id,
				intoJob: scheduleState === "checking-into-job",
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                accuracy: position.coords.accuracy
            })
            .then(newEvents => {
                //get first event id
                const newEventId: int | undefined = newEvents[0]?.id;

                //if newEventId is undefined show an error
                if (newEventId === undefined) {
                    return showSnackbar("Something went wrong during end day, please try again.", { variant: "error" }, false);
                }

                //call api to complete the "Check Out" step
                employeeCheckIn({
                    empEventId: newEventId,
					intoJob: scheduleState === "checking-into-job",
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    accuracy: position.coords.accuracy
                })
                .then(newEvents => {
                    //refresh last & undo event
                    refreshEvents();
                    refreshUndoEvent();

                    //show a notification for events
                    showEventNotifications(newEvents);
                });
            })
			.catch(e => {
				//refresh last & undo event
				refreshEvents();
				refreshUndoEvent();

				return showSnackbar("Something went wrong, please try again.", { variant: "error" }, false);

			});
        }, () => {
            //give error to the user
            showSnackbar("Unable to get your location.", { variant: "error" }, false);
        }, { enableHighAccuracy: true, maximumAge: 0 });
    };

    //undo a check in event
    const undoCheckIn = () => {
        //show notification
        const close = showSnackbar("Undoing last action...", { variant: "info", persist: true });

        //call api
        employeeUndoCheckIn()
        .then(() => {
            //close notification
            close();

            //refresh last & undo event
            refreshEvents();
            refreshUndoEvent();

            //show success
            showSnackbar("Last action successfully undone.", { variant: "success" });
        }).catch(() => { close(); showSnackbar("Unable to undo last action.", { variant: "error" }, false); });
    };

    //get check in event type text
    const getCheckInType = (event: EmployeeWorkEvent | null | undefined) => {
        if (event === null || event === undefined) return "Check In"; 
        else if (event.typeId === 2) {
            if (event.workLocationTypeId === 0) return "Check Out";
            else if (event.workLocationTypeId === 1) return "On Site";
        }
        else if (event.typeId === 3) return "Leaving Site";
        else if (event.typeId === 4) return "Check Out";
    };

    //get undo event type text
    const getUndoCheckInType = (event: EmployeeWorkEvent | null | undefined) => {
        if (!event) return "";
        if (event.typeId === 2) return "Check In";
        else if (event.typeId === 3) {
            if (event.workLocationTypeId === 0) return "Check Out";
            else if (event.workLocationTypeId === 1) return "On Site";
        }
        else if (event.typeId === 4) return "Leaving Site";
        else if (event.typeId === 5) return "Check Out";
    };

    //get check in button style
    const getCheckInButtonStyle = () => {
        if (lastEvent?.typeId === 2) {
            if (lastEvent?.workLocationTypeId === 1) return classes.checkingIntoSite;
        }
        else if (lastEvent?.typeId === 3) return classes.checkingOutOfSite;
        return undefined;
    };

    //close function
    const close = () => navigate(-1);

    return <>
        {/* Check In/Out Button */}
		{(scheduleState !== "checking-into-job") ? (
        <Alignment row className={classes.checkInContainer} hidden={!(selectedDay.isSame(moment(), "day") || selectedDay.isSame(moment().subtract(1, "day"), "day"))}>
            {/* Check In Btn */}
            <Button
                disabled={lastEvent === undefined}
                color={lastEvent ? "secondary" : "primary"}
                variant={"contained"}
                className={cx(classes.checkInBtn, getCheckInButtonStyle())}
                onClick={() => {
                    if (lastEvent === undefined) return;

                    //if the lastEvent === null open check in dialog
                    if (lastEvent === null) return navigate("/dashboard/schedule/checking-in"); 

                    //if the lastEvent.typeId === 3 and there is more than 1 ScheduleEventEntry in their schedule open the check in dialog
                    if (lastEvent.typeId === 3) return navigate("/dashboard/schedule/leaving-site"); 

                    //open confirmation dialog
                    navigate("/dashboard/schedule/confirm");
                }}
            >{getCheckInType(lastEvent)}</Button>

            {/* Undo Button */}
            {undoEvent ? (
                <Button
                    variant={"contained"}
                    className={classes.undoCheckInBtn}
                    onClick={() => navigate("/dashboard/schedule/undo-last")}><UndoIcon/></Button>
            ) : null}
        </Alignment>
		) : null}

        {/* Confirmation for Undoing last Check-in event.  */}
        <ConfirmationDialog
            prompt={"You are about to undo the following."}
            body={
                <>
                    <Spacer vertical/>
                    <LabeledItem row label={"Action:"} item={getUndoCheckInType(undoEvent)}/>
                    <LabeledItem row label={"Time:"} item={moment(undoEvent?.inDateTime).format("h:mma")}/>
                </>
            }
            open={scheduleState === "undo-last"}
            confirmText={"Undo"}
            cancelText={"Cancel"}
            onConfirm={() => {
                //call undoCheckIn
                undoCheckIn();

                //close dialog
                close();
            }}
            onCancel={close}
        />

        {/* Basic Confirmation Dialog for Check In */}
        <ConfirmationDialog
            prompt={"Are you sure you would like to do this?"}
            open={scheduleState === "confirm"}
            confirmText={"Continue"}
            cancelText={"Cancel"}
            onConfirm={() => {
                //call checkIn
                checkIn();

                //close dialog
                close();
            }}
            onCancel={close}
        />

        {/* Check In Dialog */}
        <Dialog 
            open={scheduleState === "checking-in" || scheduleState === "leaving-site" || scheduleState === "checking-into-job"} 
            onClose={close} 
            maxWidth={"sm"} 
            className={classes.dialog}
            fullScreen={!deviceInfo.isSm} 
            TransitionComponent={!deviceInfo.isSm ? SlideUp : undefined}
        >
            {!deviceInfo.isSm? ( 
                <XDialogTitle
                    onClose={close}
                    fullScreen
                >{lastEvent ? scheduleState === "checking-into-job" ? "Checking In" : "Leaving Site" : "Checking In"}</XDialogTitle>
            ) : null}
            <DialogContent>
                {/* List of Service Orders / Tasks to Check In For (and a General Option) */}
                <List>
                    {/* If lastEvent is not null show Check Leaving Site Button */}
                    {scheduleState === "checking-into-job" ? null : lastEvent ? (
                        <>
                            <Button
                                color={"secondary"}
                                variant={"contained"}
                                onClick={() => { endDay(); close(); }}
                                style={{width: "-webkit-fill-available"}}
                            >{"Done for the Day"}</Button>
                            <Spacer vertical size={"20px"}/>

                            <Button
                                color={"secondary"}
                                variant={"contained"}
                                onClick={() => { checkIn(); close(); }}
                                style={{width: "-webkit-fill-available"}}
                                className={classes.checkingOutOfSite}
                            >{"Going Back to PNL"}</Button>
                            <Spacer vertical size={"20px"}/>

                            {getFilteredEntries().length > 0 ? <Typography variant={"h5"}>{"Going to Another Job (Pick One)"}</Typography> : null}
                        </>
                    ) : null}

                    {lastEvent === null || scheduleState === "checking-into-job" ? <Typography variant={"h5"}>{"Select Task"}</Typography> : null}

                    {/* Only show General as a clock in reason if they are on the check in step. */}
                    {(lastEvent || scheduleState === "checking-into-job") ? null : (
                        /* General Clock In Reason */
                        <ListItem button onClick={() => setSelectedReason({ id: 0, typeId: 0, locationTypeId: 0, selectedId: 0 })} selected={selectedReason?.selectedId === 0}>
                            <ListItemText
                                primary={"General"}
                                secondary={"PNL"}
                            />
                        </ListItem>
                    )}

                    {/* Select a Reason for Clock In */}
                    {getFilteredEntries().map((entry, index) => {
                        //handle item click
                        const handleClick = () => {
                            //if uniqueId is provided
                            if (entry.uniqueId) {
                                setSelectedReason({ id: entry.uniqueId, typeId: 1, locationTypeId: entry.workLocationTypeId, selectedId: entry.id });
                            }
							//if dailyAssignmentId is provided
							else if (entry.dailyAssignmentId) {
                                setSelectedReason({ id: entry.dailyAssignmentId, typeId: 2, locationTypeId: entry.workLocationTypeId, selectedId: entry.id });
							}
                        };

                        return (
                            <ListItem key={index} button onClick={handleClick} selected={selectedReason?.selectedId === entry.id}>
                                <ListItemText
                                    primary={entry.fullJobName}
                                    secondary={entry.client}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                {/* Cancel Button */}
                <Button
                    color={"secondary"}
                    variant={"contained"}
                    onClick={close}
                >{"Cancel"}</Button>

				{/* Office Bypass Button */}
                {selectedReason !== undefined && selectedReason.locationTypeId === 1 && (lastEvent?.typeId === undefined || lastEvent?.typeId === 5 || scheduleState === "checking-into-job") && userRoles.powerUser ? <Button
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => {
                        if (!selectedReason) return;

                        //call checkIn using the data from selectedReason
                        checkIn({
                            workId: selectedReason.id,
                            workTypeId: selectedReason.typeId,
                            workLocationTypeID: 0,
                        }, true);

                        //close dialog
                        close();
                    }}
                    className={classes.checkingIntoSite}
                >{"Remote"}</Button> : null}

                {/* On Site Button */}
                {selectedReason !== undefined && selectedReason.locationTypeId === 1 && (lastEvent?.typeId === undefined || lastEvent?.typeId === 5 || scheduleState === "checking-into-job") ? <Button
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => {
                        if (!selectedReason) return;

                        //call checkIn using the data from selectedReason
                        checkIn({
                            workId: selectedReason.id,
                            workTypeId: selectedReason.typeId,
                            workLocationTypeID: selectedReason.locationTypeId
                        }, true);

                        //close dialog
                        close();
                    }}
                    className={classes.checkingIntoSite}
                >{"On Site"}</Button> : null}

                {/* Confirm Button */}
                <Button
                    disabled={selectedReason === undefined}
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => {
                        if (!selectedReason) return;

                        //call checkIn using the data from selectedReason
                        checkIn({
                            workId: selectedReason.id,
                            workTypeId: selectedReason.typeId,
                            workLocationTypeID: selectedReason.locationTypeId
                        });

                        //close dialog
                        close();
                    }}
                >{scheduleState === "checking-into-job" ? "Check In" : lastEvent ? "Check In To Next Job" : "Check In"}</Button>
            </DialogActions>
        </Dialog>
    </>;
};