import { useCallback, useEffect } from 'react';
import { useApi, useLoadApi } from '@imas/api';
import { 
	GetClient, CreateClient, UpdateClient, DeleteClient,
	Client, ClientForm as TClientForm
} from '@imas/api/client';
import { Alignment } from '@imas/components/layout';
import { MuiFormSubmit, MuiFormSubmitProps, useMuiFormRef } from '@imas/mui-form';
import { isPopup } from '@imas/utils/misc';
import { ResourceCollaboratorGroup, RESOURCES } from '@imas/utils/resource';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { ExitToApp, Save as SaveIcon, Delete as DeleteIcon, FindInPage as FindInPageIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useNavigate, Navigate } from 'react-router-dom';
import { useEditorState } from '@imas/utils/editor';
import { ClientForm } from './ClientForm/ClientForm';
import { useConfirmationDialog } from '@imas/utils/dialog';
import { openEditorWindow, sendEditorUpdate } from '@imas/utils/editor';
import { getUserRoles } from '@imas/redux';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { openClientReportViewer } from './ClientReportViewer';

export const openClientEditor = (clientId?: int): Window | null => {
	return openEditorWindow<{}>(IMAS.PAGES.CLIENTS(), { id: clientId ?? null }, { height: 900, width: 1350, name: clientId ? `Client #${clientId} Edit Form` : "New Client Form" });
};

export const ClientEditor = () => {
	//page title
    document.title = "IMAS - Client Editor";

	//editor state
	const [state, setState] = useEditorState({ url: IMAS.PAGES.CLIENTS() });
    
    //check if the user is an admin
    const { admin: isAdmin } = useSelector(getUserRoles);

	//use automatic snackbar
    const showSnackbar = useAutomaticSnackbar();

	//use navigate
	const navigate = useNavigate();

	//use confirmation dialog
	const [confirm] = useConfirmationDialog();

    //use APIs
    const createClientApi = useApi(CreateClient);
    const updateClientApi = useApi(UpdateClient);
    const deleteClientApi = useApi(DeleteClient);

    //useFormRef for client form
    const [formRef, onFormRef] = useMuiFormRef<TClientForm, Client>();

    //load the specified client if state is a number
    const { data: client, error: clientError, clear: clearClient } = useLoadApi(GetClient, [state.id ?? -1], [state.id], { disabled: state.id === null });

    //reset form to blank for a new item
    useEffect(() => {
        //clear any loaded client record
        clearClient();

        if (state.id === null) {
            formRef?.reset({
                name: null,
				address: null,
				city: null,
				state: null,
				zip: null,
				country: null,
				webpage: null,
				revenueMM: null,
				revenueMMAsOfDate: null,
				notes: null,
				active: true,
				clientCui: false,
				creditApproved: false,
				paymentTerms: null,
				badCredit: false,
				correspondencetype: 0,
				clientShort: null,
				signaturemethodtype: 0
            });
        }
    }, [state]);

    //reset the form to editing the loaded client
    useEffect(() => {
        if (client === undefined) return;
        else {
            formRef?.reset({
                ...client,
				revenueMMAsOfDate: client.revenueMMAsOfDate ? moment(client.revenueMMAsOfDate).toDate() : null,
            });
        }
    }, [client]);

    //create a new client record
    const createClient = useCallback(async (data: TClientForm) => {
        //progress notification
        const close = showSnackbar("Creating client...", { variant: 'info', persist: true });
        
        try {
            //call API
            const record = await createClientApi(data);

            //close notification
            close();
			
			//send editor update
			sendEditorUpdate("/clients", "CREATE", record);

            //return the new record
            return record;
        } catch (e) {
            //close notification & re-throw error
            throw close(e);
        }
    }, [createClientApi, showSnackbar]);

    //update a client record
    const updateClient = useCallback(async (clientId: int, data: TClientForm) => {
        //progress notification
        const close = showSnackbar("Updating client...", { variant: 'info', persist: true });
        
        try {
            //call API
            const record = await updateClientApi(clientId, data);
            
            //close notification
            close();

			//send editor update
			sendEditorUpdate("/clients", "UPDATE", record);

            //return the updated record
            return record;
        } catch(e) {
            //close notification & re-throw error
            throw close(e);
        }
    }, [updateClientApi, showSnackbar]);

	//delete a client record
	const deleteClient = useCallback(async (clientId: int) => {
        //progress notification
        const close = showSnackbar("Deleting client...", { variant: 'info', persist: true });
        
        try {
            //call API
            const record = await deleteClientApi(clientId);
            
            //close notification
            close();

            //return the deleted record
            return record;
        } catch(e) {
            //close notification & re-throw error
            throw close(e);
        }
    }, [deleteClientApi, showSnackbar]);

	//submit handler
	const onSubmit = useCallback<MuiFormSubmitProps<TClientForm, Client>["onSubmit"]>(async (data) => {
		//check if a client record is loaded, if not create a new record
		if (!client) {
			return await createClient(data);
		} 
		//otherwise update the loaded record
		else {
			return await updateClient(client.id, data);
		};
	}, [client, createClient, updateClient]);

    //close form if there was an error loading the client
    if (clientError !== undefined) {
		if (isPopup()) window.close();
		return <Navigate to={"/"} replace/>;
	}

    return (
        <Alignment column flex sx={{ margin: '10px' }}>
			<Alignment column flex>
				<ClientForm
					key={state.id ?? "new"}
					client={state.id === null ? null : client}
					loading={state.id !== null && client === undefined}
					ref={onFormRef}
				/>
			</Alignment>
            
            <Alignment rowReverse sx={{ marginTop: '10px', "& > *:not(:last-child)": { marginLeft: '10px' } }}>
                {/* Save & Exit Button (Only show in a popup.) */}
                {isPopup() ? (
					<MuiFormSubmit
						formRef={formRef}
						onSubmit={onSubmit}
						onSuccessfulSubmit={() => {
							//call window.close() to close the popup window
							window.close();
						}}
						startIcon={<ExitToApp/>}
					>{"Save & Exit"}</MuiFormSubmit>
				) : null}

				{/* Save Button */}
				<MuiFormSubmit
                    formRef={formRef}
                    onSubmit={onSubmit}
                    onSuccessfulSubmit={(newClient) => {
						//if a new server was just created then change to edit the new record
						if (!client) setState({ id: newClient.id }, { replace: true });
					}}
                    startIcon={<SaveIcon/>}
                >{"Save"}</MuiFormSubmit>

				{/* Resource Collaborators for this Client Form */}
				{client ? <ResourceCollaboratorGroup resource={RESOURCES.client(client.id)} noPadding/> : null}

				{/* View report pdf */}
				<Button
					variant={"contained"}
					color={"primary"}
					disabled={client === undefined}
					onClick={() => openClientReportViewer(client?.id ?? -1)}
					startIcon={<FindInPageIcon/>}
					sx={{marginRight: '140px'}}
				>{'Preview'}</Button>

				{/* Delete Button (Admin Only) */}
				<Button
					variant={"contained"}
					color={"secondary"}
					disabled={client === undefined}
					onClick={async () => {
						//ensure a client is loaded
						if (!client) return;
						if (!isAdmin) return showSnackbar("You must be an admin to delete a client.", { variant: 'error' });

						//confirm that the user wants to delete this client
						const deleted = await confirm({
							prompt: `Are you sure you would like to delete the following client: '${client.name}'?`,
							confirmText: "Delete",
							confirmIcon: <DeleteIcon/>,
							onConfirm: async () => {
								//call API
								await deleteClient(client.id);
							}
						});

						//if client was successfully deleted
						if (deleted) {
							//send editor update
							sendEditorUpdate("/clients", "DELETE", client);

							//if this window is a popup close the window
							if (isPopup()) window.close();

							//otherwise navigate to the clients & contacts page
							else navigate(IMAS.PAGES.CLIENTS_AND_CONTACTS.SEARCH(), { replace: true });
						}
					}}
					startIcon={<DeleteIcon/>}
					sx={{ marginRight: 'auto' }}
				>{"Delete"}</Button>
			</Alignment>
		</Alignment>
	);
};