import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useMuiWatch, useMuiFormContext } from '@imas/mui-form';
import { ButtonGroup, Button, Skeleton, Box, useTheme } from '@mui/material';
import { CameraAlt as CameraAltIcon, CloudUpload as CloudUploadIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { resizeImage } from '@imas/utils/files';
import { Alignment } from '@imas/components/layout';
import { XMasonry } from '@imas/components/mui';
import { PreviewImage } from '@imas/components/files';
import { useApi, useLoadApi } from '@imas/api';
import { FileTableFile, FileTables, GetFile } from '@imas/api/files';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { TVehicleInspectionForm } from 'src/api/types/api/misc/misc';
import { vibrate } from '@imas/utils/misc';
import { DeleteVehicleFile } from 'src/api/misc/vehicles';
import { VehicleInspectionFormContext } from '../VehicleInspectionForm/VehicleInspectionFormContext';



const VehicleInspectionAttachment = () => {
	const theme = useTheme();

	//use snackbar
	const snackbar = useAutomaticSnackbar();

	const { control, getValues, setValue } = useMuiFormContext<TVehicleInspectionForm>();

	const { vehicleInspection } = useContext(VehicleInspectionFormContext);


    const [file1, file2, file3, file4, file5] = useMuiWatch({ control, name: ['file1', 'file2', 'file3', 'file4', 'file5'] });

	//api
	const deleteVehicleFile = useApi(DeleteVehicleFile);

	//selected files
    const [selected, setSelected] = useState<(HierarchyId | File)[]>([]);

    //handle new files when the are uploaded
    const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        //ensure files exist
        if (event.target.files === null || event.target.files.length === 0) return;

        //get files in a list
        const files: File[] = [];

        for (const file of event.target.files) files.push(file);
        
        //resize images if any where provided
        Promise.all(files.map(x => resizeImage(x)))
        .then(resizedFiles => {

			resizedFiles.map(file => {
				//get current values
				const current = getValues();

				//put new attachments in empty slots, put out an error message if its full
				if (current.file1 === null) setValue('file1', file);
				else if (current.file2 === null) setValue('file2', file);
				else if (current.file3 === null) setValue('file3', file);
				else if (current.file4 === null) setValue('file4', file);
				else if (current.file5 === null) setValue('file5', file);
				else snackbar(`Error: The maximum number of attachments have been reached.`, { variant: 'error' });
			});

        })
        .catch(e => console.log(e));
    };

    //callbacks for selecting files
    const onSelected = useCallback((file: HierarchyId | File, source: "click" | "press") => {
        setSelected(x => {
            if (x.length < 1) {
                if (source === "click") return x;

                vibrate("long-press");
                return [file];
            };
            
            return x.includes(file) ? x.filter(y => y !== file) : [...x, file];
        });
    }, []);

	//handle delete button press
    const deleteSelected = useCallback(() => {
        setSelected(x => {
            //get the current values the files
            const [file1Current, file2Current, file3Current, file4Current, file5Current] = getValues(['file1', 'file2', 'file3', 'file4', 'file5']);

			//if the file is new (and has not been saved), just set null. Otherwise delete from the database
			x.forEach((file) => {
				if (file === file1Current) {
					setValue('file1', null);
					if (typeof file === 'string') deleteVehicleFile(vehicleInspection?.calVehInspectionId ?? -1, 1, file);
				}
				if (file === file2Current) {
					setValue('file2', null);
					if (typeof file === 'string') deleteVehicleFile(vehicleInspection?.calVehInspectionId ?? -1, 2, file);
				}
				if (file === file3Current) {
					setValue('file3', null);
					if (typeof file === 'string') deleteVehicleFile(vehicleInspection?.calVehInspectionId ?? -1, 3, file);
				}
				if (file === file4Current) {
					setValue('file4', null);
					if (typeof file === 'string') deleteVehicleFile(vehicleInspection?.calVehInspectionId ?? -1, 4, file);
				}
				if (file === file5Current) {
					setValue('file5', null);
					if (typeof file === 'string') deleteVehicleFile(vehicleInspection?.calVehInspectionId ?? -1, 5, file);
				}
			});
            
            return [];
        });
    }, [getValues, setValue, deleteVehicleFile, vehicleInspection]);

	//generates the image based on a hiearchyid or file
	const getPreviewImage = (file: File | string | null ) => {
		if (!file) return null;
		if (file === null) return null;
		else return( 
			<PreviewImage
				key={typeof file === "string" ? file : file.name}
				file={file}
				table={FileTables.CalibrationFiles} 
				sx={{ 
					marginBottom: 1,
					width: 'calc(100% - 6px)',
					borderRadius: '2px',
					color: selected.includes(file) ? theme.palette.primary.main : '#555',
					border: '3px solid'
				}}
				//onClick={() => onSelected(file, "click")}
				onLongPress={() => onSelected(file, "press")}
				onContextMenu={(e) => { e.preventDefault(); }}
			/>
		);
	};

	//image preview renderer
	const AttachmentsPreview = useMemo(() => {

		//all images are placeed in a scrollable container
	    return (
			<Alignment column overflowScroll>
	        	<XMasonry style={{ overflow: 'scroll' }}>
	        	    {/* Render Files */}
					{getPreviewImage(file1)}
					{getPreviewImage(file2)}
					{getPreviewImage(file3)}
					{getPreviewImage(file4)}
					{getPreviewImage(file5)}
	        	</XMasonry>
			</Alignment>
	    );
	}, [file1, file2, file3, file4, file5, selected]);


    return (
        <Alignment overflowHidden column sx={{marginTop: '10px'}}>
            {/* If any files are selected then show the delete button. */}
            {selected.length > 0 ? (
                <Button
                    startIcon={<DeleteIcon/>}
                    color={"secondary"}
                    variant={"contained"}
                    sx={{ width: '100%', marginBottom: '10px' }}
                    onClick={deleteSelected}
                >{`Delete Selected (${selected.length})`}</Button>
            ) : (
                /* Camera & Upload Buttons */
                <ButtonGroup 
                    color={"primary"}
                    variant={"contained"}
                    sx={{ 
                        marginBottom: '10px', 
                        "& .MuiButtonGroup-grouped": {
                            borderColor: '#00000055!important',
                        },
                    }}
                >
                    {/* Camera */}
                    <Button
                        startIcon={<CameraAltIcon/>}
                        component={"label"}
                        sx={{ width: '100%' }}
                    >
                        {"Camera"}
                        <input
                            type={"file"}
                            hidden
                            onChange={onUpload}
                            accept={"image/*"}
                            capture={"environment"}
                        />
                    </Button>

                    {/* Upload */}
                    <Button
                        startIcon={<CloudUploadIcon/>}
                        component={"label"}
                        sx={{ width: '100%' }}
                    >
                        {"Upload"}
                        <input
                            type={"file"}
                            multiple
                            hidden
                            onChange={onUpload}
                        />
                    </Button>
                </ButtonGroup>
            )}

                {/* Render Attachments Preview */}
                {AttachmentsPreview}
        </Alignment>
    );
};


export default VehicleInspectionAttachment;