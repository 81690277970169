import { Accordion, AccordionDetails, AccordionSummary, Link, List, Typography, Button } from '@mui/material';
import { makeStyles } from '@imas/styles';
import React, { useState } from 'react';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Alignment, LabeledItem } from '@imas/components/layout';
import { SOTrackingResult, vServiceOrderSiteContact } from 'src/api/types/api/service-orders/types';
import { useApi } from '@imas/api';
import { GetServiceOrderTrackingResult } from 'src/api/service-order/api';
import { SignatureRequestDialog } from 'src/pages/ServiceOrders/ServiceOrderClientSignature/SignatureRequestDialog';
import { Draw } from 'mdi-material-ui';

const useSiteContactsStyles = makeStyles()((theme) => ({
    expanded: {
        "&$expanded": {
            margin: 0,
        },
    },
    email: {
        wordBreak: 'break-all',
    },
}));

export interface SiteContactsListProps {
    contacts: vServiceOrderSiteContact[];
	displaySignature: boolean;
};

/** Renders a list of site contacts */
export const SiteContactsList = ({ contacts, displaySignature }: SiteContactsListProps) => {
    const { classes } = useSiteContactsStyles();

	//api
	const loadServiceOrder = useApi(GetServiceOrderTrackingResult);

    //keep track of the currently open contact
    const [expanded, setExpanded] = useState<boolean | number>(false);
	const [serviceOrder, setServiceOrder] = useState<SOTrackingResult | null>(null);
	const [dialogName, setDialogName] = useState<string>('');
	const [dialogEmail, setDialogEmail] = useState<string>('');

    //function for opening a panel
    const openPanel = (panelId: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panelId : false);
    };

    //quick check if a value is null or an empty string, will return true if it is
    const isNullOrEmpty = (val: string | null) => {
		if (val === null) return true; 
		if (val === "") return true; 
		return false;
	};

	//load the service order and open the signature request dialog when signature clicked
	const signatureClicked = (contact: vServiceOrderSiteContact) => {
		setDialogName(contact.contact);
		setDialogEmail(contact.email ?? '');
		loadServiceOrder(contact.uniqueId).then((SO) => {
			setServiceOrder(SO);
		});
	};

    return (
        <List>
            {contacts.map((contact, index) => (
                // Site contact accordion to hide contact info to save space
                <Accordion key={index} expanded={expanded === contact.contactId} onChange={openPanel(contact.contactId)} classes={{ expanded: classes.expanded }}>
                    {/* Contact Name and Type */}
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        {/* Show the Contact name with their Type appended if it was provided at all */}
                        <Typography variant="subtitle1">{contact.contact+ (isNullOrEmpty(contact.type) ? "" : (" (" + contact.type + ")"))}</Typography>
                    </AccordionSummary>

                    {/* Expanded Contact Information including company name, email, and mobile/other phone number. */}
                    <AccordionDetails>
                        <Alignment column>
                            {/* Company Name */}
                            <LabeledItem label={"Company"} item={contact.company} spacer/> 

                            {/* Contact Email if provided */}
                            {isNullOrEmpty(contact.email) ? null : <LabeledItem label={"Email"} item={<Link className={classes.email} variant={"body1"} href={"mailto: " + contact.email}>{contact.email}</Link>} spacer/>}
                            
                            {/* Contact Mobile if provided */}
                            {isNullOrEmpty(contact.mobile) ? null : <LabeledItem label={"Mobile"} item={<Link variant={"body1"} href={"tel: " + contact.mobile}>{contact.mobile}</Link>} spacer/>}
                            
                            {/* Contact alt phone if provided */}
                            {isNullOrEmpty(contact.phone) ? null : <LabeledItem label={"Phone"} item={<Link variant={"body1"} href={"tel: " + contact.phone}>{contact.phone}</Link>} spacer/>}

							{/* If the device is sm and the itemTypeID === 1 then display the Client Signature button. */}
                        	<Button
                        	    startIcon={<Draw/>}
                        	    color={"primary"}
                        	    variant={"contained"}
								disabled={!displaySignature}
                        	    onClick={() => signatureClicked(contact)}
								sx={{maxWidth: '215px'}}
                        	>{"Request Signature"}</Button>
                        </Alignment>
                    </AccordionDetails>
                </Accordion>
            ))}
			{serviceOrder !== null ? 
				<SignatureRequestDialog
					open={!!serviceOrder}
					onClose={() => setServiceOrder(null)}
					uniqueId={serviceOrder.uniqueId}
					startDate={serviceOrder.startDate}
					endDate={serviceOrder.endDate}
					contactName={dialogName}
					contactEmail={dialogEmail}
					siteContacts={contacts}
				/>
			: null}
        </List>
    );
};

export default SiteContactsList;