import { GetTimeSheetEmployeeWorkHours, vTimeSheetDetail } from '@imas/api/time-sheet';
import { TypedGridRenderCellParams, TypedGridRenderEditCellParams } from '@imas/data-grid';
import { alpha, Box, Checkbox, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import { TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, TimeSheetWeekday, WeekHoursRecord } from './types';
import { useLoadApi } from '@imas/api';
import { getCertificationHourDayTotal, getDailyNotes, getWeekRecordTotalHours, HOUR_CELL_DECIMAL_OPTIONS, useColWidth, useDailyNotesCount, useHourCellColoring, useHourCellColoringStyles, weekdayList, weekdayMap } from './utils';
import { GetServiceOrder } from '@imas/api/service-order';
import { Cached as CachedIcon, Description as DescriptionIcon, Add as AddIcon } from '@mui/icons-material';
import { makeStyles } from '@imas/styles';
import { TimeSheetDetailsFormContext } from './TimeSheetDetailsFormContext';
import moment from 'moment';
import Enumerable from 'linq';
import { useTimeSheetCertificationHours } from '@imas/api/data-hooks';
import { GetCertifications } from '@imas/api/certifications';
import { NotesBadge, NotesTooltip } from './Components';
import { AsyncButton } from '@imas/components/mui';

export const ShiftDiffHeader = () => {
	return (
		<Box sx={{ position: 'absolute', bottom: 0, left: 5, width: '24px' }}>
			<Typography 
				variant={'body2'}
				sx={{ writingMode: 'vertical-rl', fontWeight: 'bold', marginBottom: '5px' }} 
			>{'Shift-Diff'}</Typography>
		</Box>
	);
};

export const ShiftDiffCell = ({ value }: TypedGridRenderCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'shiftDiff', 'row'>) => {
	return (
		<Checkbox
			checked={value}
			disabled
			sx={{ padding: '0' }}
		/>
	);
};

const getSOWeekdayRange = (startDate?: Date | DateString | null, endDate?: Date | DateString | null) => {
	const start = startDate ? moment(startDate) : null;
	const end = endDate ? moment(endDate) : null;
	if (start === null || end === null) return '';
	if (start.isSame(end, 'day')) return start.format('ddd, M/D');
	return `${start.format('ddd, M/D')}-${end.format('ddd, M/D')}`;
};

interface ServiceOrderDateRangeCellProps {
	uniqueId?: int | null;
	startDate?: Date | DateString | null;
	endDate?: Date | DateString | null;
};

export const ServiceOrderDateRange = ({ uniqueId, startDate, endDate }: ServiceOrderDateRangeCellProps) => {
	const soUniqueId = uniqueId ?? -1;
	const { data: serviceOrder } = useLoadApi(GetServiceOrder, [soUniqueId], [soUniqueId], { disabled: soUniqueId === -1 || !!startDate });

	if (soUniqueId !== -1 && !serviceOrder) return <Skeleton variant={'text'} sx={{ width: '-webkit-fill-available' }}/>;

	return <>{getSOWeekdayRange(startDate ?? serviceOrder?.startDate, endDate ?? serviceOrder?.endDate)}</>;
};

interface ServiceOrderDateRangeEditableProps extends TypedGridRenderEditCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'soWeekday', 'row'> {
	
};

export const ServiceOrderDateRangeEditable = ({ row, api }: ServiceOrderDateRangeEditableProps) => {
	const [_, setToken] = useState(0);

	const uniqueId = api.getRowWithUpdatedValues(row.id)?.uniqueId ?? null;

	api.subscribeEvent('cellEditStop', ({ field }) => { if (field === 'serviceOrderId') setToken(Date.now()); });

	return <ServiceOrderDateRange uniqueId={uniqueId}/>;
};


interface HourTotalCellProps extends TypedGridRenderCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'hourTotal', 'row'> {

};

export const HourTotalCell = ({ row, api }: HourTotalCellProps) => {
	const data = api.getRowWithUpdatedValues(row.id) ?? null;

	const shownHours = useMemo(() => {
		if (data === null) return getWeekRecordTotalHours(row);

		return (data.mon ?? 0) + (data.tue ?? 0) + (data.wed ?? 0) 
			+ (data.thu ?? 0) + (data.fri ?? 0) + (data.sat ?? 0) + (data.sun ?? 0);
	}, [data]);

	return <>{(shownHours ?? 0).toFixed(2)}</>;
};

const useHourColumnStyles = makeStyles<{ placement: 'header' | 'footer' | 'standalone'; squared: boolean; }>()((theme, { placement, squared }) => ({
	container: {
		borderWidth: placement === 'standalone' ? '1px 0 1px 1px' : placement === 'header' ? '1px 0 0 1px' : '0 0 1px 1px', 
		borderStyle: 'solid', 
		borderColor : theme.palette.grid.border,
		'&:first-of-type': squared ? {} : {
			borderTopLeftRadius: placement === 'header' || placement === 'standalone' ?  `${theme.shape.borderRadius}px` : undefined,
			borderBottomLeftRadius: placement === 'footer' || placement === 'standalone' ?  `${theme.shape.borderRadius}px` : undefined,
		},
		'&:last-of-type': {
			...(squared ? {} : {
				borderTopRightRadius: placement === 'header' || placement === 'standalone' ?  `${theme.shape.borderRadius}px` : undefined,
				borderBottomRightRadius: placement === 'footer' || placement === 'standalone' ?  `${theme.shape.borderRadius}px` : undefined,
			}),
			borderRightWidth: '1px',
		},
	},
	borderBottom: {
		borderBottom: `1px solid ${theme.palette.grid.border}`
	},
	noBorderLeft: {
		borderLeft: 'none',
	},
}));

interface HourColumnProps {
	day: TimeSheetWeekday;
	hours?: WeekHoursRecord | null;
	compareTo?: WeekHoursRecord;
	showWeekday?: boolean;
	squared?: boolean;
	placement: 'header' | 'footer' | 'standalone';
};

const calcDayTotal = (details: vTimeSheetDetail[], day: TimeSheetWeekday): int | null => {
	const hours = Enumerable.from(details).select(x => x[day]).where(x => x !== null);
	if (hours.count() === 0) return null;

	return hours.sum();
};

export const HourColumn = ({ day, hours, compareTo, showWeekday, squared, placement }: HourColumnProps) => {
	const { classes, cx } = useHourColumnStyles({ placement, squared: !!squared });
	const { classes: colorClasses } = useHourCellColoringStyles();
	const { timeSheet, api } = useContext(TimeSheetDetailsFormContext);

	const width = useColWidth(api, day) - 1;

	const hourVal =  hours ? hours[day] ?? null : null;
	const compareHourVal =  compareTo ? compareTo[day] ?? null : null;
	const weekOfDate = useMemo(() => timeSheet ? moment(timeSheet.weekOf).startOf('day') : null, [timeSheet]);
	const date = useMemo(() => {
		if (weekOfDate === null) return '-/--';

        // if (weekOfDate.isBefore(TIME_SHEET_CHANGEOVER_CUTOFF_DATE, 'day')) return weekOfDate.clone().add(weekdayMap[day] + 1, 'days').format('M/D');
		
		return weekOfDate.clone().add(weekdayMap[day], 'days').format('M/D');
	}, [weekOfDate, day]);

	// apply comparison styles
	const hourValClasses = useMemo(() => {
		if (!compareTo || (compareHourVal === null && !hourVal)) return {};
		
		return {
			[colorClasses.pastelGreen]: hourVal !== null && hourVal === compareHourVal,
			[colorClasses.pastelYellow]: hourVal !== null && hourVal < (compareHourVal ?? 0),
			[colorClasses.pastelRed]: hourVal !== null && hourVal > (compareHourVal ?? 0),
		};
	}, [hourVal, compareHourVal, compareTo, colorClasses]);

	const display = hours === undefined ? (
		<Typography 
			variant={'body2'}
			sx={{ fontWeight: 'bold', textAlign: 'center' }} 
		>{date}</Typography>
	) : (
		<Typography 
			variant={'body2'}
			sx={{ textAlign: 'center' }}
			className={cx({ [classes.borderBottom]: !!showWeekday }, hourValClasses)}
		>{hourVal === null ? '--' : hourVal.toFixed(2)}</Typography>
	);

	return (
		<Box className={classes.container} style={{ width }}>
			{placement === 'footer' ? display : null}
			
			{showWeekday ? (
				<Typography 
					variant={'body2'}
					sx={{ fontWeight: 'bold', textAlign: 'center', textTransform: 'capitalize' }} 
				>{day ?? ''}</Typography>
			) : null}

			{placement !== 'footer' ? display : null}
		</Box>
	);
};

interface HourTotalColumnProps {
	hours: number | string | null;
	placement: 'footer' | 'standalone';
	width?: string;
};

const HourTotalColumn = ({ hours, placement, width }: HourTotalColumnProps) => {
	const { classes, cx } = useHourColumnStyles({ placement, squared: false });
	const { api } = useContext(TimeSheetDetailsFormContext);
	const widthComputed = useColWidth(api, 'hourTotal') - 1;

	return (
		<Box className={cx(classes.container, { [classes.noBorderLeft]: placement === 'footer' })} sx={{ height: '20px' }} style={{ width: width ? width : widthComputed }}>
			<Typography 
				variant={'body2'}
				sx={{ textAlign: 'center' }}
			>{hours === null ? '--' : typeof hours === 'number' ? hours.toFixed(2) : hours}</Typography>
		</Box>
	);
};

interface CertHourTotal {
	hours: number;
    id: number;
    name: string;
}

interface CertHourTotalProps {
	total: CertHourTotal | null;
};

const CertHourTotal = ({ total }: CertHourTotalProps) => {
	const { classes, cx } = useHourColumnStyles({ placement: 'standalone', squared: false });

	return (
		<Box className={cx(classes.container)} sx={{ width: '50px' }}>
			<Typography 
				variant={'body2'}
				sx={{ fontWeight: 'bold', textAlign: 'center' }}
				className={classes.borderBottom}
			>{total?.name ?? '--'}</Typography>

			<Typography 
				variant={'body2'}
				sx={{ textAlign: 'center' }}
			>{total ? total.hours.toFixed(2) : ''}</Typography>
		</Box>
	);
};


// this overlays Mon-Sun column header & footer, is a grid custom header to keep it aligned with the grid columns  
export const TimeSheetHourHeadFooterOverlay = () => {
	const { timeSheetId, details, isEditable, soEmployeeHours, gridHeight, soUniqueId, addRow, refreshRows } = useContext(TimeSheetDetailsFormContext);
	
	// employee check-in hours 
	const { data: employeeWorkHours, call: refreshEmployeeWorkHours } = useLoadApi(GetTimeSheetEmployeeWorkHours, [timeSheetId], [timeSheetId]);

	// cert hour totals
	const certHourTotals = useCertHourTotals(timeSheetId);

	const soEmployeeHour = useMemo(() => (soEmployeeHours ?? []).find(x => x.uniqueId === soUniqueId), [soEmployeeHours, soUniqueId]);
	const soEmployeeHourTotal = useMemo(() => soEmployeeHour ? getWeekRecordTotalHours(soEmployeeHour) : null, [soEmployeeHour]);

	//calculate week per day totals
	const perDayTotals = useMemo(() => {
		const totals = weekdayList.map(x => ({ [x]: null })).reduce((a, b) => ({ ...a, ...b }), {}) as WeekHoursRecord;
		if (!details) return totals;

		// calculate totals by dat
		for (const day of weekdayList) totals[day] = calcDayTotal(details, day);
		return totals;
	}, [details]);

	// calculate week total hours 
	const total = useMemo(() => details ? Enumerable.from(details).select(getWeekRecordTotalHours).sum() : null, [details]);
	const totalCheckIn = useMemo(() => employeeWorkHours ? getWeekRecordTotalHours(employeeWorkHours) : null, [employeeWorkHours]);
	
	return (
		<Box sx={{ position: 'absolute', left: '-11px' }}>
			{/* Service Order Employee Hours */}
			{soEmployeeHour ? (
				<Box sx={{ display: 'flex', flexDirection: 'row', position: 'absolute', height: '22px', top: '-49px' }}>
					<Typography variant={'body2'} fontWeight={'bold'} sx={{ position: 'absolute', left: '-102px' }}>{`S.O. Hrs (${soEmployeeHour.serviceOrderId}):`}</Typography>

					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						{weekdayList.map(day => <HourColumn key={day} placement='header' hours={soEmployeeHour} day={day}/>)}
						
						{/* Total of SO Work Hours */}
						<HourTotalColumn hours={soEmployeeHourTotal} placement='standalone'/>
					</Box>
				</Box>
			) : null}

			{/* Mon-Sun Header */}
			<Box sx={{ display: 'flex', flexDirection: 'row', position: 'absolute', height: '42px', top: '-28px' }}>
				{weekdayList.map(day => <HourColumn key={day} placement='header' showWeekday squared={!!soEmployeeHour} day={day}/>)}
			</Box>

			{/* Footer */}
			<Box sx={{ display: 'flex', flexDirection: 'row', position: 'absolute', height: '42px', bottom: `-${gridHeight + 31}px` }}>	
				<Typography variant={'body2'} fontWeight={'bold'} sx={{ position: 'absolute', left: '-42px' }}>{'Total:'}</Typography>

				<Box sx={{ display: 'flex', flexDirection: 'row' }}>
					{weekdayList.map(day => <HourColumn key={day} placement='footer' hours={perDayTotals} compareTo={employeeWorkHours} showWeekday day={day}/>)}
				</Box>

				{/* Time Sheet Total Hours */}
				<HourTotalColumn hours={total} placement='footer'/>
			</Box>

			{/* Check-In Hours */}
			<Box sx={{ display: 'flex', flexDirection: 'row', position: 'absolute', height: '22px', bottom: `-${gridHeight + 32 + 32}px` }}>	
				<Typography variant={'body2'} fontWeight={'bold'} sx={{ position: 'absolute', left: '-182px' }}>{'Check In/Check Out Total:'}</Typography>

				<Box sx={{ display: 'flex', flexDirection: 'row' }}>
					{weekdayList.map(day => <HourColumn key={day} placement='standalone' hours={employeeWorkHours ?? null} day={day}/>)}
					
					{/* Total of Check-In Hours */}
					<HourTotalColumn hours={totalCheckIn} placement='standalone'/>
				</Box>

				<Typography variant={'body2'} fontWeight={'bold'} sx={{ margin: '0 5px 0 10px' }}>{'Last 30 Days:'}</Typography>
				<Box><HourTotalColumn hours={employeeWorkHours ? `${((employeeWorkHours.checkInRate ?? 0) * 100).toFixed(1)}%` : '--.-%'} placement='standalone' width='54px'/></Box>

				<IconButton
					disabled={!employeeWorkHours}
					onClick={() => refreshEmployeeWorkHours(true)}
					sx={{ padding: 0, marginLeft: '4px' }}
				><CachedIcon/></IconButton>
			</Box>

			{/* Cert Hour Totals */}
			<Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', bottom: `-${gridHeight + 32 + 32}px`, left: '-600px' }}>
				<Typography variant={'body2'} fontWeight={'bold'}>{'Method Hours'}</Typography>

				<Box sx={{ display: 'flex', flexDirection: 'row', height: '42px' }}>
					{[...new Array(8)].map((_, i) => certHourTotals[i]).map((certTotal, i) => (
						<CertHourTotal key={certTotal?.id ?? i} total={certTotal ?? null}/>
					))}
				</Box>
			</Box>

			{/* Add Row Button */}
			<Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', bottom: `-${gridHeight + 32}px`, left: '-775px' }}>
				<AsyncButton startIcon={<AddIcon/>} onClick={addRow} disabled={!isEditable} sx={{ marginTop: '10px '}}>New Row</AsyncButton>
			</Box>

			{/* Refresh Button */}
			<Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', bottom: `-${gridHeight + 32}px`, left: '425px' }}>
				<AsyncButton startIcon={<CachedIcon/>} onClick={refreshRows} disabled={!isEditable} sx={{ marginTop: '10px '}}>Refresh</AsyncButton>
			</Box>
		</Box>
	);
};

const useCertHourTotals = (timeSheetId: int) => {
	//get certifications
	const { data: certifications } = useLoadApi(GetCertifications, [], [], {
		mutate: (result) => {
			return Enumerable.from(result)
				.where(x => x.categoryId !== null)
				.orderBy(x => x.abbreviation)
				.toArray();
		},
	});

	//get TimeSheetCertificationHours for entire time sheet (used for footer totals)
	const [certificationHours] = useTimeSheetCertificationHours(timeSheetId);

	//calculate hour totals by certification
	return useMemo(() => {
		if (!certificationHours || !certifications) return [...new Array(25)].map(() => null);

		return Enumerable.from(certificationHours)
			.join(
				Enumerable.from(certifications), 
				(certHour) => certHour.certificationId, 
				(certification) => certification.id, 
				(certHour, certification) => ({ 
					id: certification.id, 
					name: certification.abbreviation, 
					hours: Enumerable.from(weekdayList).select(day => certHour[day] ?? 0).sum(),
				}),
			)
			.groupBy(cert => cert.id)
			.select(certs => ({
				...certs.first(),
				hours: certs.sum(x => x.hours),
			}))
			.orderByDescending(x => x.hours)
			.thenBy(x => x.name)
			.toArray();
	}, [certificationHours, certifications]);
};

export const useHourCellStyles = makeStyles()((theme) => ({
	invalidHours: {
		backgroundColor: alpha(theme.palette.error.main, 0.6),
	},
	hourContainer: {
		height: '100%',
		display: 'flex',
		flex: '1',
		margin: '0 -10px 0 -10px',
		justifyContent: 'center',
		alignItems: 'center',
		borderRight: `1px solid ${theme.palette.grid.border}`,
	},
	hourEdit: {
		height: '100%',
		borderRight: `1px solid ${theme.palette.grid.border}`,
	},
}));

interface HourCellProps extends TypedGridRenderCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun', 'row'> {};

export const HourCell = ({ field, row, api }: HourCellProps) => {
	const { classes, cx } = useHourCellStyles();

	const hours = row[field];

	// cell coloring
	const styles = useHourCellColoring(field, row, 'view');

	//calculate certHour total
	const certHourTotal = useMemo(() => getCertificationHourDayTotal(row.certificationHours, field), [row.certificationHours]);

	return (
		<Box
			className={cx(classes.hourContainer, { [classes.invalidHours]: (certHourTotal > (hours ?? 0))}, styles)}
		>
			{typeof hours === 'number' ? hours.toFixed(HOUR_CELL_DECIMAL_OPTIONS.decimalScale) : ''}
		</Box>
	);
};

interface NotesCellProps extends TypedGridRenderCellParams<TimeSheetDetailDM, TimeSheetDetailRM, TIME_SHEET_DETAILS_EDIT_GRID_EDITABLE, 'notes', 'row'> {};

export const NotesCell = (props: NotesCellProps) => {
	const { row, api } = props;

	const { isEditable } = useContext(TimeSheetDetailsFormContext);

	const dailyNotes = useMemo(() => getDailyNotes(row), [row]);
	const dailyNotesCount = useDailyNotesCount(dailyNotes);

	const startEditNodes = useCallback(() => {
		api.startRowEditMode({ id: row.id });
		api.setEditCellValue({ id: row.id, field: 'dailyNotes', value: { ...dailyNotes, open: true } });
	}, [row.id, dailyNotes, api]);

	const NotesInfo = useCallback(() => (
		<Tooltip title={<NotesTooltip notes={dailyNotes}/>} arrow>
			<NotesBadge badgeContent={dailyNotesCount} color="secondary">
				<DescriptionIcon sx={{ height: '18px', width: '18px' }}/>
			</NotesBadge>
		</Tooltip>
	), [dailyNotes, dailyNotesCount]);

	return (
		<>
			{row.notes ?? ''}
			<Box sx={{ marginLeft: 'auto' }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', margin: '0 -4px -1px 4px', alignSelf: 'center', position: 'relative' }}>
					{isEditable ? (
						<IconButton size='small' onClick={startEditNodes}>
							{dailyNotesCount === 0 ? (
								<>
									<DescriptionIcon sx={{ height: '18px', width: '18px' }}/>
									<AddIcon sx={{ position: 'absolute', height: '16px', width: '16px', right:'-2px', top: '0px', padding: '0 2px' }}/>
								</>
							) : (
								<NotesInfo/>
							)}
						</IconButton>
					) : (
						<NotesInfo/>
					)}
				</Box>
			</Box>
		</>
	);
};
