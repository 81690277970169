import { JsonFile } from 'src/api/types/api/JsonFile';
import { FileInfoResult, FileTables } from '@imas/api/files/types';
import { saveAs } from 'file-saver';
import { fileNameDisallowedCharactersMatch } from '@imas/constants';
import { DownloadFileURL } from '@imas/api/files';
import { openPopup } from '../misc';

//exports
export { base64toBlob } from './base64toBlob';
export { fileListToArray } from './fileListToArray';
export { fileToJson, filesToJson } from './filesToJson';
export { getFileTypeIconURL } from './getFileTypeIconURL';
export { openFile } from './openFile';
export { openFileExplorer } from './openFileExplorer';
export { resizeImage } from "./resizeImage";
export { fileToDataURL, useFileAsDataURL } from "./useFileAsDataURL";
export { useFileAsObjectURL } from "./useFileAsObjectURL";
export { useFilePreviewURL } from "./useFilePreviewURL";

export { FileDownload } from './FileDownload/FileDownload';

export * from './FileExplorer';

export { FileExplorerLink } from './FileExplorerLink/FileExplorerLink';
export type { FileExplorerLinkProps } from './FileExplorerLink/FileExplorerLink';

export * from './FileUploader/FileUploader';

export { FileDownloader } from './FileDownloader/FileDownloader';
export type { FileDownloaderRef } from './FileDownloader/FileDownloader'; 

export { FileImporter, FileImporterInput } from './FileImporter';
export type { FileImporterRef } from './FileImporter';

export { FileLink } from './FileLink/FileLink';

export { FileSystemLink } from './FileSystemLink/FileSystemLink';
export type { FileSystemLinkProps } from './FileSystemLink/FileSystemLink';

export { FolderFileDownloader } from './FolderFileDownloader/FolderFileDownloader';
export { FolderViewer } from './FolderViewer/FolderViewer';

export * from './FileViewer/FileViewer';


//get file name and extension from a full file string
export const getFileInfo = (fullFileName: string) => {
    //result var
    const result = {
        filename: "",
        extension: ""
    };

    //get the position of the last . in the full name
    const lastDot = fullFileName.lastIndexOf('.');

    result.filename = fullFileName.substring(0, lastDot);
    result.extension = fullFileName.substring(lastDot + 1);

    return result;
};

//clean a filename of all invalid characters
export const cleanFileName = (fileName: string) => {
    return fileName.replace(fileNameDisallowedCharactersMatch, '');
};

//get the download URL for a FileTable file given it's path locator and the fileTable it is located in. 
export const getFileDownloadUrl = (pathLocator: HierarchyId, fileTable: FileTables) => {
    const downloadUrl = new URL(window.location.protocol + "//" + window.location.host + "/api/file");
    downloadUrl.searchParams.append("table", fileTable);
    downloadUrl.searchParams.append("file", pathLocator);

    return downloadUrl.href;
};

/** Download a File/Files given a File object(s), or Path Locators(s) + FileTable . */
export function download(pathLocator: HierarchyId, table: FileTables): void;
export function download(pathLocators: HierarchyId[], table: FileTables): void;
export function download(file: File | Promise<File>): void;
export function download(file: File[] | Promise<File>[]): void;
export function download(source: HierarchyId | HierarchyId[] | File | File[] | Promise<File> | Promise<File>[], table?: FileTables): void {
	const sourceAsArray = Array.isArray(source) ? source : [source];

	for (const item of sourceAsArray) {
			
		if (item instanceof File) saveAs(item, item.name);
		else if (item instanceof Promise) item.then(file => saveAs(file, file.name));
		else if (typeof item === "string" && table) saveAs(DownloadFileURL(table, item));
	}
};

/** Download a FileTable file. */
export const downloadFile = (pathLocator: HierarchyId, fileTable: FileTables) => {
    //get the file download url
    const url = getFileDownloadUrl(pathLocator, fileTable);

    //download file using 'file-saver'.
    saveAs(url);
};


/** Download multiple FileTable files. */
export const downloadFiles = (pathLocators: HierarchyId[], fileTable: FileTables) => {
    for (const pathLocator of pathLocators) {
        downloadFile(pathLocator, fileTable);
    }
};

/**
 * Programmatically download a JsonFile.
 */
export const downloadJsonFile = (file: JsonFile) => {

    console.log(file);
    //create <a/> element 
    const link = document.createElement("a");

    //have the link point to a data stream which is the file and then also provide the file name.
    link.href = "data:application/octet-stream;base64," + file.content;
    link.target = "_blank";
    link.download = file.name;

    //click the <a/> element to start the file download and then remove it from the DOM
    link.click();
    link.remove();
};

//open a file in local explorer given it's fileInfo
export const openLocalExplorer = (fileInfo: FileInfoResult) => {
    //open it
    window.open("localexplorer:" + fileInfo.fullPath, "_blank");
};

//get a file size using file size values (ex. MB, KB etc....) with the given precision
export const formatFileSize = (bytes: number, decimalPoint?: number): string => {
    //if bytes 0 return a str which says 0 Bytes
    if (Math.floor(bytes) === 0) return '0 Bytes';

    let k = 1024,
        dm = decimalPoint ?? 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
