import { useCallback, useEffect } from 'react';
import { useApi, useLoadApi } from '@imas/api';
import { 
	GetClientLocation, CreateClientLocation, UpdateClientLocation, DeleteClientLocation,
	ClientLocation, ClientLocationForm as TClientLocationForm 
} from '@imas/api/client';
import { Alignment } from '@imas/components/layout';
import { MuiFormSubmit, MuiFormSubmitProps, useMuiFormRef } from '@imas/mui-form';
import { isPopup } from '@imas/utils/misc';
import { ResourceCollaboratorGroup, RESOURCES } from '@imas/utils/resource';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { ExitToApp, Save as SaveIcon, Delete as DeleteIcon, FindInPage as FindInPageIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useNavigate, Navigate } from 'react-router-dom';
import { sendEditorUpdate, useEditorState } from '@imas/utils/editor';
import { ClientLocationForm } from './ClientLocationForm/ClientLocationForm';
import { useConfirmationDialog } from '@imas/utils/dialog';
import { openEditorWindow } from '@imas/utils/editor';
import { getUserRoles } from '@imas/redux';
import { useSelector } from 'react-redux';
import { openClientLocationReportViewer } from './ClientLocationReportViewer';

export type ClientLocationEditorStateParams = { clientId?: string; };

export const openClientLocationEditor = ({ locationId, clientId }: { locationId?: int | null; clientId?: int | null; }): Window | null => {
	return openEditorWindow<ClientLocationEditorStateParams>(
		IMAS.PAGES.CLIENTS.LOCATIONS(), 
		{ id: locationId ?? null, clientId: clientId ? `${clientId}` : undefined, }, 
		{ height: 900, width: 1500, name: locationId ? `Location #${locationId} Edit Form` : clientId ? `New Location for Client #${clientId} Form` : "New Location Form" }
	);
};

export const ClientLocationEditor = () => {
	//page title
    document.title = "IMAS - Client Location Editor";

	//editor state
	const [state, setState] = useEditorState<ClientLocationEditorStateParams>({ url: IMAS.PAGES.CLIENTS.LOCATIONS() });

    //check if the user is an admin
    const { admin: isAdmin } = useSelector(getUserRoles);

	//use automatic snackbar
    const showSnackbar = useAutomaticSnackbar();

	//use navigate
	const navigate = useNavigate();

	//use confirmation dialog
	const [confirm] = useConfirmationDialog();

    //use APIs
    const createClientLocation = useApi(CreateClientLocation);
    const updateClientLocation = useApi(UpdateClientLocation);
    const deleteClientLocation = useApi(DeleteClientLocation);

    //useFormRef for location form
    const [form, onForm, formRef] = useMuiFormRef<TClientLocationForm, ClientLocation>();

    //load the specified location if state is a number
    const { data: cLocation, error: locationError, clear: clearLocation } = useLoadApi(GetClientLocation, [state.id ?? -1], [state.id], { disabled: state.id === null });

    //reset form to blank for a new item
    useEffect(() => {
        //clear any loaded location record
        clearLocation();

        if (state.id === null) {
            formRef.current?.reset({
                clientId: state.clientId ? isNaN(Number(state.clientId)) ? null : Number(state.clientId) : null,
				name: null,
				billingAddress: null,
				billingAddress1: null,
				billingAddress2: null,
				billingCity: null,
				billingState: null,
				billingZip: null,
				billingCountry: null,
				businessAddress: null,
				businessAddress1: null,
				businessAddress2: null,
				businessCity: null,
				businessState: null,
				businessZip: null,
				businessCountry: null,
				billingTo: null,
				billingAttn: null,
				billingEmail: null,
				reportMethod: 1,
				billingMethod: 1,
				billingReport: false,
				notes: null,
				active: true,
            });
        }
    }, [state, clearLocation, formRef]);

    //reset the form to editing the loaded location
    useEffect(() => {
        if (cLocation === undefined) return;
        else {
            formRef.current?.reset({
                ...cLocation,
            });
        }
    }, [cLocation, formRef]);

    //create a new location record
    const createLocation = useCallback(async (data: TClientLocationForm) => {
        //progress notification
        const close = showSnackbar("Creating location...", { variant: 'info', persist: true });
        
        try {
            //call API
            const record = await createClientLocation(data);

            //close notification
            close();

            //show success notification
            //showSnackbar("Created location.", { variant: 'success' }, true);

			//send editor update
			sendEditorUpdate("/clients/locations", "CREATE", record);

            //return the new record
            return record;
        } catch (e) {
            //close notification & re-throw error
            throw close(e);
        }
    }, [createClientLocation, showSnackbar]);

    //update a location record
    const updateLocation = useCallback(async (locationId: int, data: TClientLocationForm) => {
        //progress notification
        const close = showSnackbar("Updating location...", { variant: 'info', persist: true });
        
        try {
            //call API
            const record = await updateClientLocation(locationId, data);
            
            //close notification
            close();

            //show success notification
            //showSnackbar("Updated location.", { variant: 'success' }, true);
			
			//send editor update
			sendEditorUpdate("/clients/locations", "UPDATE", record);

            //return the updated record
            return record;
        } catch(e) {
            //close notification & re-throw error
            throw close(e);
        }
    }, [updateClientLocation, showSnackbar]);

	//delete a location record
	const deleteLocation = useCallback(async (locationId: int) => {
        //progress notification
        const close = showSnackbar("Deleting location...", { variant: 'info', persist: true });
        
        try {
            //call API
            const record = await deleteClientLocation(locationId);
            
            //close notification
            close();

            //show success notification
            //showSnackbar("Deleted location.", { variant: 'success' }, true);

			//send editor update
			sendEditorUpdate("/clients/locations", "DELETE", record);

            //return the deleted record
            return record;
        } catch(e) {
            //close notification & re-throw error
            throw close(e);
        }
    }, [deleteClientLocation, showSnackbar]);

	//submit handler
	const onSubmit = useCallback<MuiFormSubmitProps<TClientLocationForm, ClientLocation>["onSubmit"]>(async (data) => {
		//check if a location record is loaded, if not create a new record
		if (!cLocation) {
			return await createLocation(data);
		} 
		//otherwise update the loaded record
		else {
			return await updateLocation(cLocation.id, data);
		};
	}, [cLocation, createLocation, updateLocation]);

    //close editor if location could not be loaded
    if (locationError !== undefined) {
		if (isPopup()) window.close();
		return <Navigate to={"/"} replace/>;
	}

    return (
        <Alignment column flex sx={{ margin: '10px' }}>
			<Alignment column flex>
				<ClientLocationForm
					key={state.id ?? "new"}
					clientId={state.clientId ? isNaN(Number(state.clientId)) ? null : Number(state.clientId) : null}
					cLocation={state.id === null ? null : cLocation}
					loading={state.id !== null && cLocation=== undefined}
					ref={onForm}
				/>
			</Alignment>
            
            <Alignment rowReverse sx={{ marginTop: '10px', "& > *:not(:last-child)": { marginLeft: '10px' } }}>
                {/* Save & Exit Button (Only show in a popup.) */}
                {isPopup() ? (
					<MuiFormSubmit
						formRef={form}
						onSubmit={onSubmit}
						onSuccessfulSubmit={() => {
							//call window.close() to close the popup window
							window.close();
						}}
						startIcon={<ExitToApp/>}
					>{"Save & Exit"}</MuiFormSubmit>
				) : null}

				{/* Save Button */}
				<MuiFormSubmit
                    formRef={form}
                    onSubmit={onSubmit}
                    onSuccessfulSubmit={(newLocation) => {
						//if a new server was just created then change to edit the new record
						if (!cLocation) setState({ id: newLocation.id }, { replace: true });
					}}
                    startIcon={<SaveIcon/>}
                >{"Save"}</MuiFormSubmit>

				{/* Resource Collaborators for this Location Form */}
				{cLocation ? <ResourceCollaboratorGroup resource={RESOURCES.clientLocation(cLocation.id)} noPadding/> : null}

				{/* View report pdf */}
				<Button
					variant={"contained"}
					color={"primary"}
					disabled={cLocation === undefined}
					onClick={() => openClientLocationReportViewer(cLocation?.id ?? -1)}
					startIcon={<FindInPageIcon/>}
					sx={{marginRight: '140px'}}
				>{'Preview'}</Button>

				{/* Delete Button */}
				<Button
					variant={"contained"}
					color={"secondary"}
					disabled={cLocation === undefined}
					onClick={async () => {
						//ensure a location is loaded
						if (!cLocation) return;
						if (!isAdmin) return showSnackbar("You must be an admin to delete a location.", { variant: 'error' });

						//confirm that the user wants to delete this client
						const deleted = await confirm({
							prompt: `Are you sure you would like to delete the following location: '${cLocation.name}'?`,
							confirmText: "Delete",
							confirmIcon: <DeleteIcon/>,
							onConfirm: async () => {
								//call API
								await deleteLocation(cLocation.id);
							}
						});

						//if location was successfully deleted
						if (deleted) {
							//if this window is a popup close the window
							if (isPopup()) window.close();

							//otherwise navigate to the clients & contacts page
							else navigate(IMAS.PAGES.CLIENTS_AND_CONTACTS.SEARCH(), { replace: true });
						}
					}}
					startIcon={<DeleteIcon/>}
					sx={{ marginRight: 'auto' }}
				>{"Delete"}</Button>
			</Alignment>
		</Alignment>
	);
};