import { useLoadApi } from '@imas/api';
import { GetFileFromReportingServer } from '@imas/api/files';
import { getReportingAuth } from '@imas/redux';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FileViewerLoader } from '../files';
import { PDFViewer, PDFViewerProps } from './PDFViewer';

//get reporting server URL for the current domain
const getReportingURL = () => {
	switch (window.location.hostname) {
		case "pms.pnltest.net":
		case "imas.pnltest.net":
			return "https://reporting.pnltest.net";
		case "imasdev.pnltest.net":
		case "localhost":
		default:
			return "https://reportingdev.pnltest.net";
	}
};

//available templates
const SSRSTemplates = {
	"SERVICE_ORDER_WORK_PLACE_REVIEW": (workPlaceReviewId: int) => `/PNLReportServer/Pages/ReportViewer.aspx?/SOReports/ServiceOrderWorkPlaceReviewReport&ParamWorkPlaceReviewID=${workPlaceReviewId}`,
	"CLIENT_INFO": (clientId: int) => `/PNLReportServer/Pages/ReportViewer.aspx?/ClientReports/ClientInfo&ParamClientID=${clientId}`,
	"CLIENT_LOCATION_INFO": (locationId: int) => `/PNLReportServer/Pages/ReportViewer.aspx?/ClientReports/ClientLocationInfo&ParamLocationID=${locationId}`,
	"CLIENT_LOCATION_CONTACT_INFO": (contactId: int) => `/PNLReportServer/Pages/ReportViewer.aspx?/ClientReports/ContactInfo&ParamContactID=${contactId}`,
	"CLIENT_PURCHASE_ORDER": (poNumId: int) => `/PNLReportServer/Pages/ReportViewer.aspx?/ClientReports/ClientPurchaseOrder&ParamClientPoNumID=${poNumId}`,
	"CLIENT_PURCHASE_ORDER_REVISION": (poNumRevId: int) => `/PNLReportServer/Pages/ReportViewer.aspx?/ClientReports/ClientPurchaseOrderRevision&ParamClientPoNumRevID=${poNumRevId}`,
	"PROJECT_TARGET_SUMMARY_REPORT": (projectId: int) => `/PNLReportServer/Pages/ReportViewer.aspx?/JobReports/ProjectTargetSummaryReport&ParamProjectID=${projectId}`,
};

type TSSRSTemplates = typeof SSRSTemplates;

export interface SSRSPDFViewerProps<TEMPLATE extends keyof TSSRSTemplates> extends Omit<PDFViewerProps, "source" | "fileName"> {
	template: TEMPLATE;
	parameters: Parameters<TSSRSTemplates[TEMPLATE]>;
	reportName?: string;
};

export const SSRSPDFViewer = <TEMPLATE extends keyof TSSRSTemplates>({ template, parameters, reportName, ...pdfViewerProps }: SSRSPDFViewerProps<TEMPLATE>) => {
	//get reporting auth
	const reportingAuth = useSelector(getReportingAuth);
	
	//create headers
	const headers = useMemo(() => {
		if (!reportingAuth) return;

		return [
			{
				key: "Authorization",
				value: reportingAuth,
			},
			...(pdfViewerProps?.headers ?? [])
		];
	}, [reportingAuth, pdfViewerProps?.headers]);

	//get report url for selected template fill in with provided params
	const url = useMemo(() => {
		const getUrl = SSRSTemplates[template] as ((...args: any[]) => string);
		//return `${getReportingURL()}${getUrl(...parameters)}&rs:Format=PDF`;
		return `${getUrl(...parameters)}&rs:Format=PDF`;
	}, [template, parameters]);

	//get file name with .pdf
	const fileName = useMemo(() => {
		const name = reportName ?? "Report";
		return name.toLowerCase().endsWith(".pdf") ? name : `${name}.pdf`;
	}, []);

	//const { data: testFile } = useLoadApi(GetFileFromReportingServer, ["/PNLReportServer/Pages/ReportViewer.aspx?/SOReports/ServiceOrderWorkPlaceReviewReport&ParamWorkPlaceReviewID=2710&rs:Format=PDF", 'test.pdf'], []);
	const { data: testFile } = useLoadApi(GetFileFromReportingServer, [url, fileName], []);


	//returns a promise which resolves to the file
	const filePromise = useMemo(() => {
		if (!testFile) return;
		//make request for the file and return a promise which resolves to the returned file
		return new Promise<File>(async (resolve) => {
			const base64Response = await fetch(`data:application/pdf;base64,${testFile.content}`);
			const blob = await base64Response.blob();
			let file = new File([blob], testFile.name);
			resolve(file);
		});
	}, [url, testFile]);

	return (
		<>
			{filePromise ? 
				<PDFViewer
					source={filePromise}
					fileName={fileName}
					{...pdfViewerProps}
					headers={headers}
				/>
			: <FileViewerLoader/>}
		</>

	);
};