import { useState, useCallback, useMemo } from 'react';
import { Dialog, Typography, Paper, DialogContent, Link as MuiLink, Button, Divider, DialogActions, Accordion, AccordionSummary, AccordionDetails, Skeleton, IconButton, Tooltip } from '@mui/material';
import { dailyScheduleDialogStyles } from './DailyScheduleDialogStyles';
import { Alignment, Spacer, LabeledItem } from '@imas/components/layout';
import { formatTimeStr } from '@imas/utils/formatting';
import { GetDailyScheduledEventDetails } from 'src/api/schedule';
import { Description as DescriptionIcon, AttachFile as AttachFileIcon, AccessTime as AccessTimeIcon, ExpandMore as ExpandMoreIcon, Lightbulb as LightBulbIcon, Edit as EditIcon  } from '@mui/icons-material';
import { SafetyGoggles as SafetyGogglesIcon } from 'mdi-material-ui';
import { Link, useNavigate } from 'react-router-dom';
import { SelectedEvent } from '../DailyScheduleViewer';
import { XDialogTitle } from '@imas/components/mui';
import { SlideUp } from '@imas/components/transitions';
import { Qrcode as QRCodeIcon } from 'mdi-material-ui';
import { useApi, useLoadApi } from '@imas/api';
import { openEditorWindow } from '@imas/utils/editor';
import { SiteContactsList } from './SiteContactsList/SiteContactsList';
import { AssignmentList } from './AssignmentList/AssignmentList';
import QRCode from "react-qr-code";
import moment from 'moment';
import axios from 'axios';
import { GetServiceOrderProcedures } from 'src/api/service-order/api';
import { openPopup } from '@imas/utils/misc';
import { download } from '@imas/utils/files';
import { DownloadFile, FileTables } from '@imas/api/files';
import { GetSOAdditionalScopes } from '@imas/api/proposal';
import { PdfDialog } from '@imas/utils/dialog';

//get JobNo from FullJobName
export const getJobNo = (val: string) => val.split(" ")[0]; 

//get JobName from FullJobName
export const getJobName = (val: string) => { const a = val.split(" "); a[0] = ""; return a.join(" "); };

export interface DailyScheduleDialogProps {
    event: SelectedEvent | null;
    onClose: () => void;
};

export const DailyScheduleDialog = ({ event, onClose }: DailyScheduleDialogProps) => {
	const { classes, deviceInfo } = dailyScheduleDialogStyles();

    //use react router navigate
    const navigate = useNavigate();

    //api
    const downloadFileApi = useApi(DownloadFile);

    //state variables
	const [scopeId, setScopeId] = useState<int | undefined>(undefined);
	
	//get event data
    const { data: eventData } = useLoadApi(GetDailyScheduledEventDetails, [event?.id ?? 0, event?.itemType ?? 0, event?.date ?? moment()], [event], { disabled: event === null });

	//get SO procedures if it has a uniqueid
	const { data: procedures } = useLoadApi(GetServiceOrderProcedures, [event?.id ?? 0], [event], {disabled: event === null || event.itemType !== 1});

    //get scopes
	const { data: scopes } = useLoadApi(GetSOAdditionalScopes, [event?.id ?? 0], [event], {disabled: event === null || event.itemType !== 1});

	//generate procedure links
	const procedureLinks = useMemo(() => {
		if (!procedures) return <></>;
		return( 
		<>
			{procedures.map((procedure, index) => {
				 if (procedure.pathLocator){
					return <MuiLink component={'button'} variant={'body1'}  sx={{marginLeft: '5px'}} onClick={() => {
                        downloadFileApi(FileTables.ProcedureFiles, procedure.pathLocator).then((file) => {
                            download(file);
                        });
                    }}>{procedure.name + (index !== procedures.length-1 ? ',' : '')}</MuiLink>;
				 } 
				 else return <Typography color={'inherit'} sx={{marginLeft: '5px'}} >{procedure.name + (index !== procedures.length-1 ? ', ' : '')}</Typography>;
			})}
		</>
		);
	}, [procedures]);

    //generate scope links
	const scopeLinks = useMemo(() => {
        if (!scopes) return <></>;

		return( 
		<>
			{scopes.map((scope, index) => {
				 return (
				 	<MuiLink component={'button'} variant={'body1'} /* className={classes.versionNameText} */ sx={{marginRight: '5px'}} 
				 		onClick={() => {
							setScopeId(scope.id);
						}}
					>{(scope.fileName.substring(0, scope.fileName.lastIndexOf('.'))) + (index !== scopes.length-1 ? ',' : '')}</MuiLink>
				 );
			})}
		</>
		);
	}, [scopes]);

    //if the qr code is focused with a backdrop
    const [focusQr, setFocusQr] = useState<boolean>(false);

    //ref for qr code container
    const [qrCodeContainerWidth, setQrCodeContainerWidth] = useState<number>();

    const onQrCodeContainerRef = useCallback((node: HTMLDivElement | null) => {
        if (node === null) return;

        const compStyles = getComputedStyle(node);
        setQrCodeContainerWidth(node.offsetWidth - parseFloat(compStyles.paddingLeft) - parseFloat(compStyles.paddingRight));
    }, [setQrCodeContainerWidth]);

    //modify URL when the modal is closed
    const close = () => {
        //call onClose
        onClose();
    };

    //get a google maps link from an address
    const getAddressLink = (address: string) => {
        if (address === null) {
            return "_blank";
        }

        if (address.toLowerCase().includes("pnl")) {
			//google maps for PNL building
            return "https://goo.gl/maps/qshEGK2bV6Fyv4FV6";
        } else {
			//google maps with encoded address
			const fixedAddress = address.replace('&', 'and');
            return encodeURI(`https://maps.google.com?q=${fixedAddress}`);
        }
    };

    //skeleton content render method
    const SkeletonContent = () => (
        <DialogContent className={classes.titleContainer}>
            <Alignment row>
                <Skeleton variant="rectangular" className={classes.propNumContainer} height={60} width={50}/>
                <Alignment column>
                    <Spacer horizontal size={!deviceInfo.isSm ? "150px" : "300px"}/>
                    <Typography variant={deviceInfo.isSm ? "h5" : "h4"}><Skeleton/></Typography>
                    <Typography variant={deviceInfo.isSm ? "h6" : "h5"}><Skeleton/></Typography>
                    <Typography variant={"subtitle1"}><Skeleton/></Typography>
                </Alignment>
            </Alignment>
            <Divider className={classes.headerDivider}/>
            <div className={classes.timeContainer}>
                <Skeleton variant={"text"} height={30} width={150}/>
                <Alignment row>
                    <Skeleton variant={"text"} height={30} width={90}/>
                    <Spacer horizontal size={"20px"}/>
                    <Skeleton variant={"text"} height={30} width={90}/>
                </Alignment>
                <Spacer vertical size={"10px"}/>
                <Skeleton variant={"text"} height={40} width={240}/><Spacer vertical size={"10px"}/>
                <Skeleton variant={"text"} height={30} width={180}/><Spacer vertical size={"10px"}/>
                <Skeleton variant={"text"} height={30} width={120}/>
                <Skeleton variant={"text"} height={80} width={"100%"}/><Spacer vertical size={"10px"}/>
                <Skeleton variant={"text"} height={30} width={90}/><Spacer vertical size={"10px"}/>
                <Skeleton variant={"text"} height={30} width={90}/><Spacer vertical size={"10px"}/>
                <Skeleton variant={"text"} height={30} width={120}/>
                <Skeleton variant={"text"} height={60} width={"100%"}/>
                <Skeleton variant={"text"} height={60} width={"100%"}/>
            </div>
        </DialogContent>
    );

    return (
        // Dialogue container
        <Dialog 
            open={event !== null} 
            onClose={close}
            fullScreen={!deviceInfo.isSm}
            TransitionComponent={!deviceInfo.isSm ? SlideUp : undefined}
        >
            {!deviceInfo.isSm ? (
                <XDialogTitle
                    onClose={close}
                    fullScreen
                >
					<Typography variant={'h6'}>{"Work Details"}</Typography>
						{event?.itemType === 1 ? 
						<Tooltip title={'Check into this job'}>
							<IconButton
								//size={'large'}
								disabled={!(event?.date.isBetween(moment().subtract(2, 'days'), moment()))}
								//className={classes.checkIntoJob}
								color={'inherit'}
								sx={{margin: '0 10px 0 auto'}}
								onClick={() => {
									navigate(axios.getUri({ url: "/dashboard/schedule/checking-into-job", params: { id: event?.id ?? -1 }}));
								}}
							><AccessTimeIcon/></IconButton>
						</Tooltip>
						: null }
				</XDialogTitle>
            ) : null}

            {/* If no eventData has been loaded yet then show a skeleton */}
            { !eventData ? (
                <SkeletonContent/>
            ) : (
                <>
                    {/* Main Dialogue Content */}
                    <DialogContent className={classes.titleContainer}>
                        {/* Header */}
                        <Alignment row>
                            {/* change header based on device size */}
                            <Paper className={classes.propNumContainer}>
                                <Alignment column sx={{ minWidth: '52px' }}>
                                    {/* serviceorderid */}
                                    <Typography
                                        variant={"h4"}
                                        className={classes.propNumText}
                                    >{eventData.details.soId}</Typography>
                                    
                                    {/* date range 'daily' or 'weekly' */}
                                    <Typography
                                        variant={"subtitle1"}
                                        className={classes.propNumText}
                                    >{eventData.details.range}</Typography>
                                </Alignment>
                            </Paper>

                            {/* Show the Client Name, JobNo, and JobName right of the box */}
                            <Alignment column className={classes.detailsContainer}>
                                {/* Client Name */}
                                <Typography
                                    className={classes.clientName}
                                    variant={deviceInfo.isMd ? "h4" : "h5"}
                                >{eventData.details.client}</Typography>
                                
                                {/* Job Number */}
                                <Typography 
                                    variant={deviceInfo.isMd ? "h5" : "h6"}
                                >{getJobNo(eventData.details.fullJobName)}</Typography>

                                {/* Full Job name for Not Mobile */}
                                {deviceInfo.isSm ? (
                                    <Typography 
                                        variant={"subtitle1"}
                                    >{getJobName(eventData.details.fullJobName)}</Typography>
                                ) : null}
                            </Alignment>
                        </Alignment>

                        {/* Full Job name for Mobile */}
                        {!deviceInfo.isSm ? (
                            <Typography 
                                className={classes.smallJobName}
                                variant={"subtitle1"}
                            >{getJobName(eventData.details.fullJobName)}</Typography>
                        ): null}
                        <Divider className={classes.headerDivider}/>

                        {/* Body */}
                        <div className={classes.timeContainer}>
							
							<Alignment row>
								<Alignment column>
									<Alignment row>
                            			{/* SO Date Range */}
                            			<LabeledItem label={"Date(s):"} item={eventData.details.dateRange} row spacer/>

									</Alignment>
                            		{/* SO Lab & On Site Time */}
                            		<Alignment row>
                            		    <LabeledItem label={"Lab:"} row item={formatTimeStr(eventData.details.labTime)}/>
                            		    <Spacer horizontal size={"20px"}/>

                            		    <LabeledItem label={"On-Site:"} row item={formatTimeStr(eventData.details.onSiteTime)}/>
                            		</Alignment>
								</Alignment>

								{!deviceInfo.isSm ? null : event?.itemType === 1? (
									<Tooltip title={'Check into this job'}>
										<IconButton
											size={'small'}
											disabled={!(event?.date.isBetween(moment().subtract(2, 'days'), moment()))}
											color={'inherit'}
											//className={classes.checkIntoJob}
											sx={{margin: '0 0 auto auto'}}
											onClick={() => {
												navigate(axios.getUri({ url: "/dashboard/schedule/checking-into-job", params: { id: event?.id ?? -1 }}));
											}}
										><AccessTimeIcon/></IconButton>
									</Tooltip>
								) : null }
							</Alignment>
                            <Spacer vertical size={"10px"}/>
                            

                            {/* SO Location */}
                            <LabeledItem label={"Location:"} item={
                                <MuiLink
                                    className={classes.alignedText}
                                    color={"primary"}
                                    variant={"body1"}
                                    target={"_blank"}
                                    href={getAddressLink(eventData.details.serviceLocation)}
                                >{eventData.details.serviceLocation}</MuiLink>                            
                            } row={deviceInfo.isMd} spacer/>

                            {/* Feedback QR Code Dropdown (Only available for Service Order Items */}
                            {event && event.itemType === 1 ? (
                                <div>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                        >
                                            <QRCodeIcon style={{ marginRight: '10px' }}/>
                                            <Typography variant={"body1"} style={{ fontWeight: 'bold' }}>{"Feedback"}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails ref={onQrCodeContainerRef}>
                                            <QRCode
                                                onClick={() => { setFocusQr(x => !x); console.log("test"); console.log(focusQr); }}
                                                size={qrCodeContainerWidth}
                                                level={"M"}
                                                value={`https://feedback.pnltest.net/Feedbacks/SubmitFeedbackSO/3/${event.id}`}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                    <Spacer vertical/>
                                </div>
                            ) : null}

                            {/* SO Project Manager */}
                            <LabeledItem 
                                label={"PM:"} 
                                item={
                                    <MuiLink
                                        color={"primary"}
                                        component={Link} 
                                        variant={"body1"}
                                        to={`/home/employee-directory/${eventData.details.pmId}`}
                                    >{eventData.details.pmName}</MuiLink>
                                } 
                                row 
                                spacer
                            />

                            {/* Only display the Site Contacts for the SO if they exist */}
                            {eventData.contacts.length === 0 ? null : <LabeledItem label={"Site Contact(s):"} item={<SiteContactsList contacts={eventData.contacts} displaySignature={eventData.displaySignature} />} spacer/>}
                            
                            {/* Show Tasks, Details, Code if it exists, and P.O.# for the SO */}
							<LabeledItem label={"P.O. No:"} item={eventData.details.ponum} row spacer/>
                            <LabeledItem label={"Tasks:"} item={eventData.details.typeTest} row spacer/>
                            {eventData.qualityCode !== null ? <LabeledItem label={"Codes: "} item={eventData.qualityCode} row spacer inline/> : null}
                            <LabeledItem label={"Details: "} inline item={eventData.details.specialInstruct === "" ? "--" : eventData.details.specialInstruct} row={(eventData.details.specialInstruct === "" ? "--" : eventData.details.specialInstruct) === "--"} spacer/>
                            
							{/* Show SO procedures if they exist */}
							{procedures ? 
								<LabeledItem label={"Procedures: "} inline item={procedureLinks} labelProps={{display: 'inline-flex', sx: {flexFlow: 'wrap'} }} row spacer/>
							: null }

                            {/* Scopes if they exist */}
                            {scopes ? 
								<LabeledItem label={"Scopes: "} inline item={scopeLinks} row spacer/>
							: null }

                            {/* Only display the Notes for the SO if they exist */}
                            {eventData.details.soNotes === "" ? null : <LabeledItem label={"Notes:"} inline item={eventData.details.soNotes} row spacer/>}	

							{/* Scheduled Techs and their vehicles */}
                            <LabeledItem label={"Scheduled Techs:"} item={<AssignmentList assignments={eventData.assignments}/>}/>

                        </div>
                    </DialogContent>
                    <DialogActions sx={{flexFlow: 'wrap', padding: '8px 8px 0 8px', "& .MuiButton-root": {marginBottom: '8px'} }}>
                        {/* TEMP Light Pole Page Button */}
                        {eventData.details.lightPoleCheck ? (
                            <Button
                                startIcon={<LightBulbIcon/>}
                                color={"primary"}
                                variant={"contained"}
                                component={Link} 
                                to={`/light-pole/${eventData.details.id}/measurements`}
                            >{"Light Pole"}</Button>
                        ) : null}

                        {/* If the device is Md or larger and the itemTypeID === 1 then display the SO Full View button. */}
                        {deviceInfo.isMd && event?.itemType === 1 ? (
                            <Button
                                startIcon={<DescriptionIcon/>}
                                color={"primary"}
                                variant={"contained"}
                                component={Link} 
                                to={"/service-orders/details/" + eventData.details.id.toString()}
                            >{"Details"}</Button>
                        ) : null}

						{/* Work Place Safety Review Page Button */}
						{eventData.details.itemTypeId === 1 ? deviceInfo.isMd ? (
							<Button
								startIcon={<SafetyGogglesIcon/>}
								color={"primary"}
								variant={"contained"}
								onClick={() => {
									let windowProxy = window.open( axios.getUri({ url: IMAS.PAGES.SERVICE_ORDERS.WORK_PLACE_REVIEWS_LIST(eventData.details.id) }), undefined, "popup=0" );
									if ( windowProxy !== null) {
										windowProxy.isIMASPopup = false;
										windowProxy.opener = windowProxy;
									}
								}}
								sx={{marginLeft: '10px'}}
							>{"Safety"}</Button>
						) : (
							<Button
								startIcon={<SafetyGogglesIcon/>}
								color={"primary"}
								variant={"contained"}
								component={Link} 
								to={IMAS.PAGES.SERVICE_ORDERS.WORK_PLACE_REVIEWS_LIST(eventData.details.id)}
							>{"Safety"}</Button>
						) : null}

                        {/* Attachments Page Button */}
						{eventData.details.itemTypeId === 1 ? deviceInfo.isMd ? (
							<Button
								startIcon={<AttachFileIcon/>}
								color={"primary"}
								variant={"contained"}
								onClick={() => openPopup(axios.getUri({ url: `/service-orders/attachments/${eventData.details.id.toString()}`})  ) }
							>{"Attachments"}</Button>
						) : (
							<Button
								startIcon={<AttachFileIcon/>}
								color={"primary"}
								variant={"contained"}
								component={Link} 
								to={"/service-orders/attachments/" + eventData.details.id.toString()}
							>{"Attachments"}</Button>
						) : null}

						{/* If the taskCode === 16 and taskId is not null show the button to open the coaching session editor. */}
                        {eventData.details.taskCode === 16 && eventData.details.taskId !== null ? (
                            <Button
                                startIcon={<DescriptionIcon/>}
                                color={"primary"}
                                variant={"contained"}
                                onClick={() => openEditorWindow(IMAS.PAGES.MANAGE.COACHING_SESSIONS(), { id: eventData.details.taskId as int }, { name: `Schedule Editor #${eventData.details.taskId}`, height: 900, width: 650 })}
                            >{"Coaching Session"}</Button>
                        ) : null}
                    </DialogActions>

                    <PdfDialog
			        	id={scopeId}
			        	allowDownload
			        	onClose={() => {
			        		setScopeId(undefined);
			        	}}
			        ></PdfDialog>
                </>
            )}
        </Dialog>
    );
};