import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Fade, IconButton, ThemeProvider } from '@mui/material';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { store } from '@imas/redux';
import { Close as CloseIcon } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { SnackbarUtilsConfigurator } from '@imas/utils/snackbar';
//import { stopReportingRuntimeErrors } from 'react-error-overlay';
import { registerServiceWorker } from '@imas/service-worker';
import { GlobalStyles, TssCacheProvider } from 'tss-react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { TransitionProps } from '@mui/material/transitions/transition';
import { DeviceInfoProvider, useAppTheme } from '@imas/styles';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { DndProvider } from 'react-dnd';
import App from './App';
import AppProviders from './AppProviders';
import initIMAS from '@imas';
import './root.css';

//initalize the IMAS module
initIMAS(); 

//set license for mui-x pro
LicenseInfo.setLicenseKey("f588a6b8db5673c9d9a13b55ce7905c3Tz03MjkzNyxFPTE3MjM5MTIwMDEwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=");

//mui cache & tss cache for emotion
export const muiCache = createCache({
  "key": "mui",
  "prepend": true,
});

export const tssCache = createCache({
  "key": "imas",
});

if (process.env.NODE_ENV === "development") {
  //stopReportingRuntimeErrors(); // disables error overlays
}

//get base URL and root element
//  const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// add action to all snackbars
const notistackRef = React.createRef<SnackbarProvider>();
const onClickDismiss = (key: string) => () => { 
    notistackRef.current?.closeSnackbar(key);
};

//root app component which adds providers (and their settings) for the app
const Root = () => {
	//get the app main theme
	const theme = useAppTheme();

	return (
		<CacheProvider value={muiCache}>
			<TssCacheProvider value={tssCache}>
				{/* Material UI Theme Provider for providing the currently selected theme to the entire App */}
				<ThemeProvider theme={theme}>					
					{/* React DND Backend Provider */}
					<DndProvider backend={HTML5Backend}>
						{/* Provides Up-To-Date Device Info to the Entire Application */}
						<DeviceInfoProvider>
							
							{/* Material UI Pickers Localization Provider, provides the MomentUtils to any Material UI Picker component */}
							<LocalizationProvider dateAdapter={DateAdapter}>
								{/* notistack SnackbarProvider used to handle notification toasts in the App  */}
								<SnackbarProvider
									anchorOrigin={{
									vertical: "bottom",
									horizontal: "right"
									}}
									autoHideDuration={5000}
									TransitionComponent={Fade as React.ComponentType<TransitionProps>}
									ref={notistackRef}
									action={(key: string) => (
									<IconButton color={"inherit"} onClick={onClickDismiss(key)} size={"medium"}>
										<CloseIcon/>
									</IconButton>
									)}
									classes={{
									containerRoot: "snackbarRootOverride"
									}}
								>
									{/* Configure SnackbarUtils */}
									<SnackbarUtilsConfigurator/>

									{/* The Main App Object Where Pages are Rendered */}
									<AppProviders>
										<App />
									</AppProviders>

									{/* Inject Global Styles */}
									<GlobalStyles
										styles={{
											"body": {
											"background": theme.palette.mode === "light" ? 'rgb(255, 255, 255)' : '#121212',//'rgb(33, 33, 33)',
											},
											"*::-webkit-scrollbar": {
												"-webkit-appearance": "none",
												maxHeight: '12px',
											},
											"*::-webkit-scrollbar:vertical": {
												width: "8px",
											},
											"*::-webkit-scrollbar-thumb": {
												backgroundColor: theme.palette.mode === "light" ? 'rgba(0, 0, 0, .25)' : 'rgb(255, 255, 255, 0.75)',
												borderRadius: '10px',
												backgroundClip: 'content-box',
												border: '2px solid transparent'
											},
											"*::-webkit-scrollbar-corner": {
												background: "inherit"
											}
										}}
									/>
								</SnackbarProvider>
							</LocalizationProvider>
						</DeviceInfoProvider>
					</DndProvider>
				</ThemeProvider>
			</TssCacheProvider>
		</CacheProvider>
	);
};

//render the app
ReactDOM.render(
	<BrowserRouter>
		{/* Redux Provider, provides our application's main store */}
		<ReduxStoreProvider store={store}>
			<Root/>
		</ReduxStoreProvider>
	</BrowserRouter>,
	rootElement
);

//register app service worker
registerServiceWorker();