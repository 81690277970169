import React from 'react';
import { Paper, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { getUserData, getUserRoles } from '@imas/redux';
import { useLoadApi } from '@imas/api';
import { Alignment, XSkeleton } from '@imas/components/layout';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { employeeAlertsViewerStyles } from './EmployeeAlertsViewerStyles';
import { GetEmployeeAlerts, TimeOffAlertsResult } from '@imas/api/employee';
import { GetHolidays } from '@imas/api/misc/holidays';
import { Holiday } from '@imas/api/misc/types/Holiday';
import { Message, vVehicleInspection } from 'src/api/types/api/misc/misc';
import { openVehicleInspectionEditor } from 'src/pages/Home/Vehicles/VehicleInspection/VehicleInspectionEditor';
import { useOpenVehicleInspections } from '@imas/api/data-hooks/misc';
import { Image as ImageIcon } from '@mui/icons-material';
import { GetRecentMessages } from 'src/api/misc/messages';

export const EmployeeAlertsViewer = () => {
    //component styling
    const { classes } = employeeAlertsViewerStyles();

    //get user roles and data
    //const userRoles = useSelector(getUserRoles);
	const userData = useSelector(getUserData);

    //get information
    const { data: alerts } = useLoadApi(GetEmployeeAlerts, [userData?.employee.employeeId ?? -1], [userData], {disabled: userData === null});
	const { data: holidays } = useLoadApi(GetHolidays, [moment()]);
    const { data: notifications } = useLoadApi(GetRecentMessages, [], []);
	const [ openInspections ] = useOpenVehicleInspections({disabled: userData === null || !userData.employee.fleetManager});

    //render nothing of the user is not a admin or manager
    //if (!(userRoles.admin || userRoles.manager)) return null;

    const EmployeeAlertsViewerCategory = ({ label, items }: { label: string, items?: TimeOffAlertsResult[] }) => {		
		if ( (items !== undefined) && (items.length === 0)) return null;

        return (
            <React.Fragment key={label}>
                <Typography variant={"h6"}>{label}</Typography>
                <List>
                    {items ? items.map((item, index) => (
                        <ListItem key={index} className={classes.alertItem}>
                            <ListItemText secondary={item.alertDescription}/>
                        </ListItem>
                    )) : [null, null, null].map((_, i) => (
                        <ListItem key={i}><ListItemText primary={<Typography variant={"body1"}><XSkeleton/></Typography>} secondary={<Typography variant={"caption"}><XSkeleton/></Typography>}/></ListItem>
                    ))}
                </List>
            </React.Fragment>
        );
    };

	const HolidayViewerCategory = ({ label, items }: { label: string, items?: Holiday[] }) => {		
		if ( (items !== undefined) && (items.length === 0)) return null;

        return (
            <React.Fragment key={label}>
                <Typography variant={"h6"}>{label}</Typography>
                <List>
                    {items ? items.map((item, index) => (
                        <ListItem key={index} className={classes.listItem}>
                            <ListItemText primary={item.holidayName} secondary={moment(item.holidayDate).format("ddd, MMM Do")}/>
                        </ListItem>
                    )) : [null, null, null].map((_, i) => (
                        <ListItem key={i}><ListItemText primary={<Typography variant={"body1"}><XSkeleton/></Typography>} secondary={<Typography variant={"caption"}><XSkeleton/></Typography>}/></ListItem>
                    ))}
                </List>
            </React.Fragment>
        );
    };

	//vehicle inspection notifications are for fleet managers only
	const VehicleInspectionCategory = ({ label, items }: { label: string, items?: vVehicleInspection[] }) => {		
		if ( (items !== undefined) && (items.length === 0)) return null;
		if (!items) return null;

        return (
            <React.Fragment key={label}>
                <Typography variant={"h6"}>{label}</Typography>
                <List>
                    {items ? items.map((item, index) => (
                        <ListItem key={index} className={classes.listItem} button onClick={() => openVehicleInspectionEditor({calVehInspectionId: item.calVehInspectionId})}>
                            <ListItemText primary={`Vehicle ${item.calItemVehicle.substring(0,2)} (${item.inspectionIncompleteSummary})`} secondary={moment(item.inspectionDate).format("ddd, MMM Do, YYYY")}/>{item.inspectionWithFiles ? <ImageIcon/> : null}
                        </ListItem>
                    )) : [null, null, null].map((_, i) => (
                        <ListItem key={i}><ListItemText primary={<Typography variant={"body1"}><XSkeleton/></Typography>} secondary={<Typography variant={"caption"}><XSkeleton/></Typography>}/></ListItem>
                    ))}
                </List>
            </React.Fragment>
        );
    };

    //show recent custom notification messages
	const RecentNotificationsCategory = ({ label, items }: { label: string, items?: Message[] }) => {		
		if ( (items !== undefined) && (items.length === 0)) return null;
		if (!items) return null;

        return (
            <React.Fragment key={label}>
                <Typography variant={"h6"}>{label}</Typography>
                <List>
                    {items ? items.map((item, index) => (
                        <ListItem key={index} className={classes.listItem} >
                            <ListItemText primary={`${item.title}`} 
                                secondary={ item.senderName + (item.body !== null ? " - " : null) + item.body}
                            />
                        </ListItem>
                    )) : [null, null, null].map((_, i) => (
                        <ListItem key={i}><ListItemText primary={<Typography variant={"body1"}><XSkeleton/></Typography>} secondary={<Typography variant={"caption"}><XSkeleton/></Typography>}/></ListItem>
                    ))}
                </List>
            </React.Fragment>
        );
    };

    return (
        <Paper className={classes.viewerContainer}>
            <Typography variant={"h5"} style={{ fontWeight: 'bold', margin: '10px auto' }}>{"Notifications"}</Typography>
            
            <Alignment overflowScroll style={{ margin: '0 15px', flex: '1 1 0' }}>
                {/* Time Off */}
                <EmployeeAlertsViewerCategory label={"Time Off"} items={alerts}/>

                {/* Recent Notifications */}
                <RecentNotificationsCategory label={"Recent Alert Notifications"} items={notifications?.sort((x, y) => moment(x.dateAdded).isBefore(y.dateAdded) ? 1 : -1)}/>

				{/* Open Vehicle Inspections, fleet managers only */}
				<VehicleInspectionCategory label={'Vehicle Issues'} items={openInspections?.sort((x, y) => moment(x.inspectionDate).isBefore(y.inspectionDate) ? 1 : -1)}/>

				{/* Holidays */}
				<HolidayViewerCategory label={"Upcoming Holidays"} items={holidays}/>

            </Alignment>
        </Paper>
    );
};