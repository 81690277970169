import React, { useCallback, useImperativeHandle, useMemo, useRef } from "react";
import { FieldValues, UnpackNestedValue, useFormState, UseFormTrigger,  UseFormSetValue, UseFormGetValues, UseFormReset, FieldPath, FieldPathValue, SetValueConfig, DefaultValues } from "react-hook-form";
import { Editable, EditableControl, SpreadableFormProps, FormRefSubmit, OnResetCallback, OnResetCallbackRecord, RegisterOnResetCallback, OnBlurCallback, OnBlurCallbackRecord, RegisterOnBlurCallback, OnFormFieldBlur } from "./types";
import { getErrorAdder } from "./utils";
import MuiFormContext, { TEditableFormContext } from './MuiFormContext';

//FormRef interface
export interface MuiFormRef<TFieldValues extends FieldValues, R extends any = any> {
    isSubmitted: boolean;
    isSubmitSuccessful: boolean;
    isSubmitting: boolean;
    isValid: boolean;
    isDirty: boolean;
    isLoading: boolean;
    isSaving: boolean;
    hasUnsavedChanges: boolean;
    submit: FormRefSubmit<TFieldValues, R>;
    trigger: UseFormTrigger<Editable<TFieldValues>>;
    setValue: UseFormSetValue<Editable<TFieldValues>>;
    getValues: UseFormGetValues<Editable<TFieldValues>>;
    reset: UseFormReset<Editable<TFieldValues>>;
};

//FormPartial interface
export interface MuiFormPartial {
    viewOnly?: boolean;
    loading?: boolean;
	size?: "small" | "x-small";
};

//FormProps interface
export interface MuiFormProps<TFieldValues extends FieldValues, TContext extends object>
     extends SpreadableFormProps<TFieldValues, TContext>, 
     MuiFormPartial
{};

const MuiFormRender = <T extends FieldValues, TContext extends object>(props: React.PropsWithChildren<MuiFormProps<T, TContext>>, ref: React.ForwardedRef<MuiFormRef<T>>): JSX.Element => {
    //unpack props
    const { 
        context, validator,
        control, handleSubmit, trigger, setError, setValue, getValues, reset: rawReset, onSuccessfulSubmit, onFieldBlur: rawOnFieldBlur,
        viewOnly, loading, children, size
    } = props;

	//list of registered onReset callbacks
	const onResetCallbacksRef = useRef<OnResetCallbackRecord<Editable<T>>[]>([]);

	//registers a new callback
	const registerOnResetCallback = useCallback<RegisterOnResetCallback<Editable<T>>>((field, callback) => {
		onResetCallbacksRef.current = [...onResetCallbacksRef.current as OnResetCallbackRecord<Editable<T>>[], { field, callback } as unknown as OnResetCallbackRecord<Editable<T>>];

		//unregister callback
		return () => {
			onResetCallbacksRef.current = onResetCallbacksRef.current.filter(x => x.callback !== (callback as unknown as OnResetCallback<Editable<T>>));
		};
	}, []);
	
	//handles calling the registered callbacks when reset is called
	const handleOnResetCallbacks = useCallback(() => {
		for (const registration of onResetCallbacksRef.current) {
			registration.callback(getValues(registration.field) as unknown as any);
		}
	}, [getValues]);
	
	//resetRef
	const resetRef = useRef(rawReset);
	resetRef.current = rawReset;
	const reset = useCallback<UseFormReset<Editable<T>>>((values, options) => {
		//call original reset
		resetRef.current(values, options);

		//trigger validation
		trigger();
	
		//call onReset callback hanlder
		handleOnResetCallbacks();
	}, [trigger, handleOnResetCallbacks]);

	//list of registered onBlur callbacks
	const onBlurCallbacksRef = useRef<OnBlurCallbackRecord<Editable<T>>[]>([]);

	//registers a new onBlur callback
	const registerOnBlurCallback = useCallback<RegisterOnBlurCallback<Editable<T>>>((field, callback) => {
		onBlurCallbacksRef.current = [...onBlurCallbacksRef.current as OnBlurCallbackRecord<Editable<T>>[], { field, callback } as unknown as OnBlurCallbackRecord<Editable<T>>];

		//unregister callback
		return () => {
			onBlurCallbacksRef.current = onBlurCallbacksRef.current.filter(x => x.callback !== (callback as unknown as OnBlurCallback<Editable<T>>));
		};
	}, []);

	//handles calling the registered callbacks when onFieldBlur is called
	const handleOnBlurCallbacks = useCallback<OnFormFieldBlur<Editable<T>>>((field, params) => {
		for (const registration of onBlurCallbacksRef.current) {
			if (registration.field === field) registration.callback(params as any);
		}
	}, []);

	//onFieldBlur
	const onFieldBlurRef = useRef(rawOnFieldBlur);
	onFieldBlurRef.current = rawOnFieldBlur;
	const onFieldBlur = useCallback<OnFormFieldBlur<Editable<T>>>((field, params) => {
		//call original reset
		if (onFieldBlurRef.current) onFieldBlurRef.current(field, params);
	
		//call onReset callback hanlder
		handleOnBlurCallbacks(field, params);
	}, [handleOnBlurCallbacks]);

    //get form state
    const { isSubmitted, isSubmitSuccessful, isSubmitting, isValid, dirtyFields } = useFormState({ control: (control as EditableControl<T>) });

    //calculate isDirty
    const isDirty = Object.keys(dirtyFields).length > 0;

	//calculate isLoading
	const isLoading = (loading ?? false) || isSubmitting;
    
    //onSubmit callback
    const validateFormValues = useCallback(async (data: UnpackNestedValue<Editable<T>>) => {
        const [errors, addError] = getErrorAdder<T>(data);
        const values = validator(data, addError, context ?? null);
		
        //if the result is a string the validation failed and the result is an error message
        if (values === undefined || values === null || Object.keys(errors).length > 0) {
            throw errors;
        }

        return values;
    }, [context, validator]);

    //trigger form submit
    const submit = useCallback<FormRefSubmit<T, any>>((onSuccess) => {
        //outer promise resolves to the value of the resultPromise once handleSubmit has resolved
        return new Promise((resolve, reject) => {
            const resultPromise = new Promise<any>((resolveResult, rejectResult) => {
                //call handleSubmit
                handleSubmit(async (data) => {
                    //validate the values
                    const validData = await validateFormValues(data);

                    //call the onSuccess function with the validated values and wait for it to finish
                    const result = await onSuccess(validData);

                    //resolve result
                    resolveResult(result);
                }, async (errors) => {
                    //reject errors
                    rejectResult(errors);
                })()
                .then(() => {
                    //once handleSubmit is done resolve the resultPromise and resolve/reject it's results 
                    resultPromise
                    .then(x => {
						resolve(x);

						//call onSuccessfulSubmit hanlder if it was provided
						if (onSuccessfulSubmit) onSuccessfulSubmit();
					})
                    .catch(e => reject(e));
                })
                .catch(e => reject(e));
            });
        });
    }, [handleSubmit, validateFormValues, onSuccessfulSubmit]);
    
     //overwrite setValue to validate by default
     const setValueValidate = useCallback(<TFieldName extends FieldPath<Editable<T>>>(name: TFieldName, value: UnpackNestedValue<FieldPathValue<Editable<T>, TFieldName>>, options?: SetValueConfig): void => {
        setValue(name, value, { shouldValidate: true, ...options });
    }, [setValue]);

    //when loading changes and is true, validate the form
    // useEffect(() => {
    //     if (loading) trigger();
    // }, [loading]);

    //imperative handle for EditableFormRef
    useImperativeHandle(ref, () => ({
        isSubmitted,
        isSubmitSuccessful,
        isSubmitting,
        isValid,
        isDirty,
        isLoading,
        isSaving: isSubmitting,
        hasUnsavedChanges: isDirty && !isSubmitSuccessful && !viewOnly,
        submit,
        trigger,
        setValue,
        getValues,
        reset: reset,
    }), [isSubmitted, isSubmitSuccessful, isSubmitting, isValid, isDirty, isLoading, viewOnly, submit, trigger, setValue, getValues, reset]);

    //get provider value
    const formContext: TEditableFormContext<T> = useMemo(() => ({
        isLoading,
        isViewOnly: viewOnly ?? false,
        control,
		size: size ?? "x-small",
        trigger,
        setError,
        setValue: setValueValidate,
        getValues, 
        reset,
		onFieldBlur,

		registerOnResetCallback,
		registerOnBlurCallback,
    }), [isLoading, viewOnly, control, size, trigger, setError, setValueValidate, getValues, reset, onFieldBlur, registerOnResetCallback, registerOnBlurCallback]);

    //render form and children
    return (
		//TODO Fix the Typing here.
        <MuiFormContext.Provider value={formContext as unknown as TEditableFormContext<{}>}>
            {children}
        </MuiFormContext.Provider>
    );
};

//wrap the FormWrapperRender with a React.forwardRef
const MuiForm = React.forwardRef(MuiFormRender);

export { MuiForm };