import { GetFileInfo } from '@imas/api/files';
import { getApi } from '@imas/api';
import axios from 'axios';
import { store } from '@imas/redux';
import { FileTables } from '@imas/api/files/types';
import { openPopup } from '@imas/utils/misc';

interface OpenFileExplorerOptions {

};

export async function openFileExplorer(table: FileTables, folder: HierarchyId | null, options?: OpenFileExplorerOptions): Promise<string | null> {
    //check if office mode is enabled
    const officeModeEnabled = store.getState().appSettings.value.general.officeMode;

    //get api
    const { api: getFileInfo } = getApi(GetFileInfo);

    if (officeModeEnabled) {
        try {
            //get info for this folder
            const fileInfo = await getFileInfo(table, folder ?? "/");
            
            //use native interop if available
            if (window?.native) {
                return window.native.openLocal(fileInfo.fullPath);
            }

            //fallback to localexplorer
            window.open("localexplorer:" + fileInfo.fullPath, "_blank");
            return null;
        } catch(e) {
            //return error message
            if (typeof e === "string") return e;
            else return "An unknown error has ocurred trying to open folder.";
        }
    } else {
        //open web file explorer
        const window = openPopup(
            axios.getUri({ url: '/files/explore', params: { 
                table, 
                folder: folder ?? undefined,
            }}), 
            {
				name: `IMAS File Explorer: ${folder ? `(Folder) ${folder}` : `(Table)${table}`}`, 
            	width: 1200,
				height: 600,
			},
        );

        //check if viewer popup was opened
		if (window === null) return "Failed to open file explorer popup.";
        return null;
    };
};