import { Alignment } from '@imas/components/layout';
import { AppBar, Box, Typography,Paper } from '@mui/material';
import { isPopup } from '@imas/utils/misc';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { ChangePassword as TChangePassword } from '@imas/api/auth/types/ChangePassword';
import { ChangePassword } from 'src/api/misc/misc';
import { MuiFormSubmit, useMuiFormRef } from '@imas/mui-form';
import { useApi } from '@imas/api';
import ChangePasswordForm from './ForgotForm/ChangePasswordForm';
import { Navigate, useNavigate, useParams } from 'react-router';
import { useCallback } from 'react';
import forgotLoginStyles from './ForgotLoginStyles';
import { useState } from 'react';
export const ChangePass = () => {
    document.title = "IMAS - Change Password";

	const { classes, deviceInfo } = forgotLoginStyles();
	const [submitted, setSubmitted] = useState(false);
    const { hashKey } = useParams();
	const isPopupWindow = isPopup();
    const showSnackbar = useAutomaticSnackbar();
	const [formRef, onFormRef] = useMuiFormRef<TChangePassword, void>();
	const changePassword = useApi(ChangePassword);
	const navigate = useNavigate();
	const submit = useCallback( async ( data: TChangePassword, ) => {
		//display notification
	
		const close = showSnackbar("Changing Password...", {variant: "info", persist: true});
	
		try{	
			await changePassword(hashKey ?? "", data.password);
	
			close();
			
			showSnackbar("Password changed.", {variant: 'success'}, true);
			
		} catch(e){
			 close(e);
			 showSnackbar("Invalid Credentials.", {variant: 'error'}, true);
			 throw navigate("/invalid-credentials");		}

	  }, []);
    return(
        <>
        <Alignment column>
        <AppBar position={"static"} color={"primary"} sx={{height: '56px'}}>
					<Alignment row sx={{margin: 'auto 0 auto 0'}}>

						{/*  PNL Image */}	
						<Box component={"img"} sx={{margin: '0 10px 0 10px', maxWidth: '75px', width: '-webkit-fill-available' }} src={"/images/Logo-Transparent.png"}/>
						<Typography variant={'h5'} sx={{margin: 'auto 0 auto 10px'}}>{'Reset Password'}</Typography>

					</Alignment>
					
				</AppBar>
                <Alignment 
				column 
				flex 
				sx={{
					margin: ['2vh 8%', '5vh 8%', '10vh 8%'],
					width: '-webkit-fill-available',
					maxWidth: '400px',
					alignSelf: 'center',
				}}
			>

                <Paper className={classes.loginContainer} sx={{ padding: '25px', marginTop: '20px' }}>
					
                <Typography align="center">
					{submitted ? "Successfully reset password." : "Please enter your new password."}
				</Typography>
                <ChangePasswordForm
				submitted={submitted}
                ref={onFormRef}

                />
                <MuiFormSubmit
				disabled={submitted}
                    formRef={formRef}
                    onSubmit={submit}
                    onSuccessfulSubmit={() => {
                        setSubmitted(true);
                    }}
                    disablePreventNavigation
                >{"Update"}
                </MuiFormSubmit>
                </Paper>
            </Alignment>
            </Alignment>
        </>
    );

};