import { useApi, useLoadApi } from '@imas/api';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { DatePicker } from '@mui/lab';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Checkbox, FormControlLabel, Autocomplete } from '@mui/material';
import moment, { Moment } from 'moment';
import { useMemo, useState } from 'react';
import { CreateSignatureRequest, GetServiceOrderContacts, GetvServiceOrderContactDetails } from 'src/api/service-order/api';
import { openClientSignaturePage } from './ClientSignaturePage';
import { Email as EmailIcon } from '@mui/icons-material';
import MobileClientSignatureStyles from './MobileClientSignatureStyles';
import { vServiceOrderContact, vServiceOrderSiteContact } from 'src/api/types/api/service-orders/types';
import { Alignment, Spacer } from '@imas/components/layout';
import { Pdf as PdfIcon } from '@imas/components/icons';
import { GetAllClientLocationContacts, GetClientLocationContact, GetClientLocationContacts } from '@imas/api/client';

export interface SignatureRequestDialogProps{
	open: boolean,
    onClose: () => void;
	uniqueId: int,
	startDate: string,
	endDate: string,
	siteContacts: vServiceOrderSiteContact[],
	contactName?: string,
	contactEmail?: string,
}

export const SignatureRequestDialog = ({ open, onClose, uniqueId, startDate, endDate, siteContacts, contactName, contactEmail }: SignatureRequestDialogProps) => {
	//use automatic hiding snackbar
	const showSnackbar = useAutomaticSnackbar();

	const now = new Date();

	//component styling
	const { classes, deviceInfo } = MobileClientSignatureStyles();

	//load contacts if they are not provided
	const {data: contactDetails} = useLoadApi(GetvServiceOrderContactDetails, [uniqueId], [uniqueId]);

	interface ContactMenuOption {
		name: string,
		email: string | null,
	}

	//turn contacts and sitecontacts into options for an autocomplete
	const contactOptions = useMemo(() => {
		if (!contactDetails) return undefined;

		let cOptions = contactDetails.map((contact) => {
			const option: ContactMenuOption = {name: contact.contact, email: contact.email};
			return option;
		});

		if (!siteContacts) return cOptions.sort((a, b) => a.name > b.name ? 1 : -1);
		siteContacts.map((contact) => {
			const option: ContactMenuOption = {name: contact.contact, email: contact.email};
			if (cOptions.findIndex(x => x.name === contact.contact) === -1) cOptions.push(option);
		});

		return cOptions.sort((a, b) => a.name > b.name ? 1 : -1);

	}, [contactDetails, siteContacts]);

	//api
	const createSignatureRequest = useApi(CreateSignatureRequest);

	//state variables
	const [name, setName] = useState<string>(contactName !== undefined ? contactName : '');
	const [email, setEmail] = useState<string>(contactEmail ?? '');
	const [sendCopy, setSendCopy] = useState<boolean>(true);
	const [thruDate, setThruDate] = useState<Moment>(Date.parse(endDate) < now.valueOf() ? moment(endDate) : Date.parse(startDate) < now.valueOf() ? moment(startDate) : moment()); 
	const [notes, setNotes] = useState<string>('');
	const [workNote, setWorkNote] = useState<string>('');
	const [requestLoading, setRequestLoading] = useState<boolean>(false);
	const PDF_DISABLED_VALUE = 1;
	const EMAIL_DISABLED_VALUE = 2;
	const { data: emailContacts } = useLoadApi(GetAllClientLocationContacts, [email], [email], {disabled: !email});

	const contactPreference = useMemo(() => {
		//if there is no email record, default return is 0 since that represents BOTH email and PDF signature method
		if(!emailContacts || emailContacts.length === 0) return 0;

		//loop to find minimum signature method type 
		let minSignatureMethodType = EMAIL_DISABLED_VALUE;
		for ( let i = 0; i < emailContacts.length; i++)
		{
			if(minSignatureMethodType > emailContacts[i].signaturemethodtype)
			{
				//check if the contact is active. 
				if(emailContacts[i].active)
				{
					minSignatureMethodType = emailContacts[i].signaturemethodtype;

				}
				
			}
			
		}
		return minSignatureMethodType;
		
	}, [emailContacts]);
	const signDisabled = useMemo(() => {
		let correctEmail = checkIfEmailInString(email);

		if (name === '') return true;
		if (email !== '' && !(correctEmail)) return true; 
		if (thruDate === null) return true;

		if(sendCopy && !correctEmail) return true;
		

		return false;
	}, [name, email, thruDate, sendCopy, contactPreference]);

	const emailDisabled = useMemo(() => {
		if (!email) return true;
		
		return signDisabled;
	}, [email, signDisabled, contactPreference]);



	function checkIfEmailInString(text: string) { 
		if (text.includes(';')) return false;
		if (text.includes(' ')) return false;
		let re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
		let dupeAt = /(@).*\1/;
		return re.test(text) && !dupeAt.test(text);
	}

	const emailClicked = () => {
		if(sendCopy) {
			setSendCopy(false);
		}
		else {
			setSendCopy(true);
		}
	};

	const onSignatureDialogClose = () => {
		setName('');
		setEmail('');
		//setThruDate(null);
		onClose();
	};

	const onSubmit = (requestType: 'sign-now' | 'email-request' | 'email-pdf') => {
		if (thruDate === null) return;

		//progress notification
		const close = showSnackbar("Processing email request...", {variant: 'info', persist: true}, false);
		setRequestLoading(true);
		onSignatureDialogClose();

		createSignatureRequest({uniqueId, name, notes, workNote, email, thruDate: thruDate.format(), sendCopy: sendCopy, requestType: requestType}).then((signatureRequest) => {
			close();

			if (requestType === 'sign-now') openClientSignaturePage(signatureRequest.hashUrl);
			else if (requestType === 'email-request') {
				showSnackbar("Email sent.", {variant: 'success'}, true);
			}
			else if (requestType === 'email-pdf') {
				showSnackbar("Email sent with document.", {variant: 'success'}, true);
			}

			
		}).catch(e => {
			close(e);
		});

		setRequestLoading(false);
	};

	return ( <>
		{/* Signature request dialog */}
		<Dialog open={open} onClose={onSignatureDialogClose} maxWidth={'sm'} fullWidth /* fullScreen={!deviceInfo.isSm} */ >
			<DialogTitle>{'Signature Request'}</DialogTitle>

			<DialogContent>

				<Spacer vertical/>
				
				{/* Name Field */}
				<Autocomplete
					//autoComplete
					freeSolo
					options={contactOptions ?? []}
					onInputChange={(e, value) => {
						setName(value);
					}}
					defaultValue={{name: contactName ?? '', email: contactEmail ?? '' }}
					onChange={(_, contact) => {
						if (contact === null) setName('');
						else if (typeof contact === 'string') setName(contact);
						else {
							setName(contact.name);
							setEmail(contact.email ?? '');
						}
					}}
					isOptionEqualToValue={(option, value) => option.name === value.name}
					getOptionLabel={(option) => option.name}
					renderInput={(props) => <TextField required label={"Name"} value={name} {...props} />}
					fullWidth
				/>

				<Spacer vertical/>

				{/* Email Field */}
				<TextField
					label={'Email'}
					value={email}
					onChange={(event) => setEmail(event.target.value)}
					fullWidth
					style={{ width: "-webkit-fill-available" }}
					inputProps={{maxLength:250}}
				></TextField>

				<Alignment column>
				{/* Send Copy Checkbox */}
				<FormControlLabel
					className={classes.darkModeHelp}
					control={<Checkbox checked={sendCopy} onClick={emailClicked} />}
					label={'Email Copy'}
				/>


				{/* Date Field */}
				<DatePicker
        		    value={thruDate}
        		    onChange={(newVal) => {
						if (newVal === null) return;
						setThruDate(newVal);
					}}
					maxDate={Date.parse(endDate) < now.valueOf() ? moment(endDate) : moment()}
					minDate={moment(startDate)}
					renderInput={(params) => <TextField {...params} label={"Through Date"} sx={{maxWidth: '175px'}}/>}
        		/>
				</Alignment>

				{/* Notes Field */}
				<TextField
					value={notes}
					label={'Notes for the email request (Optional)'}
					onChange={(event) => setNotes(event.target.value)}
					fullWidth
					multiline
					rows={4}
					style={{ marginBottom: "10px", marginTop: '10px', width: "-webkit-fill-available" }}
					inputProps={{maxLength:250}}
				></TextField>

				<TextField
					value={workNote}
					label={'Notes for the SO summary (Optional)'}
					onChange={(event) => setWorkNote(event.target.value)}
					fullWidth
					multiline
					rows={4}
					style={{ marginBottom: "10px", width: "-webkit-fill-available" }}
					inputProps={{maxLength:250}}
				></TextField>

			</DialogContent>

			{deviceInfo.isSm ?
			/* Desktop */
			<DialogActions>
				<Button startIcon={<EmailIcon/>} onClick={() => onSubmit('email-request')} disabled={emailDisabled || requestLoading || contactPreference === EMAIL_DISABLED_VALUE}>{'Email Request'}</Button>
				<Button startIcon={<PdfIcon/>} onClick={() => onSubmit('email-pdf')} disabled={emailDisabled || requestLoading || contactPreference === PDF_DISABLED_VALUE} sx={{marginRight: 'auto'}}>{'Email PDF'}</Button>
				<Button onClick={onSignatureDialogClose} color={'secondary'} disabled={requestLoading}>Cancel</Button>
        	  	<Button onClick={() => onSubmit('sign-now')} disabled={signDisabled || requestLoading}>{'Sign Now'}</Button>
			</DialogActions>
			:
			/* Mobile */
			<>
				<Alignment column sx={{marginBottom: '10px', marginLeft: '10px'}}>
					<Button startIcon={<EmailIcon/>} onClick={() => onSubmit('email-request')} disabled={emailDisabled || contactPreference === EMAIL_DISABLED_VALUE} sx={{marginRight: 'auto', marginBottom: '10px'}}>{'Email Request'}</Button>
					<Button startIcon={<PdfIcon/>} onClick={() => onSubmit('email-pdf')} disabled={emailDisabled || contactPreference === PDF_DISABLED_VALUE} sx={{marginRight: 'auto'}}>{'Email PDF'}</Button>
				</Alignment>
				<Alignment rowReverse sx={{marginBottom: '10px'}}>
					<Button onClick={() => onSubmit('sign-now')} disabled={signDisabled} sx={{marginRight: '10px'}}>{'Sign Now'}</Button>
					<Button onClick={onSignatureDialogClose} color={'secondary'} sx={{marginRight: '10px'}} disabled={requestLoading}>Cancel</Button>
				</Alignment>
			</>
			}
		</Dialog>	
		</>
	);
};