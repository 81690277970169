import { Typography, Paper, PaperProps, createFilterOptions, AutocompleteProps, ListSubheader, FilterOptionsState } from '@mui/material';
import { VirtualizedListboxPopper, VirtualizedListboxAdpater, VirtualizedListboxChildComponentProps } from '@imas/utils/virtualization';
import { makeStyles } from '@imas/styles';
import { forwardRef } from 'react';

type AutocompleteVirtualizerParams<T> = Required<NonNullable<Pick<AutocompleteProps<T, false, false, false, "div">, "filterOptions" | "disableListWrap" | "PopperComponent" | "ListboxComponent" | "PaperComponent" | "renderOption" | "renderGroup" | "getOptionLabel">>>;

const useObjectStyles = makeStyles<{ dense?: boolean; }>()((_, { dense }) => ({
	item: {
		padding: dense ? '0 8px!important' : undefined,
		display: 'flex',
		flexDirection: 'row',
	},
	itemText: {
		fontSize: dense ? '0.875rem' : undefined,
	},  
	disabled: {
		color: 'text.disabled',
	}
}));

interface ObjectListCol<T extends { id: int; }> {
	field: keyof T;
	label?: string;
	width?: int;
	getValue?: (item: T) => string;
};

export interface CreateObjectAutocompleteVirtualizerProps<T extends { id: int; }> {
	columns: ObjectListCol<T>[];
	getLabel: (item: T) => string;
	isDisabled?: (item: T) => boolean;
	filter?: (options: T[], state: FilterOptionsState<T>) => T[];
	hideLabels?: boolean;
	hideDisabledOptions?: boolean;
	dense?: boolean;
};

const DEFAULT_COL_WIDTH = 50;

export const createObjectAutocompleteVirtualizer = <T extends { id: int; }>(props: CreateObjectAutocompleteVirtualizerProps<T>): AutocompleteVirtualizerParams<T> => {
	const {  columns, getLabel, isDisabled, filter, hideLabels, hideDisabledOptions, dense } = props;
	const listboxPadding = dense ? 0 : 8;
	const itemSize = dense ? 24 : undefined;
	const totalWidth =  columns.map(x => x.width ?? DEFAULT_COL_WIDTH).reduce((a, b) => a + b, 0);

	const ObjectListboxAdapter = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
		const { classes, cx } = useObjectStyles({ dense });

		return (
			<>
				{/* Column Labels */}
				{hideLabels ? null : (
					<ListSubheader sx={{ lineHeight: '24px', padding: '0px', display: 'flex', flexDirection: 'row' }}>
						{columns.map((col, index) => (
							<div style={{ marginLeft: index === 0 ? '8px' : undefined, width: col.width, fontWeight: 'bold' }}>{col.label ?? col.field}</div>
						))}
					</ListSubheader>
				)}

				<VirtualizedListboxAdpater 
					{...props} 
					ref={ref}
					listboxPadding={listboxPadding}
					itemSize={itemSize}
					renderer={({ data, index, style }: VirtualizedListboxChildComponentProps<T>) => {
						const row = data[index];
						const disabled = isDisabled ? isDisabled(row.option) : !!((row.option as { disabled?: any; })?.disabled);
						
						return (
							<div 
								{...row.props}
								className={cx(row.props?.className, classes.item)}
								style={{ ...style, boxSizing: 'content-box', width: totalWidth, top: (style.top as number) + listboxPadding }}
							>
								{/* Column Values */}
								{columns.map(col => (
									<Typography 
										noWrap
										className={cx(classes.itemText, { [classes.disabled]: disabled })}
										style={{ width: col.width ?? DEFAULT_COL_WIDTH }}
									>{col.getValue ? col.getValue(row.option) : row.option[col.field]}</Typography>
								))}
							</div>
						);
					}}
				/>
			</>
		);
	});

	const IdNamePaper = forwardRef<HTMLDivElement, PaperProps>((props, ref) => {
		return (
			<Paper 
				{...props} 
				ref={ref} 
				style={{ minWidth: totalWidth + 24 }}
			/>
		);
	});

	const objectFilter = filter ? filter : createFilterOptions<T>({ stringify: (x) => columns.map(col => x[col.field]).join(' ') });
	const objectDisabledFilter: typeof objectFilter = hideDisabledOptions && isDisabled ? (options, state) => objectFilter(options.filter(x => !isDisabled(x)), state) : objectFilter;
	
	return {
		disableListWrap: true,
		filterOptions: objectDisabledFilter,
		PopperComponent: VirtualizedListboxPopper,
		ListboxComponent: ObjectListboxAdapter,
		PaperComponent: IdNamePaper,
		renderOption: (props, option) => ({ props, option }),
		renderGroup: (params) => params,
		getOptionLabel: getLabel,
	};
};