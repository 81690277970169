import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApi, useLoadApi } from '@imas/api';
import { Alignment, LabeledItem, Spacer } from '@imas/components/layout';
import { MuiFormSubmit, MuiFormSubmitProps, useMuiFormRef } from '@imas/mui-form';
import { isPopup, openPopup } from '@imas/utils/misc';
import { ResourceCollaboratorGroup, RESOURCES } from '@imas/utils/resource';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { ExitToApp, Save as SaveIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Link as LinkIcon, LinkOff as LinkOffIcon, LockOpen as LockOpenIcon, Lock as LockIcon, Add as AddIcon, FindInPage as FindInPageIcon } from '@mui/icons-material';
import { Button, Paper, Typography } from '@mui/material';
import { useNavigate, Navigate, useParams } from 'react-router-dom';
import { ClientPurchaseOrder, CreateClientPurchaseOrder, CreateClientPurchaseOrderRevision, GetClient, GetClientPurchaseOrder, GetPoFilePath, TClientPurchaseOrderForm, UpdateClientPurchaseOrder } from '@imas/api/client';
import moment from 'moment';
import { ClientPurchaseOrderForm } from './ClientPurchaseOrderForm/ClientPurchaseOrderForm';
import { PurchaseOrderReferencesGrid } from './ClientPurchaseOrderForm/ClientPurchaseOrderFormComponents/PurchaseOrderReferenceGrid';
import { TextDialog } from '@imas/components/dialogs';
import { useClientPurchaseOrders } from '@imas/api/data-hooks';
import { openClientPurchaseOrderReportViewer } from './ClientPurchaseOrderReportViewer';


/**
 *  Popup page that handles creation and editing of client purchase orders.
 */
export const openPurchaseOrderEditor = (id: int | null, clientId: int): Window | null => {
	return openPopup(`${IMAS.PAGES.CLIENTS.PURCHASE_ORDERS()}/${clientId}/${id ?? 'new'}`, { name: 'Purchase Order Editor', height: 650, width: 1250, top: window.screen.height * .1, left: window.screen.width *.1});
};

export const ClientPurchaseOrderEditor = () => {
	//page title
    document.title = 'IMAS - Purchase Order Editor';

    //url params
    const {id, clientid} = useParams();

    //parse id from string to int
    const clientPONumId = useMemo(() => {
        if (!id) return undefined;
        else if (id === 'new') return undefined;
        else return parseInt(id);
    }, [id]);

    //parse id from string to int
    const clientId = useMemo(() => {
        if (!clientid) return undefined;
        else return parseInt(clientid);
    }, [clientid]);

    //state variables
    const [editable, setEditable] = useState<boolean>(clientPONumId === undefined);
    const [revisionDialog, setRevisionDialog] = useState<boolean>(false);

	//use automatic snackbar
    const showSnackbar = useAutomaticSnackbar();

    //navigation
    const navigate = useNavigate();

    //use APIs
    const createPurchaseOrderApi = useApi(CreateClientPurchaseOrder);
    const updatePurchaseOrderApi = useApi(UpdateClientPurchaseOrder);
    const createRevision = useApi(CreateClientPurchaseOrderRevision);

    //useFormRef for purchaseOrder form
    const [form, onForm, formRef] = useMuiFormRef<TClientPurchaseOrderForm, ClientPurchaseOrder>();

    //get client purchase orders
	const [clientPurchaseOrders] = useClientPurchaseOrders(clientId ?? -1, { disabled: !clientId });

    //load the specified purchaseOrder if state is a number
    const { data: purchaseOrder, error: purchaseOrderError, clear: clearPurchaseOrder, call: refreshPurchaseOrder } = useLoadApi(GetClientPurchaseOrder, [clientPONumId ?? -1], [clientPONumId], { disabled: clientPONumId === undefined });

    //load the client
    const {data: client} = useLoadApi(GetClient, [clientId ?? -1], [clientId], {disabled: !clientId});

    //load the jobfile pathLocator
    const {data: filePath} = useLoadApi(GetPoFilePath, [clientId ?? -1], [clientId], {disabled: !clientId});


    //reset form to blank for a new item
    useEffect(() => {
        //clear any loaded purchaseOrder record
        clearPurchaseOrder();

        if (clientPONumId === undefined) {
            formRef.current?.reset({
                clientId: clientId,
                number: null,
                amount: 0,
                issuedDate: moment().toDate(),
                expirationDate: null,
                type: null,
                notes: null,
                discontinued: false,
            });
        }
    }, [id, clientId, clearPurchaseOrder, formRef]);

    //reset the form to editing the loaded purchaseOrder
    useEffect(() => {
        if (purchaseOrder === undefined) return;
        else {
            formRef.current?.reset({
                ...purchaseOrder,
                issuedDate: moment(purchaseOrder.issuedDate).toDate(),
                expirationDate:purchaseOrder.expirationDate !== null ? moment(purchaseOrder.expirationDate).toDate() : null,
            });
        }
    }, [purchaseOrder, formRef]);

    //create a new purchaseOrder record
    const createPurchaseOrder = useCallback(async (data: TClientPurchaseOrderForm) => {
        //progress notification
        const close = showSnackbar("Creating purchase order...", { variant: 'info', persist: true });
        
        try {
            //call API
            const record = await createPurchaseOrderApi(data);

            //close notification
            close();

            //return the new record
            return record;
        } catch (e) {
            //close notification & re-throw error
            throw close(e);
        }
    }, [createPurchaseOrderApi, showSnackbar]);

    //update a purchaseOrder record
    const updatePurchaseOrder = useCallback(async (purchaseOrderId: int, data: TClientPurchaseOrderForm) => {
        //progress notification
        const close = showSnackbar("Updating purchase order...", { variant: 'info', persist: true });
        
        try {
            //call API
            const record = await updatePurchaseOrderApi(purchaseOrderId, data);
            
            //close notification
            close();

            //return the updated record
            return record;
        } catch(e) {
            //close notification & re-throw error
            throw close(e);
        }
    }, [updatePurchaseOrderApi, showSnackbar]);

	//submit handler
	const onSubmit = useCallback<MuiFormSubmitProps<TClientPurchaseOrderForm, ClientPurchaseOrder>["onSubmit"]>(async (data) => {
		//check if a purchaseOrder record is loaded, if not create a new record
		if (!purchaseOrder) {
			return await createPurchaseOrder(data);
		} 
		//otherwise update the loaded record
		else {
			return await updatePurchaseOrder(purchaseOrder.id, data);
		};
	}, [purchaseOrder, createPurchaseOrder, updatePurchaseOrder]);

    const onEditClick = useCallback(() => {
        if (editable) {
            if (!form) return;
            form.submit(onSubmit).then(() => {
                setEditable(false);
            });
        }
        else {
            setEditable(true);
        }
    }, [editable, clientPONumId]);

    const onRevisionClick = useCallback(() => {
        if (!clientPONumId) return;
                                    
        setRevisionDialog(true);
    }, [clientPONumId, editable]);

    //close form if there was an error loading the PurchaseOrder
    if (purchaseOrderError !== undefined) {
		if (isPopup()) window.close();
		return <Navigate to={"/"} replace/>;
	}

    return (
        <Alignment column flex sx={{ margin: '10px' }}>
            <Alignment row flex>
                <Alignment column flex>
                    {/* Form */}
			    	<ClientPurchaseOrderForm
			    		key={clientPONumId ?? "new"}
			    		loading={(clientPONumId !== undefined && purchaseOrder === undefined) || !clientId}
                        clientId={clientId ?? -1}
                        client={client}
                        clientPONumId={clientPONumId ?? null}
                        purchaseOrder={purchaseOrder}
                        clientPurchaseOrders={clientPurchaseOrders}
                        editable={editable}
                        onEditClick={onEditClick}
                        onRevisionClick={onRevisionClick}
                        filePath={filePath}
			    		ref={onForm}
                        form={form}
			    	/>
			    </Alignment>

                <PurchaseOrderReferencesGrid purchaseOrderId={clientPONumId ?? undefined} />

            </Alignment>
			
            
            <Alignment rowReverse sx={{ marginTop: '10px', "& > *:not(:last-child)": { marginLeft: '10px' } }}>
                {/* Save & Exit Button (Only show in a popup.) */}
                {isPopup() ? (
					<MuiFormSubmit
						formRef={form}
						onSubmit={onSubmit}
						onSuccessfulSubmit={() => {
							//call window.close() to close the popup window
							window.close();
						}}
						startIcon={<ExitToApp/>}
					>{"Save & Exit"}</MuiFormSubmit>
				) : null}

				{/* Save Button */}
				<MuiFormSubmit
                    formRef={form}
                    onSubmit={onSubmit}
                    onSuccessfulSubmit={(newPO) => {
						//if a new record was just created then change to edit the new record
						if (!purchaseOrder) navigate(`${IMAS.PAGES.CLIENTS.PURCHASE_ORDERS()}/${newPO.clientId}/${newPO.id}`, {replace: true});
                        else setEditable(false);
					}}
                    startIcon={<SaveIcon/>}
                >{"Save"}</MuiFormSubmit>

                {/* View report pdf */}
				<Button
					variant={"contained"}
					color={"primary"}
					disabled={purchaseOrder === undefined}
					onClick={() => openClientPurchaseOrderReportViewer(purchaseOrder?.id ?? -1)}
					startIcon={<FindInPageIcon/>}
					sx={{marginRight: '140px'}}
				>{'Preview'}</Button>

			</Alignment>

            {/* Revision Dialog */}
            <TextDialog
                open={revisionDialog}
                label={'Enter Reason for Revision'}
                validator={(value) => {
                    if (value === '') return 'Please enter a reason.';
                    else return null;
                }}
                onCancel={() => {
                    setRevisionDialog(false);
                }}
                onConfirm={(value) => {
                    if (!clientPONumId) return;
                    createRevision(clientPONumId, value).then(() => {
                        refreshPurchaseOrder(true);
                        setEditable(true);
                    });
                    setRevisionDialog(false);
                }}
            />
		</Alignment>
	);
};