import { Fab, List, ListItemButton, ListItemText, Paper, TextField, Typography, useTheme, Zoom, FormControlLabel, Checkbox, Button, Divider } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useEffectApi, useLoadApi } from '@imas/api';
import { GetLightPoles, GetvLightPoles } from 'src/api/misc/light-pole';
import { GetServiceOrder, GetServiceOrderTrackingResult } from 'src/api/service-order/api';
import { Alignment, Spacer, LabeledItem } from '@imas/components/layout';
import LightPoleMeasurementFormDialog from './LightPoleMeasurementFormDialog/LightPoleMeasurementFormDialog';
import lightPoleMeasurementsStyles from './LightPoleMeasurementsStyles';
import { LightPoleMeasurement, vServiceOrderLightPole } from './LightPoleMeasurementUtils';
import { Add as AddIcon, NoPhotography as NoPhotographyIcon, Flag as FlagIcon, LocationOff as LocationOffIcon} from '@mui/icons-material';
import { GlobalStyles } from 'tss-react';
import moment, { Moment } from 'moment';
import { DateRange, DateRangePicker } from '@mui/lab';

const LightPoleMeasurements = () => {
    //page title
    document.title = "IMAS - Light Poles";

    //component styling
    const { classes, deviceInfo } = lightPoleMeasurementsStyles();

    //use theme and create transition durations for the FAB & Speed Dial
    const theme = useTheme();
    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    //use navigate & router params & query params
    const navigate = useNavigate();
    const { uniqueId, editorState } = useParams();
    const [queryParams] = useSearchParams();

    //date range to get records from
    const [dateRange, setDateRange] = useState<DateRange<Moment>>([moment(), moment()]); 

    //show all state
    const [showAll, setShowAll] = useState<boolean>(false);

	//hide flagged state
	const [hideFlagged, setHideFlagged] = useState<boolean>(false);

    //SO light poles
    const [lightPoles, setLightPoles] = useState<LightPoleMeasurement[] | undefined>();
	const [vlightPoles, setvLightPoles] = useState<vServiceOrderLightPole[] | undefined>();

    //get expense report given the report id
    const { data: serviceOrder } = useLoadApi(GetServiceOrderTrackingResult, [Number(uniqueId)], [uniqueId], { disabled: isNaN(Number(uniqueId)) });

    //get light poles given the uniqueID
    useEffectApi(GetLightPoles, [Number(uniqueId), showAll ? null : dateRange[0], showAll ? null : dateRange[1], hideFlagged], (lightPoles) => {
        setLightPoles(lightPoles);
    }, [uniqueId, dateRange, showAll, hideFlagged], { disabled: isNaN(Number(uniqueId)) });

	//get vlight poles given the params
    useEffectApi(GetvLightPoles, [Number(uniqueId), showAll ? null : dateRange[0], showAll ? null : dateRange[1], hideFlagged], (lightPoles) => {
        setvLightPoles(lightPoles);
    }, [lightPoles], { disabled: isNaN(Number(uniqueId)) });



    //get the editor state from the params
    const lightPoleEditorState = useMemo((): LightPoleMeasurement | null | undefined => {
        if (editorState === undefined) return undefined;

        if (editorState === "new") return undefined;

        if (editorState === "edit" || editorState === "view") {
            //try and parse the queryParams for the itemId
            const id = queryParams.get("id");

            if (id === null || isNaN(Number(id))) return null;

            //if expenseReportDetails is undefined return undefined
            if (lightPoles === undefined) return;

            //get the item for the id
            const expenseItem = lightPoles.find(x => x.id === Number(id));

            if (expenseItem === undefined) return null;

            return expenseItem;
        }

        return null;
    }, [editorState, lightPoles]);

    //onClick for expense items
    const onItemClick = useCallback((item: LightPoleMeasurement) => {
        
        //open the editor
        navigate(`edit?id=${item.id}`);
    }, [navigate]);

    //if expenseReportId is not a valid number or expense report is null then navigate back to the Expense Report summary page, if the expenseEditorState is null then remove the new/edit/view or other invalid syntax
    if (isNaN(Number(uniqueId)) || serviceOrder === null || lightPoleEditorState === null) {
        navigate(-1);
        return null; 
    }

    //common props
    const commonProps: {
        autoComplete: string,
        variant: "outlined",
        size: "small"
    } = {
        autoComplete: 'off',
        variant: "outlined",
        size: "small"
    };

    return (
        <Alignment column flex overflowHidden style={{ maxWidth: '600px' }}>
            {/* Header */}
            <Paper square>
                {serviceOrder ? (
                    <Typography variant={"h6"} className={classes.jobNoText}>{`${serviceOrder.client} ${serviceOrder.siteNum ?? 'X'}${serviceOrder.depCode}-${serviceOrder.pronum} (${serviceOrder.serviceOrderId}) Light Poles`}</Typography>
                ) : null}

                {/* Date Range Selector */}
                <DateRangePicker
                    startText={"From Date"}
                    endText={"To Date"}
                    value={dateRange}
                    onChange={(newValue) => {
                        setDateRange(newValue);
                    }}
                    renderInput={(startProps, endProps) => (
                      <Alignment row style={{ margin: '5px 10px' }}>
                        <TextField {...startProps} {...commonProps}/>
                        <Spacer horizontal/>
                        <TextField {...endProps} {...commonProps}/>

                      </Alignment>
                    )}
                />

				<Alignment row>
					{/* Show All Check Box */}
					<FormControlLabel
                	    style={{ minWidth: '110px', marginLeft: '5px' }}
                	    label={"Show All"}
                	    control={<Checkbox checked={showAll} onChange={() => setShowAll(!showAll)}/>}
                	/>

					{/* Hide Flagged Check Box */}
                	<FormControlLabel
                	    style={{ minWidth: '110px', marginLeft: '5px' }}
                	    label={"Hide Flagged"}
                	    control={<Checkbox checked={hideFlagged} onChange={() => setHideFlagged(!hideFlagged)}/>}
                	/>
				</Alignment>

            </Paper>

            <List className={classes.itemList} dense>
                {lightPoles ? vlightPoles ? lightPoles.map(lightPole => (
                    <ListItemButton key={lightPole.id} onClick={() => onItemClick(lightPole)} divider>
                        <ListItemText
                            //primary={`${moment(lightPole.date).format("M/D/YYYY")} (${lightPole.mapNumber ?? "??"}.${lightPole.poleNumber ?? "??"}) ${lightPole.address ?? "None"}`}
							primary={vlightPoles.find(x => x.id === lightPole.id)?.lightpoleShortDescription}
                            primaryTypographyProps={{ color: "textPrimary" }}
                        />
                        { lightPole.latitude === null ? <LocationOffIcon style={{color: (theme.palette.mode === 'light' ? '000' : 'FFFFFF') }}/> : null }
						{ lightPole.pathLocator === null ? <NoPhotographyIcon style={{color: (theme.palette.mode === 'light' ? '000' : 'FFFFFF') }}/> : null }
						{ lightPole.solightpoletobereviewed ? <FlagIcon color='secondary'/> : null }
                    </ListItemButton>
                )) : <Typography variant={"body1"} color={"textPrimary"} margin={'10px 0 0 10px'}>{"Loading"}</Typography> : <Typography variant={"body1"} color={"textPrimary"} margin={'10px 0 0 10px'}>{"Loading"}</Typography>}
            </List>

			{vlightPoles ? 
			<Alignment row sx={{ margin: 'auto 0px 0px 0px' }}>
					<Spacer horizontal/>
					<LabeledItem label={'Flagged:'} item={(vlightPoles?.filter(x => x.flagged).length)?.toString()} row />
					<Spacer horizontal/>
					<LabeledItem label={'Pole 1:'} item={((vlightPoles?.filter(x => x.pole1)).length)?.toString()} row />
					<Spacer horizontal/>
					<LabeledItem label={'Pole 2:'} item={(vlightPoles?.filter(x => x.pole2).length)?.toString()} row />
					<Spacer horizontal/>
					<LabeledItem label={'Total:'} item={(vlightPoles?.length.toString())} row />
			</Alignment>
			: null}

            {/* Show + Light Pole button on Desktop */}
            {deviceInfo.isMd ? (
                <Button
                    sx={{ margin: '5px 5px 10px 5px' }}
                    color={"primary"}
                    variant={"contained"}
                    startIcon={<AddIcon/>}
                    onClick={() => navigate("new")}
                >{"Light Pole"}</Button>
            ) : null}

            {/* Global Styling to Move Snackbar container Up */}
            {serviceOrder !== undefined ? <GlobalStyles styles={{ ".snackbarRootOverride": { marginBottom: '70px' } }}/> : null}
				
            {/* Add Fab */}
            <Zoom
                in={serviceOrder !== undefined && !deviceInfo.isMd}
                timeout={transitionDuration}
                unmountOnExit
            >
                <Fab
                    variant={"extended"}
                    color={"primary"}
                    className={classes.fab}
                    onClick={() => navigate("new")}
                ><AddIcon/>{"Light Pole"}</Fab>
            </Zoom>
            
            {/* Measurement Form Dialog */}
            {serviceOrder ? (
                <LightPoleMeasurementFormDialog
                    open={lightPoleEditorState !== undefined || editorState === "new"}
                    uniqueId={serviceOrder.uniqueId}
                    state={editorState === "new" ? null : lightPoleEditorState}
                    pronum={serviceOrder.pronum ?? []}
                    onSave={(lightPole) => { 
                        //update the details
                        setLightPoles(x => {
                            if (!x) return undefined;

                            if (x?.some(y => y.id === lightPole.id)) return x.map(y => y.id === lightPole.id ? lightPole : y);
                            else return [ ...x, lightPole];
                        });
                    }}
                    onCancel={() => navigate(-1)}
                />
            ) : null}
        </Alignment>
    );
};

export default LightPoleMeasurements;
