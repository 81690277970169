import { TextField, Typography, MenuItem, Divider, Autocomplete, Checkbox, FormControlLabel, Button } from '@mui/material';
import { DatePicker } from '@mui/lab';
import React, { useState, useMemo, useEffect, useCallback, forwardRef, useRef, useImperativeHandle } from 'react';
import { LightPoleMeasurementFormData } from 'src/api/types/form/LightPoleMeasurementFormData';
import { Alignment, Spacer } from '@imas/components/layout';
import { CameraAlt as CameraAltIcon } from '@mui/icons-material';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { NumberTextField } from '@imas/components/inputs';
import { MyLocation as MyLocationIcon } from '@mui/icons-material';
import lightPoleMeasurementFormStyles from './LightPoleMeasurementFormStyles';
import { GOOGLE_MAPS_API_KEY } from '@imas/constants';
import { Circle, GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import moment from 'moment';
import { useApi, useLoadApi } from '@imas/api';
import { GetGeoLocationInformation } from 'src/api/external/api';
import { DownloadFile } from '@imas/api/files';
import { FileTables } from '@imas/api/files/types';
import Resizer from "react-image-file-resizer";
import { GetLightPoleRemainingWallThickness } from 'src/api/misc/light-pole';
import { GetLightPoleNumPronum } from 'src/api/misc/light-pole';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

//MobileTimeSheetFormProps interface
interface LightPoleMeasurementFormProps {
    data?: LightPoleMeasurementFormData,
    loading?: boolean,
    saving?: boolean,
    pronum: int,
}

//MobileTimeSheetFormRef interface
export interface LightPoleMeasurementFormRef {
    isValid: boolean,
    hasChanges: boolean,
    data: React.MutableRefObject<LightPoleMeasurementFormData>
}

const getDefaultMobileTimeSheetFormData = (): LightPoleMeasurementFormData => ({
    date: null,
    refNumber: null,
    file: null,
    latitude: null,
    longitude: null,
    accuracy: null,
    mapNumber: null,
    poleNumber: null,
	angle: null,
	address: null,
	city: null,
	state: null,
	zip: null,
	baseThickness1: null,
    baseThickness2: null,
    baseThickness3: null,
    baseThickness4: null,
    poleCorrosion1: null,
    poleCorrosion2: null,
    poleCorrosion3: null,
    poleCorrosion4: null,
	poleNomThickness1: null,
	poleNomThickness2: null,
	poleNomThickness3: null,
	poleNomThickness4: null,
    impactTest: null,
	charge: null,
	groundCondition: null,
    paintCondition: null,
    notes: null,
    nonAccessible: false,
	junctionBox: false,
	solightpolenomthickness: null,
	solightpoletobereviewed: false,
	solightpoletobereviewednotes: null,
	arterial: false,
});

const defaultFormData = getDefaultMobileTimeSheetFormData();

const LightPoleMeasurementForm = React.memo(forwardRef<LightPoleMeasurementFormRef, LightPoleMeasurementFormProps>((props, ref) => {
    useEffect(() => { console.log("FORM MOUNTED"); return () => console.log("FORM UNMOUNTED"); }, []);

    //destructure props
    let { loading, saving } = props;

    const {editorState } = useParams();
    const [queryParams] = useSearchParams();

    const idstr = queryParams.get("id");

    const [id, setId] = useState(-1);
    
    console.log(idstr);

    useEffect(() => {
        setId(parseInt(idstr ?? "-1"));
    },[idstr]);

    console.log(id);
    console.log(editorState);
    //set initialData to defaultClientFormData if no data was provided
    const initialData = props.data ?? defaultFormData;

    //component styling
    const { classes } = lightPoleMeasurementFormStyles();

    //using automatic snackbar
    const showSnackbar = useAutomaticSnackbar();

    //use APIs
    const getFile = useApi(DownloadFile);
    const getGeoLocationInfo = useApi(GetGeoLocationInformation);

    //imagePreview var
    const [imagePreview, setImagePreview] = useState<string | null>(null);

    //form data
    const [data, setData] = useState<LightPoleMeasurementFormData>({ ...getDefaultMobileTimeSheetFormData(), date: moment() });
	//const [wallThickness, setWallThickness] = useState<number | null>(null);

	//Wall Thickness API
	const {data: wallThickness} = useLoadApi(GetLightPoleRemainingWallThickness, [{...data, poleNumber:3, date: moment(), file: null, mapNumber: null, }], [data.impactTest, data.charge, data.poleNomThickness1, data.poleNomThickness2, data.poleNomThickness3, data.poleNomThickness4, data.poleCorrosion1, data.poleCorrosion2, data.poleCorrosion3, data.poleCorrosion4, data.baseThickness1, data.baseThickness2, data.baseThickness3, data.baseThickness4], {debounce:250});
    const { data: LightPoleNums} = useLoadApi(GetLightPoleNumPronum, [props.pronum], [props.pronum]);

    //form data ref
    const dataRef = useRef<LightPoleMeasurementFormData>(data);

    const defaultAngles = [90];

    //run when data changes
    useEffect(() => {
        //update data ref
        dataRef.current = { 
            ...data,
        };
    }, [data]);

    //update form data when initialData changes
    useEffect(() => {
        //reset image preview when initialData changes
        setImagePreview(null);

        if (initialData) {
            setData(Object.assign({}, initialData.date === null ? { ...initialData, date: moment() } : initialData));
        } else {
            setData(Object.assign({}, { ...getDefaultMobileTimeSheetFormData(), date: moment() }));
        }
    }, [initialData, loading]);

    //function to update latitude and longitude
    const updateLatLong = useCallback((lat: double, long: double, acc: double) => {
        //update lat long
        setData(x => ({ 
            ...x,
            latitude: lat,
            longitude: long,
            accuracy: acc,
        }));

        //update address info
        getGeoLocationInfo(lat, long)
        .then(response => {
            if (response === null) return showSnackbar("Failed to load address for location.", { variant: "error" });

            //get first result
            const location = response.results.filter(x => x.types.includes("street_address") || x.types.includes("premise") || x.types.includes("establishment"))[0];
            if (location === undefined) return;

            //try and get address, city, state, and zip
            const streetNumber = location.address_components.find(x => x.types.includes("street_number"))?.short_name;
            const streetName = location.address_components.find(x => x.types.includes("route"))?.short_name;
            const city = location.address_components.find(x => x.types.includes("locality"))?.short_name;
            const state = location.address_components.find(x => x.types.includes("administrative_area_level_1"))?.short_name;
            const zip = location.address_components.find(x => x.types.includes("postal_code"))?.short_name;

            //update address info
            setData(x => ({ 
                ...x,
                address: streetNumber && streetName ? `${streetNumber} ${streetName}` : null,
                city: city ?? null,
                state: state ?? null,
                zip: zip ?? null,
            }));
        })
        .catch(() => {});
    }, [getGeoLocationInfo]);

    //get image preview when data.file changes
    useEffect(() => {
        if (data.file === null || data.file === undefined) {
            return setImagePreview(null);
        } else if (typeof data.file === "string") {
            getFile(FileTables.JobFiles, data.file)
            .then(file => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const result = e.target?.result;
                    if (typeof result === "string") setImagePreview(result);
                    else setImagePreview(null);  
                };
                reader.readAsDataURL(file);
            });
        } else {
            const reader = new FileReader();
            reader.onload = (e) => {
                const result = e.target?.result;
                if (typeof result === "string") setImagePreview(result);
                else setImagePreview(null);  
            };
            reader.readAsDataURL(data.file);
        }

    }, [data.file]);

    //handle change func
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setData({ ...data, [e.target.name]: e.target.value === "" ? null : e.target.value });
    };

    //checks if the form is valid
    const formIsValid = (): boolean => {
        if (data.date === null) return false;
        if ((data.mapNumber ?? "") === "" || (data.mapNumber ?? "").length > 20) return false;
        if (data.poleNumber === null) return false;
        if ((data.address ?? "") === "" || (data.address ?? "").length > 255) return false;
        if ((data.city ?? "").length > 255) return false;                                                                                                                                                                                                       
        if ((data.state ?? "").length > 255) return false;
        if ((data.zip ?? "").length > 255) return false;
        if (data.poleNomThickness1 === null) return false;
        if ((data.charge ?? "") === "") return false;
        if ((data.groundCondition ?? "") === "") return false;
        if ((data.paintCondition ?? "") === "") return false;
        if ((data.impactTest ?? "") === "") return false;
        if ((data.notes ?? "").length > 255) return false;
        if ((data.groundCondition ?? "").length > 50) return false;
        if (LightPoleNums === undefined) return false;
        if((LightPoleNums.filter((x) => x.soLightpoleNumber === data.poleNumber && x.id !== id).length) > 0) return false;
        return true;
    };

    //check if there are unsaved changes in the form
    const hasUnsavedChanges = (): boolean => {
        const { file: dFle, date: dDate, ...reducedData } = data;
        const { file: iFile, date: iDate, ...reducedInitialData } = initialData;

        //check for any differences between initData & data
        if (JSON.stringify(reducedData) !== JSON.stringify(reducedInitialData)) return true;
        
        return false;
    };
    
    //get memoized value of formIsValid and hasUnsavedChanges
    const isValid = useMemo(formIsValid, [data]);
    const hasChanges = useMemo(hasUnsavedChanges, [initialData, data]);

    //populate ref data
    useImperativeHandle(ref, () => ({
        isValid,
        hasChanges,
        data: dataRef
    }), [isValid, hasChanges]);
    
    if (loading) {
        return null;
    };

	/* const constraints = {
		video: true
	};
	const video = document.querySelector('video');
	function handleSuccess(stream: MediaProvider) {
		if (video === null) return;
		video.srcObject = stream;
	}
	function handleError(error: any) {
	console.error('Reeeejected!', error);
	}
	let userMedia = navigator.mediaDevices.getUserMedia(constraints).
	then(handleSuccess).catch(handleError); */

    return (
        <Alignment column flex hidden={saving}>
			
			{/* Notes Field */}
			{data.solightpoletobereviewed ? 
				<>
            		<TextField
            		    label={"Review Notes"}
            		    name={"review notes"}
						disabled={true}
            		    multiline
            		    minRows={3}
            		    value={data.solightpoletobereviewednotes ?? ""}
            		    //onChange={handleChange}
            		    error={(data.solightpoletobereviewednotes ?? "").length > 255}
            		    helperText={(data.solightpoletobereviewednotes ?? "").length > 255 ? "Too long." : undefined}
            		/>

            		<Spacer vertical/>
            		<Divider/>
            		<Spacer vertical/>
				</>
			: null}

            <DatePicker
                label={"Date"}
                value={data.date} 
                onChange={(newVal) => setData(x => ({ ...x, date: newVal }))}
                renderInput={(params) => (
                    <TextField 
                        {...params} 
                        required
                        error={data.date === null} 
                        inputProps={{ ...params.inputProps, tabindex: 1 }} 
                        autoFocus 
                    />
                )}
            />
            <Spacer vertical/>

            {/* Date, Map #, & Pole #  */}
            <Alignment row>
                {/* Map # */}
                <TextField
                    required
                    label={"Map #"}
                    name={"mapNumber"}
                    value={data.mapNumber ?? ""}
                    onChange={handleChange}
                    error={(data.mapNumber ?? "").length > 20 || (data.mapNumber ?? "") === ""}
                    helperText={(data.mapNumber ?? "").length > 20 ? "Too long." : undefined}
                    inputProps={{ tabindex: 1 }}
                />
                <Spacer horizontal/>

                {/* Pole # */}
                <NumberTextField
                    required
                    label={"Pole #"}
                    value={data.poleNumber ?? ""}
                    onValueChange={(values) => {
                        setData(x => ({ ...x, poleNumber: values.floatValue !== undefined ? Math.min(values.floatValue, 2_147_483_647) : null }));
                    }}
                    decimalScale={2}
                    inputProps={{ inputMode: "decimal", tabIndex: 1 }}
                    allowNegative={false}
                    error={data.poleNumber === null || (LightPoleNums?.filter((x) => x.soLightpoleNumber === data.poleNumber && x.id !== id).length ?? -1) > 0}
                    helperText={(LightPoleNums?.filter((x) => x.soLightpoleNumber === data.poleNumber && x.id !== id).length ?? -1) > 0 ? "Pole # has already been entered." : undefined}

                />
            </Alignment>
            <Spacer vertical/>

            {/* Ref/Serial # */}
            <TextField
                label={"Ref/Serial #"}
                name={"refNumber"}
                value={data.refNumber ?? ""}
                onChange={handleChange}
                inputProps={{ tabindex: 1 }}
            />
            <Spacer vertical/>

            {/* Get Location Button */}
            <Button
                color={"primary"}
                variant={"contained"}
                startIcon={<MyLocationIcon/>}
                tabIndex={1}
                onClick={() => {
                    //get user location
                    navigator.geolocation.getCurrentPosition(position => {
                        //update the location data data
                        updateLatLong(position.coords.latitude, position.coords.longitude, position.coords.accuracy);
                    }, () => {
                        //give error to the user
                        showSnackbar("Unable to get your location.", { variant: "error" }, false);
                    }, { enableHighAccuracy: true, maximumAge: 0 });
                }}
            >{data.latitude === null || data.longitude === null ? "Capture Location" : "Re-Capture Location"}</Button>
            <Spacer vertical/>

            {/* Google Maps Preview of Location */}
            { data.latitude && data.longitude ? (
                <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                    <GoogleMap
                        mapContainerStyle={{ width: '-webkit-fill-available', height: '300px' }}
                        center={{ lat: data.latitude, lng: data.longitude }}
                        zoom={18}
                    >
                        { /* Child components, such as markers, info windows, etc. */ }
                        {data.accuracy ? (
                            <Circle
                                center={{ lat: data.latitude, lng: data.longitude }}
                                radius={data.accuracy}
                            />
                        ) : null}
                        <Marker
                            position={{ lat: data.latitude, lng: data.longitude }}
                            draggable
                            onDragEnd={(e) => {
                                const coords = e.latLng?.toJSON();
                                if (coords === undefined) return;

                                //update lat/long/acc when drag ends
                                updateLatLong(coords.lat, coords.lng, 0);
                            }}
                        />
                    </GoogleMap>
                </LoadScript>
            ) : <Typography variant={"body1"}>{"No Location"}</Typography>}
            <Spacer vertical/>

            {/* Address */}
            <TextField
                required
                label={"Address"}
                name={"address"}
                value={data.address ?? ""}
                onChange={handleChange}
                error={(data.address ?? "").length > 255 || (data.address ?? "") === ""}
                helperText={(data.address ?? "").length > 255 ? "Too long." : undefined}
                inputProps={{ tabindex: 1 }}
            />
            <Spacer vertical/>

            {/* City State Zip  */}
            <Alignment row>
                {/* City */}
                <TextField
                    label={"City"}
                    name={"city"}
                    value={data.city ?? ""}
                    onChange={handleChange}
                    error={(data.city ?? "").length > 255}
                    helperText={(data.city ?? "").length > 255 ? "Too long." : undefined}
                    inputProps={{ tabindex: 1 }}
                />
                <Spacer horizontal/>

                {/* State */}
                <TextField
                    label={"State"}
                    name={"state"}
                    value={data.state ?? ""}
                    onChange={handleChange}
                    error={(data.state ?? "").length > 255}
                    helperText={(data.state ?? "").length > 255 ? "Too long." : undefined}
                    inputProps={{ tabindex: 1 }}
                />
                <Spacer horizontal/>

                {/* Zip */}
                <TextField
                    label={"Zip"}
                    name={"zip"}
                    value={data.zip ?? ""}
                    onChange={handleChange}
                    error={(data.zip ?? "").length > 255}
                    helperText={(data.zip ?? "").length > 255 ? "Too long." : undefined}
                    inputProps={{ tabindex: 1 }}
                />
            </Alignment>

			<Spacer vertical/>
			
			{/* Arterial Checkbox */}
			<FormControlLabel
                label={"On Arterial (Major) Road"}
                control={<Checkbox color={"primary"} checked={data.arterial} onChange={() => setData(x => ({ ...x, arterial: !x.arterial }))} />}
            />

            {/* Lat/Long Checkbox */}
			<FormControlLabel
                label={"Lat/Long Captured"}
                classes={{label: classes.latLongLabel}}
                control={<Checkbox disabled checked={data.latitude !== null} />}
            />

            {/* <Spacer vertical/> */}
            
            {/* <Alignment row> */}
                {/* Read only latitude */}
                {/* <TextField
                    label={'Latitude'}
                    name={'lat'}
                    value={data.latitude}
                    disabled={true}
                />

                <Spacer horizontal/> */}

                {/* Read only Longitude */}
                {/* <TextField
                    label={'Longitude'}
                    name={'long'}
                    value={data.longitude}
                    disabled={true}
                />

            </Alignment> */}

            <Spacer vertical/>
            <Divider/>
            <Spacer vertical/>

            {/* Nominal Thickness Measurements */}
            <Typography variant={"body1"}>{"Nominal Thickness (mils)"}</Typography>
            <Spacer vertical/>

            <Alignment row>
                {/* West */}
                <NumberTextField
                    label={"West"}
                    value={data.poleNomThickness4 ?? ""}
                    onValueChange={(values) => {
                        setData(x => ({ ...x, poleNomThickness4: values.floatValue !== undefined ? Math.min(values.floatValue, 32_000) : null }));
                    }}
                    decimalScale={0}
                    inputProps={{ inputMode: "numeric", tabIndex: 5 }}
                    allowNegative={false}
                    className={classes.centeredTextField}
                />
                <Spacer horizontal/>

                <Alignment column>
                    {/* North */}
                    <NumberTextField
                        label={"North"}
                        value={data.poleNomThickness1 ?? ""}
                        onValueChange={(values) => {
                            setData(x => ({ ...x, poleNomThickness1: values.floatValue !== undefined ? Math.min(values.floatValue, 32_000) : null }));
                        }}
                        decimalScale={0}
                        inputProps={{ inputMode: "numeric", tabIndex: 2 }}
                        allowNegative={false}
                        error={data.poleNomThickness1 === null}
                    />
                    <Spacer vertical/>

                    {/* South */}
                    <NumberTextField
                        label={"South"}
                        value={data.poleNomThickness3 ?? ""}
                        onValueChange={(values) => {
                            setData(x => ({ ...x, poleNomThickness3: values.floatValue !== undefined ? Math.min(values.floatValue, 32_000) : null }));
                        }}
                        decimalScale={0}
                        max={32_000}
                        inputProps={{ inputMode: "numeric", tabIndex: 4 }}
                        allowNegative={false}
                    />
                </Alignment>
                <Spacer horizontal/>

                {/* East */}
                <NumberTextField
                    label={"East"}
                    value={data.poleNomThickness2 ?? ""}
                    onValueChange={(values) => {
                        setData(x => ({ ...x, poleNomThickness2: values.floatValue !== undefined ? Math.min(values.floatValue, 32_000) : null }));;
                    }}
                    decimalScale={0}
                    max={32_000}
                    inputProps={{ inputMode: "numeric", tabIndex: 3 }}
                    allowNegative={false}
                    className={classes.centeredTextField}
                />
            </Alignment>
            <Spacer vertical/>
            <Divider/>
            <Spacer vertical/>

			{/* Angle */}
            <Autocomplete
				freeSolo
            
				size={"small"}
				options={[90]}
				fullWidth
				onInputChange={(e, value) => {
                    setData(x => ({ ...x, angle: parseFloat(value) !== undefined ? Math.min(parseFloat(value), 360) : null }));
                }}
				renderInput={(params) => <TextField  type={'number'} label={"Pole Angle"} {...params} />}
                inputMode={"decimal"}               
							/>
            <Spacer vertical/>


			{/* Junction Box Present Checkbox */}
            <FormControlLabel
                label={"Junction Box Present"}
                control={<Checkbox color={"primary"} checked={data.junctionBox} onChange={() => setData(x => ({ ...x, junctionBox: !x.junctionBox }))} />}
            />
            <Spacer vertical/>

            {/* Pole Type */}
            <TextField
                label={"Pole Type"}
                name={"charge"}
                value={data.charge ?? ""}
                required
                onChange={handleChange}
                SelectProps={{ inputProps: { tabIndex: 6 }}}
                error={(data.charge ?? "") === ""}
                select
            >
                <MenuItem value={"Fiberglass, Dug"}>{"Fiberglass, Dug"}</MenuItem>
                <MenuItem value={"Steel, Bolted, Covered"}>{"Steel, Bolted, Covered"}</MenuItem>
                <MenuItem value={"Steel, Bolted, Dug"}>{"Steel, Bolted, Dug"}</MenuItem>
                <MenuItem value={"Steel, Bolted, Rusted"}>{"Steel, Bolted, Rusted"}</MenuItem>
                <MenuItem value={"Steel, Bolted, Undug"}>{"Steel, Bolted, Undug"}</MenuItem>
                <MenuItem value={"Steel, Concrete, Rusted"}>{"Steel, Concrete, Rusted"}</MenuItem>
                <MenuItem value={"Steel, Concrete, Undug"}>{"Steel, Concrete, Undug"}</MenuItem>
                <MenuItem value={"Steel, Galvanized, Dug"}>{"Steel, Galvanized, Dug"}</MenuItem>
                <MenuItem value={"Steel, Galvanized, Undug"}>{"Steel, Galvanized, Undug"}</MenuItem>
                <MenuItem value={"Steel, Painted, No Tape, Dug"}>{"Steel, Painted, No Tape, Dug"}</MenuItem>
                <MenuItem value={"Steel, Painted, No Tape, Undug"}>{"Steel, Painted, No Tape, Undug"}</MenuItem>
                <MenuItem value={"Steel, Painted, Taped, Dug"}>{"Steel, Painted, Taped, Dug"}</MenuItem>
                <MenuItem value={"Steel, Painted, Taped, Undug"}>{"Steel, Painted, Taped, Undug"}</MenuItem>
                <MenuItem value={"Wood, Undug"}>{"Wood, Undug"}</MenuItem>
                
            </TextField>
            <Spacer vertical/>

            {/* Ground Condition */}
            <TextField 
                label={"Ground Condition"} 
                name={"groundCondition"}
                value={data.groundCondition ?? ""}
                required
                onChange={handleChange}
                SelectProps={{ inputProps: { tabindex: 6 } }} 
                error={(data.groundCondition ?? "") === ""} 
                select
            >
                <MenuItem value={"Grass"}>{"Grass"}</MenuItem>
                <MenuItem value={"Rocks"}>{"Rocks"}</MenuItem>
                <MenuItem value={"Shrubs"}>{"Shrubs"}</MenuItem>
                <MenuItem value={"Desert"}>{"Desert"}</MenuItem>
                <MenuItem value={"Concrete"}>{"Concrete"}</MenuItem>
                <MenuItem value={"Asphalt"}>{"Asphalt"}</MenuItem>
                <MenuItem value={"Pavers"}>{"Pavers"}</MenuItem>
            </TextField>
            <Spacer vertical/>

            {/* Paint Condition */}
            <TextField
                required
                label={"Paint Condition"}
                name={"paintCondition"}
                value={data.paintCondition ?? ""}
                onChange={handleChange}
                SelectProps={{ inputProps: { tabIndex: 6 }}}
                error={(data.paintCondition ?? "") === ""}
                select
            >
                <MenuItem value={"Good"}>{"Good"}</MenuItem>
                <MenuItem value={"Fair"}>{"Fair"}</MenuItem>
                <MenuItem value={"Poor"}>{"Poor"}</MenuItem>
            </TextField>
            <Spacer vertical/>

            {/* Non Accessible Checkbox */}
            <FormControlLabel
                label={"Non-Accessible"}
                control={<Checkbox color={"secondary"} checked={data.nonAccessible} onChange={() => setData(x => ({ ...x, nonAccessible: !x.nonAccessible }))}/>}
            />
            <Spacer vertical/>

            {/* Impact Test */}
            <TextField
                required
                label={"Impact Test"}
                name={"impactTest"}
                value={data.impactTest ?? ""}
                onChange={handleChange}
                SelectProps={{ inputProps: { tabIndex: 6 }}}
                error={(data.impactTest ?? "") === ""}
                select
            >
                <MenuItem value={"Pass"}>{"Pass"}</MenuItem>
                <MenuItem value={"Fail"}>{"Fail"}</MenuItem>
                <MenuItem value={"Not Required"}>{"Not Required"}</MenuItem>
            </TextField>
			<Spacer vertical/>

				{/* Base thickness for bolted or concrete poles */}
				{ ( (data.charge ?? "").includes('Bolt') || ((data.charge ?? "").includes('Concrete') )) ? 
					<>
						<Spacer vertical/>
            			<Divider/>
            			<Spacer vertical/>

            			{/* Base Thickness Measurements */}
            			<Typography variant={"body1"}>{"Base Thickness (mils)"}</Typography>
            			<Spacer vertical/>

            			<Alignment row>
            			    {/* West */}
            			    <NumberTextField
            			        label={"West"}
            			        value={data.baseThickness4 ?? ""}
            			        onValueChange={(values) => {
            			            setData(x => ({ ...x, baseThickness4: values.floatValue !== undefined ? Math.min(values.floatValue, 32_000) : null }));
            			        }}
            			        decimalScale={0}
            			        max={32_000}
            			        inputProps={{ inputMode: "numeric", tabIndex: 10 }}
            			        allowNegative={false}
            			        className={classes.centeredTextField}
            			    />
            			    <Spacer horizontal/>
							
            			    <Alignment column>
            			        {/* North */}
            			        <NumberTextField
            			            label={"North"}
            			            value={data.baseThickness1 ?? ""}
            			            onValueChange={(values) => {
            			                setData(x => ({ ...x, baseThickness1: values.floatValue !== undefined ? Math.min(values.floatValue, 32_000) : null }));
            			            }}
            			            decimalScale={0}
            			            max={32_000}
            			            inputProps={{ inputMode: "numeric", tabIndex: 7 }}
            			            allowNegative={false}
            			        />
            			        <Spacer vertical/>
								
            			        {/* South */}
            			        <NumberTextField
            			            label={"South"}
            			            value={data.baseThickness3 ?? ""}
            			            onValueChange={(values) => {
            			                setData(x => ({ ...x, baseThickness3: values.floatValue !== undefined ? Math.min(values.floatValue, 32_000) : null }));
            			            }}
            			            decimalScale={0}
            			            max={32_000}
            			            inputProps={{ inputMode: "numeric", tabIndex: 9 }}
            			            allowNegative={false}
            			        />
            			    </Alignment>
            			    <Spacer horizontal/>
								
            			    {/* East */}
            			    <NumberTextField
            			        label={"East"}
            			        value={data.baseThickness2 ?? ""}
            			        onValueChange={(values) => {
            			            setData(x => ({ ...x, baseThickness2: values.floatValue !== undefined ? Math.min(values.floatValue, 32_000) : null }));
            			        }}
            			        decimalScale={0}
            			        max={32_000}
            			        inputProps={{ inputMode: "numeric", tabIndex: 8 }}
            			        allowNegative={false}
            			        className={classes.centeredTextField}
            			    />
            			</Alignment>
					</>
				: null 
				}	

            <Spacer vertical/>
            <Divider/>
            <Spacer vertical/>

            {/* Base Thickness Measurements */}
            <Typography variant={"body1"}>{"External Corrosion (1/64 inch)"}</Typography>
            <Spacer vertical/>

            <Alignment row>
                {/* West */}
                <NumberTextField
                    label={"West"}
                    value={data.poleCorrosion4 ?? ""}
                    onValueChange={(values) => {
                        setData(x => ({ ...x, poleCorrosion4: values.floatValue !== undefined ? Math.min(values.floatValue, 32_000) : null }));
                    }}
                    decimalScale={0}
                    max={32_000}
                    inputProps={{ inputMode: "numeric", tabIndex: ((data.charge ?? "").includes('Bolt') ? 14 : 10) }}
                    allowNegative={false}
                    className={classes.centeredTextField}
                />
                <Spacer horizontal/>

                <Alignment column>
                    {/* North */}
                    <NumberTextField
                        label={"North"}
                        value={data.poleCorrosion1 ?? ""}
                        onValueChange={(values) => {
                            setData(x => ({ ...x, poleCorrosion1: values.floatValue !== undefined ? Math.min(values.floatValue, 32_000) : null }));
                        }}
                        decimalScale={0}
                        max={32_000}
                        inputProps={{ inputMode: "numeric", tabIndex: ((data.charge ?? "").includes('Bolt') ? 11 : 7) }}
                        allowNegative={false}
                    />
                    <Spacer vertical/>

                    {/* South */}
                    <NumberTextField
                        label={"South"}
                        value={data.poleCorrosion3 ?? ""}
                        onValueChange={(values) => {
                            setData(x => ({ ...x, poleCorrosion3: values.floatValue !== undefined ? Math.min(values.floatValue, 32_000) : null }));
                        }}
                        decimalScale={0}
                        max={32_000}
                        inputProps={{ inputMode: "numeric", tabIndex: ((data.charge ?? "").includes('Bolt') ? 13 : 9) }}
                        allowNegative={false}
                    />
                </Alignment>
                <Spacer horizontal/>

                {/* East */}
                <NumberTextField
                    label={"East"}
                    value={data.poleCorrosion2 ?? ""}
                    onValueChange={(values) => {
                        setData(x => ({ ...x, poleCorrosion2: values.floatValue !== undefined ? Math.min(values.floatValue, 32_000) : null }));
                    }}
                    decimalScale={0}
                    max={32_000}
                    inputProps={{ inputMode: "numeric", tabIndex: ((data.charge ?? "").includes('Bolt') ? 12 : 8) }}
                    allowNegative={false}
                    className={classes.centeredTextField}
                />
            </Alignment>

			<Spacer vertical/>

			<Alignment row>
				<Typography variant={"body1"} fontWeight={'bold'}>{"% Remaining Wall Thickness:"}</Typography>
				<Spacer horizontal size={'3px'}/>
				{wallThickness ? 
				<Typography variant={"body1"}>{wallThickness.wallThickness !== null ? (wallThickness.wallThickness * 100).toFixed(1) + "%" : ""}</Typography>
				: null}
			</Alignment>

            <Spacer vertical/>
            <Divider/>
            <Spacer vertical/>

            {/* Notes Field */}
            <TextField
                label={"Notes"}
                name={"notes"}
                multiline
                minRows={3}
                value={data.notes ?? ""}
                onChange={handleChange}
                error={(data.notes ?? "").length > 255}
                helperText={(data.notes ?? "").length > 255 ? "Too long." : undefined}
                inputProps={{ tabIndex: ((data.charge ?? "").includes('Bolt') ? 15 : 11) }}
            />

            <Spacer vertical/>
            <Divider/>
            <Spacer vertical/>
            

            {/* Image Capture */}
            <Button
                color={"primary"}
                variant={"contained"}
                startIcon={<CameraAltIcon/>}
                component={"label"}
                tabIndex={((data.charge ?? "").includes('Bolt') ? 16 : 12)}
            >
                {imagePreview ? "Re-Take Picture" : "Take Picture"}
                <input
                    type={"file"}
                    hidden
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        //ensure files is not null
                        if (event.target.files === null) return;
                        
                        //get the first file
                        const image = event.target.files[0];

                        if (image === undefined) return;

                        //resize image
                        (async () => {
                            const resizedImage = await new Promise<File>((resolve) => {
                                Resizer.imageFileResizer(
                                    image,
                                    600,//1000,
                                    600,//1000,
                                    "JPEG",
                                    90,
                                    0,
                                    (result) => resolve(result as File),
                                    "file"
                                );
                            });

                            setData(x => ({ ...x, file: resizedImage }));
                        })().catch(() => {});
                    }}
                    accept={"image/*;capture=camera"}
                    capture={"environment"}
					
					
                />
            </Button>

			{/* <video autoPlay></video> */}		

            {/* Image Preview if Image is shown */}
            {imagePreview ? (
                <Alignment column className={classes.imageContainer}>
                    <Spacer vertical/>
                    <img src={imagePreview} style={{ width: '100%' }}/>
                </Alignment>
            ) : null}
        </Alignment>
    );
}));

export default LightPoleMeasurementForm;