//file tables enum
export enum FileTables {
	CalibrationFiles = "CalibrationFiles",
    ClientFiles = "ClientFiles",
    EmployeePictureFiles = "EmployeePictureFiles",
    EmployeeSignatureFiles = "EmployeeSignatureFiles",
    FormFiles = "FormFiles",
    GeneralFiles = "GeneralFiles",
    JobFiles = "JobFiles",
	ProcedureFiles = "ProcedureFiles",
	SandboxFiles = "SandboxFiles",
    ScopeFiles = "ScopeFiles",
    TimeSheetFiles = "TimeSheetFiles",
    ExpenseReportFiles = "ExpenseReportFiles",
};