import { GetClientLocationClient, GetClientLocationContacts } from './../client/locations';
import { DataHookResult, createUpdateHandler } from './_utils';
import { useHub, useOnHub } from '../_hubs';
import { useCache } from '../_cache';
import { useLoadApi, UseLoadApiOptionsFromApi } from '../_internals';
import { 
	GetClient, GetAllClients, GetClientLocations, GetClientVContacts, GetClientLocationVContacts, GetClientLocationDocuments, GetVClientContacts,
	GetClientLocationVContactPrimaryPhoneNumbers, GetClientLocationContactProposals, GetClientVLocationPrimaryPhoneNumbers, 
	GetClientVContactPrimaryPhoneNumbers, GetClientNotes, GetClientPurchaseOrders, GetClientByName, GetClientProposals, GetAllClientIdNames, GetClientPurchaseOrderRevisions
}  from '../client';
import { useEffect, useMemo } from 'react';

export const useClient = (clientId: int, options?: UseLoadApiOptionsFromApi<typeof GetClient>) => {
	//load data
    const api = useLoadApi(GetClient, [clientId], [clientId], options);

	//get data from cache if  possible
	const [cacheResult, cache] = useCache("Clients", async (x) => await x.get({ id: clientId }) ?? undefined, [clientId]);

	//update cache when the api resolves
	useEffect(() => { if (api.data) cache.update("UPDATE", api.data); }, [api.data, cache]);

	//load signalR hub
	const hub = useHub("/clients");

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, client) => {
		//update data if ids match
		if (client.id === clientId && type === "UPDATE") api.update(client);

		//update cache
		cache.update(type, client);
	}, [clientId]);

	return [api.data ?? cacheResult, { stale: !api.data && cacheResult, ...api }] as DataHookResult<typeof api, true>;
};

export const useClientByName = (clientName: string, options?: UseLoadApiOptionsFromApi<typeof GetClientByName>) => {
	//load data
    const api = useLoadApi(GetClientByName, [clientName], [clientName], options);

	//load signalR hub
	const hub = useHub("/clients");

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, client) => {
		//update data if ids match
		if (client.name === clientName && type === "UPDATE") api.update(client);
	}, [clientName]);

	return [api.data , api] as DataHookResult<typeof api>;
};

export const useClients = (options?: UseLoadApiOptionsFromApi<typeof GetAllClients>) => {
	//load data
    const api = useLoadApi(GetAllClients, [], [], options);

	//load signalR hub
	const hub = useHub("/clients");

	//get data from cache if  possible
	const [cacheResult, cache] = useCache("Clients", async (x) =>  await x.get(), []);

	//update cache when the api resolves
	useEffect(() => { if (api.data) cache.update("UPDATE", api.data); }, [api.data, cache]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", createUpdateHandler(api.update, cache), []);

	return [api.data ?? cacheResult, { stale: !api.data && cacheResult, ...api }] as DataHookResult<typeof api, true>;
};

export const useClientIdNames = (options?: UseLoadApiOptionsFromApi<typeof GetAllClientIdNames>) => {
	//load data
    const api = useLoadApi(GetAllClientIdNames, [], [], options);

	//load signalR hub
	const hub = useHub("/clients");

	//listen for updates and update the list of data
	useOnHub(hub, "Update", createUpdateHandler(api.update), []);

	return [api.data, api] as DataHookResult<typeof api, true>;
};

export const useVClientContacts = (options?: UseLoadApiOptionsFromApi<typeof GetVClientContacts>) => {
	//load data
    const api = useLoadApi(GetVClientContacts, [], [], options);

	//load signalR hub
	const hub = useHub("/clients");

	//get data from cache if  possible
	const [cacheResult, cache] = useCache("vClientContacts", async (x) => await x.get(), []);

	//update cache when the api resolves
	useEffect(() => { if (api.data) cache.update("UPDATE", api.data); }, [api.data, cache]);

	//listen for updates and update the list of data
	useOnHub(hub, "UpdateVClientContact", createUpdateHandler(api.update, cache), []);

	return [api.data ?? cacheResult, { stale: !api.data && cacheResult, ...api }] as DataHookResult<typeof api, true>;
};

export const useClientLocations = (clientId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetClientLocations>) => {
	//load data
    const api = useLoadApi(GetClientLocations, [clientId ?? null], [clientId], options);

	//load signalR hub
	const hub = useHub("/clients/locations");

	//get data from cache
	const [cacheResult, cache] = useCache("ClientLocations", async (x) => {
		if (clientId) return await x.store.where("clientId").equals(clientId).toArray();
		return await x.get();
	}, [clientId]);

	//update cache when the api resolves
	useEffect(() => { if (api.data) cache.update("UPDATE", api.data); }, [api.data, cache]);

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update, cache), [api.update, cache]);

	//listen for updates
	useOnHub(hub, "Update", (type, location) => {
		if (clientId && location.clientId !== clientId) return;
		update(type, location);;
	}, [clientId]);

	return [api.data ?? cacheResult, { stale: !api.data && cacheResult, ...api }] as DataHookResult<typeof api, true>;
};

export const useClientLocationContacts = (locationId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetClientLocationContacts>) => {
	//load data
    const api = useLoadApi(GetClientLocationContacts, [locationId ?? null], [locationId], options);

	//load signalR hub
	const hub = useHub("/clients/locations/contacts");

	//get data from cache
	const [cacheResult, cache] = useCache("ClientLocationContacts", async (x) => { 
		if (locationId) return await x.store.where("locationId").equals(locationId).toArray();
		return await x.get();
	}, [locationId]);

	//update cache when the api resolves
	useEffect(() => { if (api.data) cache.update("UPDATE", api.data); }, [api.data, cache]);

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update, cache), [api.update, cache]);

	//listen for updates
	useOnHub(hub, "Update", (type, contact) => {
		if (locationId && contact.locationId !== locationId) return;
		update(type, contact);;
	}, [locationId]);

	return [api.data ?? cacheResult, { stale: !api.data && cacheResult, ...api }] as DataHookResult<typeof api, true>;
};

export const useClientLocationVContacts = (locationId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetClientLocationVContacts>) => {
	//load data
    const api = useLoadApi(GetClientLocationVContacts, [locationId ?? null], [locationId], options);

	//load signalR hub
	const hub = useHub("/clients/locations/contacts");

	//update handler
	const updateContacts = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "UpdateView", (type, contact) => {
		//ignore contacts which don't belong to the specified location
		if (contact.locationId !== locationId) return;

		//update the list of contacts
		updateContacts(type, contact);
	}, [locationId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useClientLocationVContactPrimaryPhoneNumbers = (locationId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetClientLocationVContactPrimaryPhoneNumbers>) => {
	//load data
    const api = useLoadApi(GetClientLocationVContactPrimaryPhoneNumbers, [locationId ?? null], [locationId], options);

	//load signalR hub
	const hub = useHub("/clients/locations/contacts");

	//update handler
	const updateContacts = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "UpdatePrimaryPhoneNumber", (type, contactPrimaryPhoneNumber) => {
		//ignore contacts which don't belong to the specified location
		if (locationId !== null && contactPrimaryPhoneNumber.locationId !== locationId) return;

		//update the list of contacts
		updateContacts(type, contactPrimaryPhoneNumber);
	}, [locationId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useClientLocationDocuments = (locationId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetClientLocationDocuments>) => {
	//load data
    const api = useLoadApi(GetClientLocationDocuments, [locationId ?? null], [locationId], options);

	//load signalR hub
	const hub = useHub("/clients/locations/documents");
	
	//update handler
	const updateDocuments = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, document) => {
		//ignore document which don't belong to the specified location
		if (locationId !== null && document.locationId !== locationId) return;

		//update the list of documents
		updateDocuments(type, document);
	}, [locationId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useClientLocationClient = (locationId: int, options?: UseLoadApiOptionsFromApi<typeof GetClientLocationClient>) => {
	//load data
    const api = useLoadApi(GetClientLocationClient, [locationId], [locationId], options);

	//load signalR hub
	const hub = useHub("/clients");

	//loaded client's ID
	const clientId = api.data?.id ?? null;

	//listen for updates in the loaded client record, ignore un-releated updates
	useOnHub(hub, "Update", (type, client) => {
		if (type === "UPDATE" && client.id === clientId) {
			api.update(client);
		}
	}, [clientId, api.update]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useClientVContacts = (clientId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetClientVContacts>) => {
	//load data
    const api = useLoadApi(GetClientVContacts , [clientId ?? null], [clientId], options);

	//load signalR hub
	const hub = useHub("/clients/locations/contacts");

	//listen for updates and update the list of data
	useOnHub(hub, "UpdateView", createUpdateHandler(api.update), []);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useClientVLocationPrimaryPhoneNumbers = (clientId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetClientVLocationPrimaryPhoneNumbers>) => {
	//load data
    const api = useLoadApi(GetClientVLocationPrimaryPhoneNumbers, [clientId ?? null], [clientId], options);

	//load signalR hub
	const hub = useHub("/clients/locations");

	//update handler
	const updateContacts = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "UpdatePrimaryPhoneNumber", (type, locationsPrimaryPhoneNumber) => {
		//ignore locations which don't belong to the specified client
		if (clientId !== null && locationsPrimaryPhoneNumber.clientId !== clientId) return;

		//update the list of locations
		updateContacts(type, locationsPrimaryPhoneNumber);
	}, [clientId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useClientVContactPrimaryPhoneNumbers = (clientId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetClientVContactPrimaryPhoneNumbers>) => {
	//load data
    const api = useLoadApi(GetClientVContactPrimaryPhoneNumbers, [clientId ?? null], [clientId], options);

	//load signalR hub
	const hub = useHub("/clients/locations/contacts");

	//update handler
	const updateContacts = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "UpdatePrimaryPhoneNumber", (type, contactPrimaryPhoneNumber) => {
		//ignore contacts which don't belong to the specified client
		if (clientId !== null && contactPrimaryPhoneNumber.clientId !== clientId) return;

		//update the list of contacts
		updateContacts(type, contactPrimaryPhoneNumber);
	}, [clientId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useClientNotes = (clientId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetClientNotes>) => {
	//load data
    const api = useLoadApi(GetClientNotes, [clientId ?? null], [clientId], options);

	//load signalR hub
	const hub = useHub("/clients/notes");

	//update handler
	const updateContacts = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, note) => {
		//ignore notes which don't belong to the specified client
		if (clientId !== null && note.clientId !== clientId) return;

		//update the list
		updateContacts(type, note);
	}, [clientId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useClientProposals = (clientId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetClientProposals>) => {
	//load data
    const api = useLoadApi(GetClientProposals, [clientId ?? null], [clientId], options);

	//load signalR hub
	const hub = useHub("/proposals");

	//update handler
	const updateContacts = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, proposal) => {
		if (clientId !== null && proposal.clientId !== clientId) return;

		//update the list
		updateContacts(type, proposal);
	}, [clientId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useClientPurchaseOrders = (clientId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetClientPurchaseOrders>) => {
	//load data
    const api = useLoadApi(GetClientPurchaseOrders, [clientId ?? null], [clientId], options);

	//load signalR hub
	const hub = useHub("/clients/purchase-orders");

	//update handler
	const updatePurchaseOrders = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, purchaseOrder) => {
		//ignore purchase orders which don't belong to the specified client
		if (clientId !== null && purchaseOrder.clientId !== clientId) return;

		//update the list
		updatePurchaseOrders(type, purchaseOrder);
	}, [clientId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useClientPurchaseOrderRevisions = (purchaseOrderId: int, options?: UseLoadApiOptionsFromApi<typeof GetClientPurchaseOrderRevisions>) => {
	//load data
    const api = useLoadApi(GetClientPurchaseOrderRevisions, [purchaseOrderId], [purchaseOrderId], options);

	//load signalR hub
	const hub = useHub("/clients/purchase-order-revisions");

	//update handler
	const updatePurchaseOrderRevisions = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, revision) => {
		//ignore purchase orders which don't belong to the specified client
		if (purchaseOrderId !== null && revision.purchaseOrderId !== purchaseOrderId) return;

		//update the list
		updatePurchaseOrderRevisions(type, revision);
	}, [purchaseOrderId]);

	return [api.data, api] as DataHookResult<typeof api>;
};

export const useClientLocationContactProposals = (contactId?: int | null, options?: UseLoadApiOptionsFromApi<typeof GetClientLocationContactProposals>) => {
	//load data
    const api = useLoadApi(GetClientLocationContactProposals, [contactId ?? null], [contactId], options);

	//load signalR hub
	const hub = useHub("/proposals");

	//update handler
	const updateContacts = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, proposal) => {
		//ignore proposals which don't use the specified contact
		if (proposal.contactId !== contactId) return;

		//update the list of proposals
		updateContacts(type, proposal);
	}, [contactId]);

	return [api.data, api] as DataHookResult<typeof api>;
};